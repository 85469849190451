/**
/* © 2023 University of Cambridge. All rights reserved.  
**/

import React from 'react';
import FormControl from '@mui/material/FormControl';
import { Autocomplete, TextField, useMediaQuery, useTheme } from '@mui/material';
import { makeid } from '../utils/Utilities';


const getWidthOfText = function(text:"Age"|"Diagnosis age"|"Age at death"|"Age at First Period", smScreen:boolean) {
  switch (text) {
    case "Age":
      return (smScreen ? 9 : 10);
    case "Diagnosis age":
      return 20;
    case "Age at death":
      return (smScreen ? 13 : 15);
    case "Age at First Period":
      return 25;
  }
};

export interface AgeProps {
  age: undefined|number;
  ageChange?:(age: string|null) => void;
  label?: "Age"|"Diagnosis age"|"Age at death"|"Age at First Period";
  id?: string;
  required?: boolean;
  maxAge?: number;
  minAge?: number;
}

SelectAge.defaultProps = {
  label: 'Age',
  id: "age-label",
  required: true
};

function isNumber(value: string|null): boolean {
   return ((value != null) &&
           (value !== '') &&
           !isNaN(Number(value)));
}

function isValid(age: string|null, minAge: number, maxAge: number): boolean {
  if(isNumber(age) && age !== null) {
    const ageValue = parseInt(age);
    if(ageValue < minAge || ageValue > maxAge) return false;
  }
  return true;
}

/**
 * Age component
 * @constructor
 * @param {AgeProps} AgeProps - age properties
 */
export default function SelectAge(props: AgeProps) {
  const minAge = (props.minAge ? props.minAge : 0);
  const maxAge = (props.maxAge ? props.maxAge+1 : 121);
  const [age, setAge] = React.useState((props.age && props.age>-1 ? props.age.toString() : ""));
  const [valid, setValid] = React.useState(isValid(age, minAge, maxAge));

  const ages = Array.from({ length: maxAge-minAge }, (v, i) => (i+minAge).toString());
  ages.unshift("");
  const smScreen = useMediaQuery(useTheme().breakpoints.down('md'));
  const w = getWidthOfText(props.label?props.label:"Age", smScreen);
  const uid = makeid();

  const ageChange = (newAge:string|null) => {
    if(props.ageChange) {
      const v = isValid(newAge, minAge, maxAge);
      if(!v) newAge = null;
      setValid(v);
      console.log("newAge", newAge, "min/max", minAge, maxAge);
      setAge(newAge ? newAge : "");
      props.ageChange(newAge);
    }
  }

  return (
    <FormControl required sx={{ minWidth: 150, pb:0.75 }} size="small">
      <Autocomplete
        key={"age"+props.age}
        id={uid}
        sx={{mr:1}}
        options={ages}
        getOptionLabel={(option) => option}
        //style={{ width: 200 }}
        renderInput={(params) => (
          <TextField {...params} error={(!props.age && props.required) || !valid}
                    helperText={!props.age && props.required ? 'Required' : ''}
                    label={props.label} variant="outlined" size="small" sx={{width: w+'ch'}}/>
        )}
        onChange={(event: any, newValue: string | null) => {
          ageChange(newValue);
        }}
        autoSelect={true}
        value={(props.age && props.age>-1 ? props.age.toString() : "")}
        clearOnBlur={false}
        onBlur={() => {
          const newValue = (document.getElementById(uid) as HTMLInputElement).value;
          ageChange(newValue);
        }}
      />
    </FormControl>
  );
}
