/**
/* © 2023 University of Cambridge. All rights reserved.  
**/

import React from "react";
import { FormControl, FormGroup,  FormHelperText,  FormLabel, MenuItem, Select, SelectChangeEvent, TextField } from '@mui/material';
import { getRF, setRF } from './Person';
import MyTooltip from "./MyTooltip";

// ONS wording for ethnicity, https://www.ons.gov.uk/methodology/classificationsandstandards/measuringequality/ethnicgroupnationalidentityandreligion#ethnic-group
const eGrp = ["White", "Mixed/Multiple ethnic groups", "Asian or Asian British", "Black or Black British", "Other ethnic group", "Unknown"] as const;
const eBgA = ["English/Welsh/Scottish/Northern Irish/British", "Irish", "Gypsy or Irish Traveller", "Any other White background, please describe"] as const;
const eBgB = ["White and Black Caribbean", "White and Black African", "White and Asian", "Any other Mixed/Multiple ethnic background, please describe"] as const;
const eBgC = ["Indian", "Pakistani", "Bangladeshi", "Chinese", "Any other Asian background, please describe"] as const;
const eBgD = ["African", "Caribbean", "Any other Black/African/Caribbean background, please describe"] as const;
const eBgE = ["Arab", "Any other ethnic group, please describe"] as const;

export type eGrpT = typeof eGrp[number];

type eBgAT = typeof eBgA[number];
type eBgBT = typeof eBgB[number];
type eBgCT = typeof eBgC[number];
type eBgDT = typeof eBgD[number];
type eBgET = typeof eBgE[number];

export type eBgT = eBgAT | eBgBT | eBgCT | eBgDT | eBgET;

export function Ethnicity() {
    let ethnic = getRF("ethnic");
    const [ethnicGrp, setEthnicGrp] = React.useState<eGrpT>(getRF("ethnic")?.group);
    const [ethnicBg, setEthnicBg] = React.useState<eBgT|undefined>(ethnic?.background);
    const [ethnicBgDesc, setEthnicBgDesc] = React.useState<string|undefined>(ethnic?.backgroundDesc);

    const handleGpChange = (event: SelectChangeEvent) => {
        const grp = event.target.value as eGrpT;
        setEthnicGrp(grp);
        setEthnicBg(undefined);
        ethnic = {group:grp};
        setRF("ethnic", ethnic);
    };

    const handleBgChange = (event: SelectChangeEvent) => {
        const bg = event.target.value as eBgT;
        ethnic['background'] = bg;
        setRF("ethnic", ethnic);
        setEthnicBg(bg);
        setEthnicBgDesc(undefined);
    };

    const handleBgDescChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const bgdesc =  event.target.value;
      ethnic['backgroundDesc'] = bgdesc;
      setRF("ethnic", ethnic);
      setEthnicBgDesc(bgdesc);
    };
  
  
    let bgX = undefined;
    switch(eGrp.indexOf(ethnicGrp)) { 
      case 0: { 
        bgX = eBgA; 
        break; 
      } 
      case 1: { 
        bgX = eBgB;
        break; 
      }
      case 2: { 
        bgX = eBgC;
        break; 
      }
      case 3: { 
        bgX = eBgD;
        break; 
      }
      case 4: { 
        bgX = eBgE;
        break; 
      } 
      default: { 
        break; 
      } 
    }
    let bgDesc = (ethnicBg  && ethnicBg.indexOf("please describe") > -1 ? "" : undefined);

    const grpMenus = eGrp.map((g) => {
      return <MenuItem value={g} key={g}>{g}</MenuItem>
    })

    const bgMenus = bgX?.map((b) => {
      return <MenuItem value={b} key={b}>{b}</MenuItem>
    })

    // ONS :: https://www.ons.gov.uk/methodology/classificationsandstandards/measuringequality/ethnicgroupnationalidentityandreligion#ethnic-group
    const tt = (
      <MyTooltip
        outline={false}
        title={<>
          Information about your ethnicity can help us to make your risk assessment more accurate.
          Genetic risk information for breast cancer is largely based on white european ethnic groups
          and we are working to extend this to other ethnic groups in the near future. The ethnicity
          categories here are based on those recommended by the Office for National Statisitcs.</>}
        areaLabel="Ethnicity group"
      />
    );

    return (
      <FormGroup>
        <FormLabel id="ethnic-grp-label" sx={{pr:1}} >What is your ethnic group?{tt}</FormLabel>
        <FormControl>
          <Select
              labelId="ethnic-grp-label"
              size="small"
              value={ethnicGrp?ethnicGrp:"none"}
              sx={{ minWidth: 120, width: 230}}
              onChange={handleGpChange}
              autoWidth={true}
          >
            <MenuItem value="none" sx={{display:"none"}}>Select ethnic group</MenuItem>
            {grpMenus}
          </Select>
          {(!ethnicGrp || ethnicGrp === "Unknown") && <FormHelperText>Required</FormHelperText>}
        </FormControl>
        {bgX !== undefined && 
            <FormControl>
              <FormLabel id="ethnic-background-select-label" sx={{pt:1}}>What is your ethnic background?</FormLabel>
              <Select
                  labelId="ethnic-background-select-label"
                  size="small"
                  value={ethnicBg ? ethnicBg : "none"}
                  sx={{ minWidth: 120, width: 230 }}
                  autoWidth={true}
                  onChange={handleBgChange}
              >
                <MenuItem value="none" sx={{display:"none"}}>Select ethnic background</MenuItem>
                {bgMenus}
              </Select>
              {(!ethnicBg) && <FormHelperText>Required</FormHelperText>}
            </FormControl>
        }
        {bgDesc !== undefined && 
          <FormControl sx={{pt:1, minWidth: 120, width: 230}}>
            <TextField id="ethnicity-description" value={ethnicBgDesc} size="small" label="Please describe" variant="outlined" onChange={handleBgDescChange}/>
          </FormControl>
        }
      </FormGroup>
    )
  }
  