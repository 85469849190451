/**
/* © 2023 University of Cambridge. All rights reserved.  
**/

import React from "react";
import { useScrollTrigger } from "@mui/material";

import { BrowserRouter, Route, Switch } from "react-router-dom";
import SectionTool from "../pages/SectionTool";
import Decrypt from "../pages/Decrypt";
import Test from "../pages/Test";

interface ESProps {
    children: React.ReactElement;
}

/** Elevates the navigation bar */
function ElevationScroll(props: ESProps) {
    const { children } = props;
    const trigger = useScrollTrigger({
      disableHysteresis: true,
      threshold: 0,
    });
  
    return React.cloneElement(children, {
      elevation: trigger ? 4 : 0,
    });
  }

/**
 * NavBar - navigation bar component
 * @returns NavBar
 */
const NavBar = () => {

    return (
        <ElevationScroll >
            <BrowserRouter>
                <Switch>
                    <Route exact path="/" component={SectionTool} />
                    <Route exact path='/test' component={Test}></Route>
                    <Route exact path='/decrypt' component={Decrypt}></Route>
                </Switch>
            </BrowserRouter>
        </ElevationScroll>
    );
};

export default NavBar;
