/**
/* © 2023 University of Cambridge. All rights reserved.  
**/

import { Box, Button, Grid, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/system";
import { pedigree_js, pedigreejs_io, pedigreejs_zoom, pedigrejs_utils } from "../pedigreejs/pedigreejs.v3.0.0-rc3";
import { getCanRisk } from "../utils/CanRisk";
import { PedigreeKeyTable } from "./PedigreeKeyTable";
import AddIcon from '@mui/icons-material/Add';
import MinusIcon from '@mui/icons-material/Remove';
import ResetIcon from '@mui/icons-material/RestartAlt';
import { useEffect, useRef, useState } from "react";
import { CANRISK_CANCER_COLOURS, CanRiskCancer, Cancer, OTHER_CANCERS_COLOURS } from "../utils/Cancers";
import { Person } from "./Person";


interface TreeProps {
  colour:any;
  updateTwin:boolean;
  afterUpdate: () => void;
}

export function FamilyTree(props:TreeProps) {
    const [dimensions, setDimensions] = useState({ 
      height: window.innerHeight,
      width: window.innerWidth
    });
    const ref = useRef(true);
    const theme = useTheme();
    const mdScreen = useMediaQuery(theme.breakpoints.down('md'));
    const width = (mdScreen ? dimensions.width - 48 : (dimensions.width*8/12) - 108);

    const {membersWithCancer, cancerTypes} = Cancer.getFamilyMembersWithCancers();
    const diseases = getDiseases(cancerTypes);

    const opts:any = {
      'targetDiv': 'pedigreejs',
      'background': props.colour,
      'width': width,
      'height': (mdScreen? 420 : 480),
      'symbol_size': 30,
      'edit': false,
      'font_size':  (mdScreen? '0.7em' : '0.75em'),
      'zoomIn': .5,
      'zoomOut': 1.5,
      'zoomSrc':  ['button'] ,
      'labels': [['age', 'yob']],
      'diseases': diseases,
      'DEBUG': false};

    let resizeTimer: NodeJS.Timeout;
    function handleResize() {
      clearTimeout(resizeTimer);
      if(dimensions.width === window.innerWidth && dimensions.height === window.innerHeight) {
        return;
      }

      resizeTimer = setTimeout(function() {
        setDimensions({
          height: window.innerHeight,
          width: window.innerWidth
        })
        const p = document.getElementById("pedigreejs");
        p?.remove();
        ref.current = true;
      }, 500);
    }

    // listen and handle browser window resizing
    useEffect(() => {
      const firstRender = ref.current || props.updateTwin;
      if (firstRender) {
        if(props.updateTwin) {
          const p = document.getElementById("pedigreejs");
          p?.remove();
        }

        ref.current = false;
        showPedigree(opts, membersWithCancer, mdScreen);

        if(props.updateTwin) props.afterUpdate();
        // if medium size screen or above [removing this fixes page returning to top on mobile devices when scrolling]
        if(dimensions.width >= 768) window.addEventListener('resize', handleResize);
      }
    });

    return (
        <Grid container key="familytree">
            <Grid item xs={12} md={9}>
              <Box display="flex" justifyContent="flex-end">
                <Button 
                      variant="contained"
                      title="Zoom In"
                      sx={{ minWidth: 16, mr: 1 }}
                      size="small"
                      color="secondary"
                      onClick={() => {
                        pedigreejs_zoom.btn_zoom(opts, 1.05);
                      }}>
                  <AddIcon sx={{fontSize:16}}/>
                </Button>
                <Button
                      variant="contained"
                      title="Zoom Out"
                      sx={{ minWidth: 16, mr: 1 }}
                      size="small"
                      color="secondary"
                      onClick={() => {
                      pedigreejs_zoom.btn_zoom(opts, 0.95);
                      }}>
                  <MinusIcon sx={{fontSize:16}}/>
                </Button>
                <Button
                      variant="contained"
                      title="Scale to fit"
                      sx={{ minWidth: 16, mr: 1 }}
                      size="small"
                      color="secondary"
                      onClick={() => {
                        pedigreejs_zoom.scale_to_fit(opts);
                      }}>
                  <ResetIcon sx={{fontSize:16}}/>
                </Button>
                </Box>
              </Grid>

              <Grid key="tree" item xs={12} md={9} id="pedigree" sx={{mt:1}}>
            </Grid>
            <Grid item xs={12} md={3}><PedigreeKeyTable diseases={diseases}/></Grid>
        </Grid>
    )
}

function getDiseases(cancerTypes:string[]) {
  const diseases = [];
  // get cancer diseases for this family
  for(let i in cancerTypes.sort()) {
    let c = cancerTypes[i];
    let col;
    if(CanRiskCancer.indexOf(c) > -1) {
      col = CANRISK_CANCER_COLOURS[c];
    } else {
      col =  OTHER_CANCERS_COLOURS[c];
    }
    diseases.push({'type': (c === "Opposite Breast" ? "breast_cancer2" :c.toLocaleLowerCase()+"_cancer"), 'colour': col});
  }
  return diseases;
}

/** Show pedigreejs **/
const showPedigree = (opts:any, membersWithCancer:Person[], mdScreen:boolean) => {
  const p = document.getElementById("pedigreejs");
  const ped = document.getElementById("pedigree");
  if(!p && ped){
    console.log("REDRAW");
    const p = document.createElement('div');
    p.id = 'pedigreejs';
    ped.appendChild(p); 
    pedigreejs_load(opts, membersWithCancer, mdScreen);

    // remove widgets from pedigreejs
    const cls = ["popup_selection", "indi_rect", "addsibling", "addpartner", "addchild", "addparents", "delete", "line_drag_selection"];
    for(let i = 0; i < cls.length; i++) {
        removeElementsByClass(cls[i]);
    }
  }
}

const pedigreejs_load = (opts:any, membersWithCancer:Person[], mdScreen:boolean) => {
  const p = document.getElementById('pedigree');
  if(p)
    opts["width"] = p.offsetWidth-(mdScreen? 0 : 16);
  const canrisk = getCanRisk();
  try {
    pedigreejs_io.load_data(canrisk, opts);
    const newdataset = pedigrejs_utils.copy_dataset(opts.dataset);
    
    for(let i = 0; i < membersWithCancer.length; i++) {
      updateCancers(membersWithCancer[i], newdataset);
    }
    opts.dataset = newdataset;
    pedigree_js.rebuild(opts);
    pedigreejs_zoom.scale_to_fit(opts);
  } catch(e) {
    let msg;
    if (typeof e === "string") {
        msg = e.toUpperCase();
    } else if (e instanceof Error) {
        msg = e.message;
    }
    console.error("FamilyTree ::: "+msg,canrisk, e);
  }
};

function updateCancers(p:Person, dataset:any) {
  const myCancers = Cancer.getMyCancers(p);
  for(let myCancer of myCancers) {
    if(CanRiskCancer.indexOf(myCancer.cancer) === -1){
      for(let ds of dataset) {
        if(p.uid === ds.name) {
          ds[myCancer.cancer.toLowerCase()+'_cancer_diagnosis_age'] = p.cancers[myCancer.cancer].age;
        }
      }
    }
  }
}

function removeElementsByClass(className:string){
    const elements = document.getElementsByClassName(className);
    while(elements.length > 0){
        elements[0].parentNode?.removeChild(elements[0]);
    }
}
