/**
/* © 2023 University of Cambridge. All rights reserved.  
**/

import { Checkbox, FormControl, FormControlLabel, FormLabel, Grid,
         Radio, RadioGroup, Typography } from "@mui/material";
import React from "react";
import { Person } from "./Person";
import MyTooltip from "./MyTooltip";

/**
 * Gene genetic test
 */
export class GeneticTest {
  gene: string;
  type: '0'|'S'|'T' = "0";
  result: '0'|'N'|'P' = "0";
  constructor(gene: string) {
    this.gene = gene;
  }
}

/**
 * Genetic tests
 */
export class Genes {
  static genes = ['BRCA1', 'BRCA2', 'PALB2', 'ATM', 'CHEK2', 'BARD1', 'RAD51C', 'RAD51D', 'BRIP1'];
  static geneTests = () => {
    return Genes.genes.map(gene => {return new GeneticTest(gene);
  })};
}

interface GeneticTestsProps {
  question: string;
  person: Person;
  change:(gts: GeneticTest[]) => void;
}

/**
 * Gene Tests Panel
 */
export default function GeneticTestsPanel(props: GeneticTestsProps) {
  const person = props.person;
  const [isTested, setIsTested] = React.useState(person.geneTests[0].result !== "0");

  const gts = person.geneTests.map((gt, idx) => {
    const geneTestsChange = (isPositive:boolean) => {
      gt.result = isPositive ? "P" : "N";
      person.geneTests[idx] = gt;
      props.change(person.geneTests);
    };

    return (
      <Grid item xs={4} key={gt.gene}>
          <FormControlLabel
            key={gt.gene+gt.result}
            onChange={function(e) {geneTestsChange((e.target as HTMLInputElement ).checked)}}
            control={<Checkbox defaultChecked={gt.result === "P"}/>}
            label={gt.gene} />
      </Grid>
    )
  })

  return (
    (<Grid container spacing="0">
      <Grid item xs={12} sx={{ml:1}}>
        <FormControl>
          <FormLabel id="radio-btn-grp-genetic-test" sx={{maxWidth:600}}>
            {props.question}
            <MyTooltip
              outline={false}
              title={"Genetic testing may have been carried out for example if a "+
                     "close relative has had a particular type of cancer."}
              areaLabel="Genetic testing information button"/>
          </FormLabel>
          <RadioGroup
            row
            key={person.geneTestsQ}
            aria-labelledby="radio-btn-grp-genetic-test"
            defaultValue={person.geneTestsQ}
            name="gt-grp"
            onChange={(event: React.ChangeEvent<HTMLInputElement>, value: string) => {
              person.geneTestsQ = value as "U"|"N"|"Y";
              setIsTested(value === "Y");

              function setGeneTest(gt:GeneticTest, i:number) {
                gt.result = (value === "Y"?"N":"0");
                gt.type =   (value === "Y"?"T":"0");
              } 
              person.geneTests.forEach(setGeneTest);
            }}
          >
            <FormControlLabel value="Y" control={<Radio size="small" />} label="Yes" />
            <FormControlLabel value="N" control={<Radio size="small" />} label="No" />
            <FormControlLabel value="U" control={<Radio size="small" />} label="Unknown" />
          </RadioGroup>
        </FormControl>
      </Grid>
      {isTested && 
      <Grid item xs={12} sx={{ml:1, mt:1}}>
        <Typography variant="body1">Please tick if a pathogenic variant (mutation) was found:</Typography>
        <Grid container spacing="0">{gts}</Grid>
      </Grid>}
    </Grid>
    )
  );
}

interface GeneticTestsReportProps{
  geneTests: GeneticTest[]
}

export function GeneticTestsReport(props:GeneticTestsReportProps) {
  let hasMutation = false;
  for(let i=0; i<props.geneTests.length; i++) {
    if(props.geneTests[i]['result'] === "P") hasMutation = true;
  }

  if(!hasMutation) return <Typography variant="body1">None</Typography>;

  let gts;
  if(props.geneTests.length > 0) {
    gts = props.geneTests.map((gt, idx) => {
      return (
        gt.result === "P" ? gt.gene+"; " : ""
      )
    })
  }
  return (<Typography variant="body1">{gts}</Typography>)
}
