/**
/* © 2023 University of Cambridge. All rights reserved.  
**/
import { Box, Typography } from "@mui/material";

export function SubSectionHeader(props: {colour?:any, txtColour?:any, title:string}) {
    const c = (!props.colour ? "#fafafa" : props.colour);
    const t = (!props.txtColour ? "#003087" : props.txtColour);
    return (
        <Box sx={{my: 1, px:1, backgroundColor:c, borderRadius:1, boxShadow: 1, textTransform: 'capitalize'}} textAlign="left">
            <Typography variant="h1" sx={{textAlign: "center", color:t}}>
                {props.title.toLowerCase()}
            </Typography>
        </Box>
    )
}
