/**
/* © 2023 University of Cambridge. All rights reserved.  
**/
import { Breadcrumbs, Link, useTheme } from "@mui/material";
import "../css/breadcrumbstyled.css";


interface MyCanRiskBreadcrumbsProps {
    CRUMBS: string[],
    idx: number,
    onClick:(idx:number) => void;
}

export function MyCanRiskBreadcrumbs(props:MyCanRiskBreadcrumbsProps) {
    const currCrumb = props.idx ? props.idx: 0;
    const theme = useTheme();

    return (
        <Breadcrumbs
            aria-label="breadcrumb menu"
            maxItems={7}
            itemsAfterCollapse={4}
            itemsBeforeCollapse={2}
            separator=""
            sx={{backgroundColor:"#EC008C", px:1, py:0.4, borderRadius:"5px 5px 0 0"}}
        >
        {props.CRUMBS.map((crumb, idx) => {
            const isCurrent = idx===currCrumb;
            let sty = {
                fontWeight: "bold",
                fontSize: "0.85rem",
                py:0.4, px:0.8, my:0.4,
                borderTop:1, borderLeft:1, borderRight:1, borderBottom:0.1, borderRadius:"5px 5px 0 0"};
            if(isCurrent) {
                const currentSty = {backgroundColor: theme.palette.primary.dark, color: "white", borderColor: theme.palette.primary.main};
                sty = {...sty, ...currentSty};
            } else {
                const defaultSty = {backgroundColor: "seashell", color: theme.palette.primary.dark };
                sty = {...sty, ...defaultSty};
            }
            
            return (
              <Link 
                key={idx} 
                underline="none"
                color={isCurrent ? "primary": "warning"}
                component="button"
                variant="caption"
                sx={sty}
                onClick={() => props.onClick(idx)}
                >{crumb}</Link>
            )
          })
        }
      </Breadcrumbs>
    )
}