/**
/* © 2023 University of Cambridge. All rights reserved.  
**/

import { ButtonProps as MuiButtonProps } from "@mui/material/Button";
import { Genes, GeneticTest } from "./GeneticTests";
import { Frequency } from "./AlcoholPanel";
import { answerType } from "../utils/Utilities";
import { eBgT, eGrpT } from "./Ethnicity";


interface RiskFactorsI {
  dob?: Date;
  ethnic: {"group":eGrpT, "background"?:eBgT, "backgroundDesc"?:string};
  hgtUnits: "metric"|"imperial";
  wgtUnits: "metric"|"imperial";
  hgtMetric: number;
  wgtMetric: number;
  alcohol: {"frequency":Frequency, "amount"?:number};
  menarche: 10|11|12|13|14|15|16|typeof NaN;
  periodsStopped: {haveStopped: answerType, age?:number};

  // OC_use
  // NA=unspecified, N=Never, F:Years=Former use, C:Years=Current use;
  // F and C are followed by integer=number of years taken e.g. F:4 means former use of 4 years
  ocUsage: {usage: answerType, isCurrent?: boolean, nyears?: string};

  // Use of menopause hormone therapy
  // NA=unspecified
  // N=Never used
  // F=Former use
  // E=Current E-type use
  // C=Current other/unknown type (including combined type) use
  mhtUsage: {usage: answerType, isCurrent?: boolean, currentType?: "E"|"C"};
}


const cachedRF = getProbandRiskFactors();
const ProbandRiskFactors:RiskFactorsI|any = (cachedRF ? cachedRF : {
  ethnic: {
    group: "Unknown",
    background: undefined,
    backgroundDesc: undefined
  },
  hgtUnits: "metric",
  wgtUnits: "metric",
  alcohol: { undefined },
  menarche: NaN,
  periodsStopped: { haveStopped: "U" },
  ocUsage: { usage: "U" },
  mhtUsage: { usage: "U" },
});

export function setRF(key:"dob"|"ethnic"|"hgtUnits"|"wgtUnits"|"hgtMetric"|"wgtMetric"|"alcohol"|"menarche"|"periodsStopped"|"ocUsage"|"mhtUsage", value:any) {
  ProbandRiskFactors[key] = value;
  setProbandRiskFactors();
}

export function getRF(key:"dob"|"ethnic"|"hgtUnits"|"wgtUnits"|"hgtMetric"|"wgtMetric"|"alcohol"|"menarche"|"periodsStopped"|"ocUsage"|"mhtUsage") {
  return ProbandRiskFactors[key];
}

function setProbandRiskFactors() {
  localStorage.setItem('ProbandRiskFactors',  JSON.stringify(ProbandRiskFactors));
}

export function getProbandRiskFactors() {
  const newObject = localStorage.getItem("ProbandRiskFactors");
  if(newObject){
    const obj = JSON.parse(newObject);
    if(obj.dob) obj.dob = new Date(obj.dob);
    return obj;
  }
}

/**
 * Person details
 */
export class Person {
  sex: "M"|"F" = "F";
  name?: string;
  uid: string = getUniqueUID();
  fathid: string = '0';
  mothid: string = '0';

  fathid_original?: string;   // store half-siblings fathid
  mothid_original?: string;   // store half-siblings mothid

  age: number = 0;
  yob: number = 0;
  yod: number = 0;
  status: 0|1|-1 = 0; // 0 = alive, 1 = dead, -1 = unknown assume alive
  mztwin: '0'|'1'|'2'|'3'|'4'|'5'|'6'|'7'|'8'|'9'|'A' = '0';
  ashkenazi: 0|1 = 0;  // Ashkenazi status, 0 = not Ashkenazi, 1 = Ashkenazi
  halfSibling: {isHalfSibling:answerType, sharesMother?:boolean} = {isHalfSibling:"U", sharesMother:true};
  geneTests: GeneticTest[] = Genes.geneTests();
  cancers:  { [key: string]: {age: number, homeAddress?: string, treatmentCenter?: string, otherCancerName?: string|undefined } } = {};
  extraDetails?: {fullname?: string, othername?: string, dob?: Date, dod?: Date}

  dateChange?:(date: Date) => void;
  color?: MuiButtonProps["color"];

  cancersQ: answerType  = "U";
  geneTestsQ: answerType  = "U";

  static updateAgeYob = (date: Date, person: Person) => {
    person.age = new Date().getFullYear() - date.getFullYear()//moment().diff(date, 'years', false);
    person.yob = date.getFullYear()
  }
        
  constructor(sex: "M"|"F", mothid: string='0', fathid: string='0') {
    this.sex = sex;
    this.mothid = mothid;
    this.fathid = fathid;
  }
}

/**
 * Type of relative e.g. brothers, sisters, uncles...
 */
 export interface RelativeI {
  name: string;
  number: number;
  sex: "M"|"F";
  mother: Person;
  father: Person;
  persons?: Person[];
  halfSiblingOption?: boolean;
}

/**
 * Family tree object containing pedigree information
 */
let count = 1;
interface FamTreeI {
  mother: Person;
  father: Person;
  proband: Person;
  maternal_grandmother: Person;
  maternal_grandfather: Person;
  paternal_grandmother: Person;
  paternal_grandfather: Person;
  partners: [Person];
  sibs: RelativeI[];
  kids: RelativeI[];
  mothers_sibs: RelativeI[];
  fathers_sibs: RelativeI[];
}

const cachedFT = getFamilyTree();
const famTree: FamTreeI = (cachedFT ? cachedFT : {
  maternal_grandmother : (cachedFT ? cachedFT.maternal_grandmother : new Person("F")),
  maternal_grandfather : (cachedFT ? cachedFT.maternal_grandfather : new Person("M")),
  paternal_grandmother : (cachedFT ? cachedFT.paternal_grandmother : new Person("F")),
  paternal_grandfather : (cachedFT ? cachedFT.paternal_grandfather : new Person("M")),
  partners : (cachedFT ? cachedFT.partners : [new Person("M")]),
});

famTree.mother = (cachedFT ? cachedFT.mother : new Person("F", famTree.maternal_grandmother.uid, famTree.maternal_grandfather.uid));
famTree.father = (cachedFT ? cachedFT.father : new Person("M", famTree.paternal_grandmother.uid, famTree.paternal_grandfather.uid));
famTree.proband = (cachedFT ? cachedFT.proband : new Person("F", famTree.mother.uid, famTree.father.uid));

famTree.sibs = (cachedFT ? cachedFT.sibs : [
  { name: "sisters", number: 0, sex: "F", mother: famTree.mother, father: famTree.father, halfSiblingOption:true },
  { name: "brothers", number: 0, sex: "M", mother: famTree.mother, father: famTree.father, halfSiblingOption:true }
]);

famTree.kids = (cachedFT ? cachedFT.kids : [
  { name: "daughters", number: 0, sex: "F", mother: famTree.proband, father: famTree.partners[0] },
  { name: "sons", number: 0, sex: "M", mother: famTree.proband, father: famTree.partners[0] }
]);

famTree.mothers_sibs = (cachedFT ? cachedFT.mothers_sibs :[
  { name: "mother's sisters",  number: 0, sex: "F", mother: famTree.maternal_grandmother, father: famTree.maternal_grandfather, halfSiblingOption:true },
  { name: "mother's brothers", number: 0, sex: "M", mother: famTree.maternal_grandmother, father: famTree.maternal_grandfather, halfSiblingOption:true }
]);

famTree.fathers_sibs = (cachedFT ? cachedFT.fathers_sibs :[
  { name: "father's sisters",  number: 0, sex: "F", mother: famTree.paternal_grandmother, father: famTree.paternal_grandfather, halfSiblingOption:true },
  { name: "father's brothers", number: 0, sex: "M", mother: famTree.paternal_grandmother, father: famTree.paternal_grandfather, halfSiblingOption:true }
]);

export function getFT() {
  return famTree;
}

export function setFamilyTree() {
  localStorage.setItem('FamilyTree',  JSON.stringify(famTree));
}

function getFamilyTree() {
  const newObject = localStorage.getItem("FamilyTree");
  if(newObject){
    const obj = JSON.parse(newObject);
    return obj;
  }
}

export function updateRels (rels: RelativeI, nnew: number, sex: "M"|"F") {
  let persons = rels.persons || [];
  const nold = persons.length;
  rels.number = nnew;
  if(nnew > 0) {
    if(nnew > nold) {
      persons.push(...[...new Array(nnew-nold)].map((_,idx) => (new Person(sex, rels.mother.uid, rels.father.uid))));
    } else {
      persons.splice(nnew-nold)
    }
    rels.persons = persons
  } else {
    rels.persons = [];
  }
  setFamilyTree();
}

export const get_relatives = () => {
  return getFT().kids.concat(getFT().sibs, getFT().mothers_sibs, getFT().fathers_sibs);
}


/**
 * Get a unique identifier
 * @returns
 */
function getUniqueUID() {
  const uids:string[] = [];
  try{
    const {proband, mother, father,
      maternal_grandmother, maternal_grandfather, paternal_grandmother, paternal_grandfather,
      partners} = getFT();
    const relatives = get_relatives();
    
    if(proband) uids.push(proband.uid);
    if(mother) uids.push(mother.uid);
    if(father) uids.push(father.uid);
    if(maternal_grandmother) uids.push(maternal_grandmother.uid);
    if(maternal_grandfather) uids.push(maternal_grandfather.uid);
    if(paternal_grandmother) uids.push(paternal_grandmother.uid);
    if(paternal_grandfather) uids.push(paternal_grandfather.uid);
    if(partners) {for(let i = 0; i < partners.length; i++) {uids.push(partners[i].uid);} }

    for (let i = 0; i < relatives.length; i++) {
      let ps = relatives[i].persons;
      if(ps) {
        for (let j = 0; j < ps.length; j++) {
          uids.push(ps[j].uid);
        }
      }
    }
  } catch (e) {
    if (e instanceof ReferenceError) {
        // ignore error as this is thrown when
        // family members have not been generated yet
    }
  }

  let uid = (count++).toString();
  let loop = 0;
  while(uids.indexOf(uid) >= 0 && loop < 150){
    uid = (count++).toString();
    loop++;
  }
  return uid
}

