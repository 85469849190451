/**
/* © 2023 University of Cambridge. All rights reserved.  
**/

import { Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import SquareOutlinedIcon from '@mui/icons-material/SquareOutlined';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import { detectIdenticalSiblings } from "./TwinPanel";

type RowType = {name:string, description:JSX.Element}

interface PedigreeKeyTableProps {
    diseases:any[];
}

/**
 * Key for pedigree graphic
 * @returns 
 */
export function PedigreeKeyTable({diseases}:PedigreeKeyTableProps) {
    const rest:React.CSSProperties = {boxSizing:"border-box", width:"18px", height:"12px", display:"inline-block"};
    const icons:RowType[] = [
        {name:'Male', description:<SquareOutlinedIcon fontSize="small"/>},
        {name:'Female', description:<CircleOutlinedIcon fontSize="small"/>},
        {name:'Deceased', description:<><DeceasedMaleIcon /><DeceasedFemaleIcon /></>}
    ];
    const cancers:RowType[] = [];

    for (let i = 0; i <diseases.length; i++) {
        const c = diseases[i];
        const cancer = (c.type === 'breast_cancer2' ? 'Opposite breast' : c.type.replace('_', ' '));
        const name = cancer.charAt(0).toUpperCase() + cancer.slice(1)
        const colour = c.colour;
        cancers.push({name:name, description:<span style={{backgroundColor: colour, ...rest}}/>});
    }

    const identicalSibs = detectIdenticalSiblings();
    if(identicalSibs.length !== 0) {
        let mztwin = false;
        for(let i = 0; i < identicalSibs.length; i++) {
            for(let j = 0; j < identicalSibs[i].siblings.length; j++) {
                const mz = identicalSibs[i].siblings[j].mztwin;
                if(mz && mz !== "0") mztwin = true;
            }
        }
        if(mztwin) icons.push({name:'Identical Twin', description:<MZTwinIcon />});
    }

    return (
        <>
            <Typography variant="subtitle1">Key:</Typography>
            <Grid container>
                <Grid item xs={6} md={12}>
                    <TableKey rows={icons} col1={"Description"} col2={"Icon"} />
                </Grid>
                {cancers.length > 0 && 
                <Grid item xs={6} md={12}>
                    <TableKey rows={cancers} col1={"Cancer"} col2={"Colour"} />
                </Grid>
                }
            </Grid>
        </>
    )
}

function DeceasedFemaleIcon() {
    return (
        <svg height="20" width="20">
            <circle cx="10" cy="10" r="7" stroke="black" strokeWidth="1.2" fill="white" />
            <line x1="0" y1="20" x2="20" y2="0" stroke="black" strokeWidth="1"/>
        </svg> 
    )    
}

function DeceasedMaleIcon() {
    return (
        <svg height="20" width="25">
            <rect x="3" y="3" width="14" height="14"  stroke="black" strokeWidth="1.2" fill="white" />
            <line x1="0" y1="20" x2="20" y2="0" stroke="black" strokeWidth="1"/>
        </svg> 
    )    
}

function MZTwinIcon() {
    return (
        <svg height="50" width="65">
            <path d="M 7 33 l 25 -20 l 25 20" stroke="black" strokeWidth="1.2" fill="none" />
            <line x1="17" y1="24" x2="47" y2="24" stroke="black" strokeWidth="1"/>
            <rect x="1" y="33" width="14" height="14"  stroke="black" strokeWidth="1.2" fill="white" />
            <rect x="50" y="33" width="14" height="14"  stroke="black" strokeWidth="1.2" fill="white" />
        </svg> 
    )    
}

function TableKey({rows, col1, col2}:{rows:RowType[], col1:string, col2:string}) {
    return (
        <TableContainer>
        <Table size="small" aria-label="Key of pedigree graphic" sx={{width:"auto"}}>
        <TableHead>
            <TableRow>
                <TableCell sx={{px:1, fontWeight:700}}>{col1}</TableCell>
                <TableCell sx={{px:1, fontWeight:700}}>{col2}</TableCell>
            </TableRow>
        </TableHead>
        
        <TableBody>
            {rows.map((row) => (
                <TableRow
                key={row.name}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                <TableCell component="th" scope="row" sx={{px:1, fontSize:"0.8rem"}}>
                    {row.name}
                </TableCell>
                <TableCell align="center" sx={{px:1}}>{row.description}</TableCell>
                </TableRow>
            ))}
        </TableBody>
        </Table>
    </TableContainer>
    )
}
