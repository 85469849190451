/**
/* © 2023 Cambridge University. All rights reserved.  
**/

export const HRT_TABLETS = [
    ["Adgyn Combi","C-type"],
    ["Adgyn Estro","E-type"],
    ["Adgyn Medro","P-type"],
    ["Angeliq","C-type"],
    ["Bedol","E-type"],
    ["Climagest","C-type"],
    ["Climaval","E-type"],
    ["Climesse","C-type"],
    ["Clinorette","C-type"],
    ["Cycloprogynova","C-type"],
    ["Duphaston","P-type"],
    ["Elleste Duet/Conti","C-type"],
    ["Elleste Solo/MX","E-type"],
    ["Estracombi","C-type"],
    ["Estraderm/MX","E-type"],
    ["Estradot","E-type"],
    ["Estrapak","C-type"],
    ["Ethinylestradiol","E-type"],
    ["Evorel","E-type"],
    ["Evorel Conti/Sequi","C-type"],
    ["Femoston/Conti","C-type"],
    ["Femseven","E-type"],
    ["Femseven conti/Sequi","C-type"],
    ["Harmogen","E-type"],
    ["Hormonin","E-type"],
    ["Indivina","C-type"],
    ["Kliofem","C-type"],
    ["Kliovance","C-type"],
    ["Livial","S-type"],
    ["Micronor","P-type"],
    ["Novofem","C-type"],
    ["Nuvelle","C-type"],
    ["Oestrogel","E-type"],
    ["Premarin","E-type"],
    ["Premique","C-type"],
    ["Prempak-C","C-type"],
    ["Progynova/TS","E-type"],
    ["Provera","P-type"],
    ["Sandrena","E-type"],
    ["Tibolone","S-type"],
    ["Tridestra","C-type"],
    ["Trisequens/Forte","C-type"],
    ["Zumenon","E-type"]
];
