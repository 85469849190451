/**
/* © 2023 University of Cambridge. All rights reserved.  
**/

import { CalendarPickerView, DatePicker } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers'
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import { useMediaQuery, useTheme } from '@mui/material';

export interface DateProps {
  dateChange:(date: any) => void;
  views?: readonly CalendarPickerView[];
  mydate?: Date|null;
  minDate?: Date|undefined;
  dateLabel?: "Date of birth"|"Year of death"|"Date of death"|""|undefined;
  required?: boolean;
}

/**
 * Date/Year component
 * @constructor
 * @param {DateProps} DateProps - date properties
 */
export default function MyDate(props: DateProps) {
  // set properties depending if yob or dob view
  const dob = (props.views && props.views.includes("day"));
  const fmt = dob ? "dd-MM-yyyy": "yyyy"
  const lbl = (props.dateLabel || props.dateLabel === "" ? props.dateLabel : (dob ? "Date of Birth": "Year of Birth"));
  const msk = dob ? "__-__-____": "____";
  const req = (props.required !== undefined ? props.required : true);
  const smScreen = useMediaQuery(useTheme().breakpoints.down('md'));

  const wid = dob ? "18ch" : (smScreen ? "15ch" : "16ch");
  const isValid = (props.mydate);

  return (
    <FormControl required={req}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          disableFuture
          openTo="year"
          label={lbl}
          views={props.views ? props.views : ["year", "day"]}
          value={props.mydate ? props.mydate : null}
          onChange={props.dateChange}
          renderInput={(params) => <TextField {...params} error={!isValid && req} helperText={!isValid &&  req? 'Required' : null} size="small" sx={{width: wid}} data-testid="dob"/>}
          inputFormat={fmt}
          mask={msk}
          minDate={props.minDate}
          maxDate={new Date()}
        />
      </LocalizationProvider>
    </FormControl>
  );
}
