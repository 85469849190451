
/**
/* © 2023 University of Cambridge. All rights reserved.  
**/

// https://developer.mozilla.org/en-US/docs/Web/API/SubtleCrypto
// Methods for the SubtleCrypto interface of the Web Crypto API
// that provides a number of low-level cryptographic functions
const ALGORITHM = "AES-GCM";
const ITERATIONS = 1e6;

/**
 * Encrypt a string
 * @param s string to encrypt
 * @param password 
 * @returns encyted URL parameter
 */
export const s_encrypt = async (s: string, password:string) => {
  const pass = utf8ToUint8Array(password);
  const salt = window.crypto.getRandomValues(new Uint8Array(16));
  const data = utf8ToUint8Array(s);
  const cipher = await subtle_encrypt(pass, salt, data);
  return base64EncodeURL(salt) + base64EncodeURL(cipher);
}

/**
 * Decrypt string
 * @param urlparam 
 * @param password 
 * @returns 
 */
export async function s_decrypt(urlparam: string, passphrase:string) {
  const password = utf8ToUint8Array(passphrase);
  const salt = base64DecodeURL(urlparam.slice(0, 22));
  const cipher = base64DecodeURL(urlparam.slice(22));
  try
  {
    const { key, iv } = await deriveKeyAndIv(password, salt);
    const plainText = await window.crypto.subtle.decrypt({ name: ALGORITHM, iv: iv }, key, cipher);
    return arrayBufferToUtf8(plainText);
  } catch(err) {
    console.error(err);
    return null;
  };
}


/**
 * Derive key and initilisation vector
 * @param password 
 * @param salt 
 * @param iterations 
 * @returns 
 */
const deriveKeyAndIv = async (password:Uint8Array, salt:Uint8Array, iterations=ITERATIONS) => {
  const winCrypto = window.crypto.subtle;
  const keyLength = 32;
  const ivLength = 16;
  const numBits = (keyLength + ivLength) * 8; 
  const passwordKey = await winCrypto.importKey('raw', password, 'PBKDF2', false, ['deriveBits']);
  const derviedBytes = await winCrypto.deriveBits({ name: 'PBKDF2', hash: 'SHA-256', salt: salt, iterations: iterations }, passwordKey, numBits);
  const iv = derviedBytes.slice(keyLength, keyLength + ivLength);
  const key = await winCrypto.importKey('raw', derviedBytes.slice(0, keyLength), ALGORITHM, false, ['encrypt', 'decrypt']);
  return { key, iv };
}

/**
 * Use SubtleCrypto to encrypt data
 * @param password 
 * @param salt 
 * @param plainText 
 * @returns returns a Promise which will be fulfilled with the encrypted data (also known as "ciphertext")
 */
const subtle_encrypt = async (password:Uint8Array, salt:Uint8Array, plainText:Uint8Array) => {
  const { key, iv } = await deriveKeyAndIv(password, salt);
  return crypto.subtle.encrypt({name: ALGORITHM, iv}, key, plainText);
}

const utf8ToUint8Array = (input:string) => new TextEncoder().encode(input);
const arrayBufferToUtf8 = (input:ArrayBuffer) => new TextDecoder().decode(new Uint8Array(input));

/**
 * Convert byte arrays to URL-safe base64 format
 * @param byteArray 
 * @returns 
 */
function base64EncodeURL(byteArr: Uint8Array | ArrayBuffer) {
  const uint8Arr = byteArr instanceof Uint8Array ? byteArr : new Uint8Array(byteArr);
  let s = '';
  for (var i = 0; i < uint8Arr.length; i++) {
    s += String.fromCharCode(uint8Arr[i]);
  }
  return btoa(s).replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');
}

/**
 * Decode base64url encoding
 * @param b64urlstring 
 * @returns Uint8Array
 */
function base64DecodeURL(b64url: string): Uint8Array {
  const b64str = b64url.replace(/-/g, '+').replace(/_/g, '/'); // Replace URL-safe characters and padding
  const binaryStr = atob(b64str);                              // Convert the Base64 string to a binary string
  const byteNumbs = new Uint8Array(binaryStr.length);          // Create a Uint8Array directly from the binary string
  for (let i = 0; i < binaryStr.length; i++) {
    byteNumbs[i] = binaryStr.charCodeAt(i);
  }
  return byteNumbs;
}
