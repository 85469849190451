/**
/* © 2023 University of Cambridge. All rights reserved.  
**/

import { getFT, getRF, RelativeI, Person } from "./Person";
import React, { useState } from "react";
import { Avatar, Box, Button, Card, CardContent, CardHeader, Grid, Typography, useTheme } from "@mui/material";

import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronDownIcon from '@mui/icons-material/KeyboardArrowDown';
import PriorityHighIcon from '@mui/icons-material/PriorityHighRounded';
import { CancerRerport } from "./CancerPanel";
import { GeneticTestsReport } from "./GeneticTests";
import { red } from "@mui/material/colors";
import { get_hgt_ft_ins, get_wgt_st_lbs } from "./HeightBMI";
import { alcoholAmount, alcoholFrequency } from "./AlcoholPanel";
import { toTitleCase } from "./Family";
import { getMHTUsage, getOCUsage, getPeriodsStopped } from "./WomensHealth";
import { makeid } from "../utils/Utilities";
import { SubSectionHeader } from "./SubSectionHeader";


interface SummaryProps {
    printing: boolean;
    gotoSection: (section: string, key: string) => void;
}

export const Summary = React.forwardRef((props: SummaryProps, ref:any) => {
    const {proband, kids, sibs, mother, father, mothers_sibs, fathers_sibs, maternal_grandmother, maternal_grandfather, paternal_grandmother, paternal_grandfather} = getFT();
    const theme = useTheme();
    const c = proband.sex === 'F' ? theme.palette.female.dark : theme.palette.male.dark;

    const getPageMargins = () => {
        return `@page { margin: 3rem 2rem !important; }`;
    };
    //const clight = proband.sex === 'F' ? theme.palette.female.light : theme.palette.male.light;

/*    let nchild = 0;
    for(let i = 0; i < kids.length; i++) {
        nchild += kids[i].number;
    }

    const partners_components = Array.from({ length: partners.length }, (v, i) => 
        <Relatives
            printing={props.printing}
            key={"partner"+i}
            rel={partners[i]} name={(partners.length > 1 ?  "Father ("+(i+1)+") of Children" : "Father of Children")}
            gotoSection={function () {props.gotoSection("ABOUT YOU", "CHILDREN")} }/>
    ); */

    return(
        <>
            <SubSectionHeader title={"Summary"} />
            <Box sx={{ backgroundColor: "seashell", color: theme.palette.primary.dark, border:2, borderColor: theme.palette.primary.dark, borderRadius:"5px 5px 0 0", p:1 }}>
                <Typography variant="body1"  sx={{fontWeight:'700'}}>This symbol <MissingAvatar /> indicates that information is missing.
                You can click on it to go back to the relevant section. If you find a mistake, you can click on the
                relevant section in the blue banner at the top.</Typography>
            </Box>

            <div ref={ref}>
                <style>{getPageMargins()}</style>
                <Card sx={{ backgroundColor: "#fafafa", mt:1 }}>
                    <CardHeader
                        title={<Typography variant="h2" component="div" color="white">Information About You</Typography>}
                        sx={{backgroundColor: c, py:1}}
                    />
                    <CardContent sx={{ p:1 }}>
                        <PersonSummary person={proband} isProband={true} gotoSection={
                            function () {props.gotoSection("ABOUT YOU", "YOUR DETAILS")}} />
                        <Typography sx={{pt:2}} variant="subtitle1">Click below to review the information about your children.</Typography>
                        <Relatives printing={props.printing} key="children" rel={kids} gotoSection={function () {props.gotoSection("ABOUT YOU", "CHILDREN")} } />
                    </CardContent>
                </Card>

                <Card sx={{ backgroundColor: "#fafafa", mt:1 }} className="page-break">
                    <CardHeader
                        title={<Typography variant="h2" component="div" color="white">Information About Your Family</Typography>}
                        sx={{backgroundColor: c, py:1}}/>
                    <CardContent  sx={{ p:1 }}>
                    <Typography variant="subtitle1">Click below to review the information about your family.</Typography>
                        <Relatives printing={props.printing} key="sb" rel={sibs} gotoSection={function () {props.gotoSection("ABOUT YOUR FAMILY", "SIBLINGS")} }/>
                        <Relatives printing={props.printing} key="ma" rel={mother} name="Mother" gotoSection={function () {props.gotoSection("ABOUT YOUR FAMILY", "PARENTS")} }/>
                        <Relatives printing={props.printing} key="pa" rel={father} name="Father" gotoSection={function () {props.gotoSection("ABOUT YOUR FAMILY", "PARENTS")} }/>
                        <Relatives printing={props.printing} key="ms" rel={mothers_sibs} gotoSection={function () {props.gotoSection("ABOUT YOUR FAMILY", "AUNTS & UNCLES")} }/>
                        <Relatives printing={props.printing} key="ps" rel={fathers_sibs} gotoSection={function (section="ABOUT YOUR FAMILY", key="AUNTS & UNCLES") {} }/>
                        <Relatives printing={props.printing} key="mm" rel={maternal_grandmother} name="Grandmother - Mother's side" gotoSection={function () {props.gotoSection("ABOUT YOUR FAMILY", "GRANDPARENTS - MOTHER'S SIDE")} }/>
                        <Relatives printing={props.printing} key="mf" rel={maternal_grandfather} name="Grandfather - Mother's side" gotoSection={function () {props.gotoSection("ABOUT YOUR FAMILY", "GRANDPARENTS - MOTHER'S SIDE")} }/>
                        <Relatives printing={props.printing} key="pm" rel={paternal_grandmother} name="Grandmother - Father's side" gotoSection={function () {props.gotoSection("ABOUT YOUR FAMILY", "GRANDPARENTS - FATHER'S SIDE")} }/>
                        <Relatives printing={props.printing} key="pf" rel={paternal_grandfather} name="Grandfather - Father's side" gotoSection={function () {props.gotoSection("ABOUT YOUR FAMILY", "GRANDPARENTS - FATHER'S SIDE")} }/>
                    </CardContent>
                </Card>
            </div>
        </>
    )
});

/**
 * Get the height and weight
 */
function getHgtWgt(gotoSection: () => void) {
    let hgtStr:JSX.Element|String = (<MissingIcon gotoSection={gotoSection}/>);
    let wgtStr:JSX.Element|String = (<MissingIcon gotoSection={gotoSection}/>);
    const wgtMetric = getRF("wgtMetric");
    if(!isNaN(wgtMetric)) {
        const wgtImperial = get_wgt_st_lbs(wgtMetric);
        if(getRF("wgtUnits") === "metric") {
            if(wgtMetric !== "")
                wgtStr = wgtMetric+"kg"
        } else {
            if(wgtImperial[0] !== undefined && wgtImperial[1] !== undefined)
                wgtStr = wgtImperial[0]+"st "+wgtImperial[1]+"lb";
        }
    }

    const hgtMetric = getRF("hgtMetric");
    if(!isNaN(hgtMetric) && hgtMetric > 0) {
        if(getRF("hgtUnits") === "metric") {
            if(hgtMetric !== 0) hgtStr = hgtMetric+"cm"
        } else {
            const hgtImperial = get_hgt_ft_ins(hgtMetric);
            if(hgtImperial[0] !== 0) hgtStr = hgtImperial[0]+"ft "+hgtImperial[1]+"in";
        }
    }
    return [hgtStr, wgtStr];
}

function getAlcoholRange(amt:number) {
    return Object.keys(alcoholAmount).find(key => (Math.abs(alcoholAmount[key] - amt) < 1e-9));
}

function getAlcohol() {
    const a = getRF("alcohol");
    if(a.frequency === "N" || !a.amount) return "Never";
    return alcoholFrequency[a["frequency"]] + "; " + getAlcoholRange(a["amount"]) + " units on a typical drinking day";
}

function getEthnicity() {
    let etxt = "";
    const ethnic = getRF("ethnic");
    if(ethnic) {
        etxt += ethnic.group;
        const bg = ethnic.background;
        if(bg) {
            if(bg.indexOf("please describe") > -1)
                etxt += ";"+ethnic.backgroundDesc;
            else
            etxt += ";"+ethnic.background;
        }
    }
    return etxt;
}

interface PersonSummaryProps {
    person: Person;
    isProband: boolean;
    gotoSection: () => void;
    desc?: string;
}

function PersonSummary(p:PersonSummaryProps) {
    let hgtStr, wgtStr, periodsStoppedTxt, etxt;

    if(p.isProband) {
        const v = getHgtWgt(p.gotoSection);
        hgtStr = v[0];
        wgtStr = v[1];
        etxt = getEthnicity();
        periodsStoppedTxt = getPeriodsStopped();
    }
    const ps = p.person;
    return (
        <>
        <Grid container>
            {!p.isProband &&
                <Grid item xs={12}><Typography sx={{fontWeight:700}}>{p.desc}</Typography></Grid>
            }

            <Grid item xs={6} columnSpacing="2">
                <Grid container>
                    {p.isProband &&
                        <>
                        <Grid item xs={6}><Typography>Surname:</Typography></Grid>
                        <Grid item xs={6}><Typography noWrap>{ps.name ? ps.name : <MissingIcon gotoSection={p.gotoSection}/>}</Typography></Grid>
                        </>
                    }

                    <Grid item xs={6}> <Typography>Sex:</Typography></Grid>
                    <Grid item xs={6}><Typography>{ps.sex === "F" ? "Female" : "Male"}</Typography></Grid>

                    <Grid item xs={6}> <Typography>{ps.status === 0 ? "Age": "Age at Death"}:</Typography></Grid>
                    <Grid item xs={6}><Typography>{ps.age !== 0 ? ps.age : <MissingIcon gotoSection={p.gotoSection}/>}</Typography></Grid>

                    <Grid item xs={6}> <Typography>Year of Birth:</Typography></Grid>
                    <Grid item xs={6}><Typography>{ps.yob !== 0 && !isNaN(ps.yob) ? ps.yob : <MissingIcon gotoSection={p.gotoSection}/>}</Typography></Grid>
                </Grid>
            </Grid>
            <Grid item xs={6}>
                <Grid container columnSpacing="2">
                    {p.isProband &&
                        <>
                            <Grid item xs={6}><Typography>Height:</Typography></Grid>
                            <Grid item xs={6}>{hgtStr ? <Typography>{hgtStr}</Typography>:<MissingIcon gotoSection={p.gotoSection}/>}</Grid>

                            <Grid item xs={6}><Typography>Weight:</Typography></Grid>
                            <Grid item xs={6}>{wgtStr ? <Typography>{wgtStr}</Typography>:<MissingIcon gotoSection={p.gotoSection}/>}</Grid>
                        </>
                    }
                </Grid>
            </Grid>
        </Grid>

        <Grid container>
                <Grid item sm={6} xs={12}>
                    <Grid container columnSpacing="2">
                    <Grid item xs={6}><Typography>Cancer Diagnosis:</Typography></Grid>
                    <Grid item xs={6}><CancerRerport person={ps} /></Grid>

                    <Grid item xs={6}><Typography>Any genetic pathogenic variant(s):</Typography></Grid>
                    <Grid item xs={6}><GeneticTestsReport geneTests={ps.geneTests} /></Grid>
                    </Grid>
                </Grid>
        </Grid>

        {p.isProband &&
            <Grid container sx={{pt:1}}>
                <Grid item sm={6} xs={12}>
                    <Grid container columnSpacing="2">
                        <Grid item xs={6}><Typography>Ethnicity:</Typography></Grid>
                        <Grid item xs={6}><Typography>{etxt}</Typography></Grid>

                        <Grid item xs={6}><Typography>Ashkenazi Jewish:</Typography></Grid>
                        <Grid item xs={6}><Typography>{ps.ashkenazi === 0 ? "No" : "Yes"}</Typography></Grid>

                        <Grid item xs={6}><Typography>Alcohol intake:</Typography></Grid>
                        <Grid item xs={6}><Typography>{getAlcohol()}</Typography></Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12}><Typography sx={{fontWeight:700, py:1}}>Reproductive Health</Typography></Grid>

                <Grid item xs={6}>
                    <Grid container columnSpacing="2">
                        <Grid item xs={6}><Typography>Age at first period:</Typography></Grid>
                        <Grid item xs={6}><Typography>{isNaN(getRF("menarche")) ? "Unknown" : getRF("menarche")}</Typography></Grid>

                        <Grid item xs={6}><Typography>Oral contraceptive usage:</Typography></Grid>
                        <Grid item xs={6}><Typography>{getOCUsage()}</Typography></Grid>
                    </Grid>
                </Grid>

                <Grid item xs={6}>
                    <Grid container columnSpacing="2">
                        <Grid item xs={6}><Typography>Periods now stopped because of menopause:</Typography></Grid>
                        <Grid item xs={6}><Typography>{periodsStoppedTxt}</Typography></Grid>

                        <Grid item xs={6}><Typography>Hormone replacement therapy usage:</Typography></Grid>
                        <Grid item xs={6}><Typography>{getMHTUsage()}</Typography></Grid>
                    </Grid>
                </Grid>
            </Grid>
        }
        </>
    )
}

interface RelativesProps {
    rel:RelativeI[]|Person;
    name?:string;
    gotoSection: () => void;
    printing: boolean;
}

function errors(persons:Person[]) {
    let n = 0;
    for(let i = 0; i < persons.length; i++) {
        if(!persons[i].yob) n++;
    }
    return n;
}

function Relatives(props:RelativesProps) {
    const [show, setShow] = useState(false);
    const rel = props.rel;
    const els = [];
    let names = "";
    let errs = 0;
    const rid = makeid()+props.printing;

    if(Array.isArray(rel)) {
        for (let i = 0; i < rel.length; i++) {
            const r = rel[i].persons;
            const name = toTitleCase(rel[i].name);
            names += (i>0 ? " / "+name : name);
            if(!r) {
                els.push(<Typography key={rid+i} sx={{fontWeight:700}}>No {name.toLowerCase()}</Typography>);
                names += " (0)"
                continue;
            };

            names += " ("+r.length+")";
            errs += errors(r);
            els.push( r.map((c, idx) => {
                console.log(name+idx)
                return (
                    <PersonSummary person={c}
                                   isProband={false}
                                   key={rid+name+idx}
                                   desc={name.replace(/('s|s)\s*$/, "")+" "+(idx+1)+". "}
                                   gotoSection={props.gotoSection} />
                )
            }));
        }
    } else {
        els.push(<PersonSummary key={rid + rel.name} person={rel} isProband={false} gotoSection={props.gotoSection} />);
        names += props.name;
        errs += errors([rel]);
    }

    return (
    <Box key={rid+"btn"} >
        <Button sx={{p:0}} size="small" color="secondary" onClick={() => setShow(!show)}>
            {show || props.printing ? <ChevronDownIcon key={rid+"dwn"} fontSize="small"/> : <ChevronRightIcon className="openSct" key={rid+"rgt"} fontSize="small"/> }
            <Typography sx={{fontWeight:700}}> {names} </Typography>
            {errs > 0 &&
                <PriorityHighIcon fontSize="small" sx={{ color: red[900], px:0 }} />
            }
        </Button>

        {(show || props.printing) && <Box sx={{ml:3}}>{els}</Box>}
    </Box>);
}


interface MissingIconProps {
    gotoSection: () => void;
}

function MissingIcon(props:MissingIconProps) {
    return (
        <Button size="small" title="Missing information" onClick={props.gotoSection} sx={{px:0}} style={{minWidth: '10px'}}>
            <MissingAvatar />
        </Button>
    )
};

function MissingAvatar() {
    return (
        <Avatar sx={{ width: 20, height: 20, mx:0.2, display:'inline-block', verticalAlign: 'middle' }} component="span">
            <PriorityHighIcon fontSize="small" sx={{ color: red[900] }} />
        </Avatar>
    )
};
