/**
/* © 2023 University of Cambridge. All rights reserved.  
**/

import React from "react";
import { Autocomplete, AutocompleteChangeReason, Box, Card, CardContent, Collapse, FormControl, FormControlLabel, FormLabel,
    Grid, MenuItem, Radio, RadioGroup, Select, SelectChangeEvent, TextField, Typography, useTheme } from "@mui/material";
import { HRT_TABLETS } from "../utils/HRT_types";
import CircleIcon from '@mui/icons-material/Circle';
import { getFT, getRF, setRF } from "./Person";
import MyTooltip from "./MyTooltip";

export default function WomensHealth(props: {}) {
    const bg = useTheme().palette.primary.xtralight;
    return (
        <Grid container spacing={2}>

            <Grid item sm={6}>
                <Card sx={{ backgroundColor: bg, borderRadius:2, height: '100%' }}>
                    <CardContent>
                        <AgeAtFirstPeriod/>
                    </CardContent>
                </Card>
            </Grid>

            <Grid item sm={6}>
                <Card sx={{ backgroundColor: bg, borderRadius:2 }}>
                    <CardContent>
                        <AgeOfMenopause/>
                    </CardContent>
                </Card>
            </Grid>

            <Grid item sm={6}>
                <Card sx={{ backgroundColor: bg, borderRadius:2, height: '100%' }}>
                    <CardContent>
                        <OralContraception />
                    </CardContent>
                </Card>
            </Grid>

            <Grid item sm={6}>
                <Card sx={{ backgroundColor: bg, borderRadius:2 }}>
                    <CardContent>
                        <MHTUse />
                    </CardContent>
                </Card>
            </Grid>
            
        </Grid>
    )
}

/**
 * Get OC usage for summary page or canrisk file
 * NA=unspecified,
 * N=Never,
 * F:Years=Former use,
 * C:Years=Current use;
 * F and C are followed by integer=number of years taken e.g. F:4 means former use of 4 years
 * @param cff - set to true to return Canrisk File Format
 * @return return OC usage as readable text or CanRisk File Format
 */
export function getOCUsage(cff:boolean=false) {
    const ocUsage = getRF("ocUsage");
    if(ocUsage.usage === "U")
        return (cff ? "NA" : "Unknown");
    else if(ocUsage.usage === "N")
        return (cff ? "N" : "No");

    let oc = "";
    if(ocUsage.nyears) {
        oc = cff ? ":"+ocUsage.nyears : "; "+ocUsage.nyears + " years taken";
    }
    if(ocUsage.isCurrent) {
        return (cff ? "C" : "Current")+oc;
    }
    return (cff ? "F" : "Former")+oc;
}

export function getPeriodsStopped() {
    let periodsStoppedTxt;
    const periodsStopped = getRF("periodsStopped");
    if(periodsStopped.haveStopped === "U") {
        periodsStoppedTxt = "Unknown";
    } else {
        if(periodsStopped.haveStopped === "N") {
            periodsStoppedTxt = "No";
        } else {
            periodsStoppedTxt = "Yes; ";
            if(periodsStopped.age) {
                if(periodsStopped.age < 40){
                    periodsStoppedTxt += "younger than 40 years old";
                } else if(periodsStopped.age < 45) {
                    periodsStoppedTxt += "between the ages of 40 and 44";
                } else if(periodsStopped.age < 50) {
                    periodsStoppedTxt += "between the ages of 45 and 49";
                } else if(periodsStopped.age < 55) {
                    periodsStoppedTxt += "between the ages of 50 and 54";
                } else if(periodsStopped.age > 54) {
                    periodsStoppedTxt += "when older than 54 years old";
                }
            } else {
                periodsStoppedTxt += "age not specified";
            }
        }
    }
    return periodsStoppedTxt;
}

/**
 * Get MHT usage for summary page or canrisk file
 * NA=unspecified,
 * N=Never used,
 * F=Former use,
 * E=Current E-type use,
 * C=Current other/unknown type (including combined type) use
 * @param cff - set to true to return Canrisk File Format 
 * @returns return MHT usage as readable text or CanRisk File Format
 */
export function getMHTUsage(cff:boolean=false) {
    const mhtUsage = getRF("mhtUsage");
    if(mhtUsage.usage === "U")
        return (cff ? "NA" : "Unknown");
    else if(mhtUsage.usage === "N")
        return (cff ? "N" : "No");
    
    if(mhtUsage.isCurrent) {
        const msg = "Used in the last 5 years";
        if(mhtUsage.currentType) {
            if(cff)
                return (mhtUsage.currentType === "E" ? "E" : "C");
            else
                return msg+(mhtUsage.currentType === "E" ? "; E-type" : "; other/unknown type (including combined type)")
        }
        return (cff ? "C" : msg);
    } else {
        return (cff ? "F" : "Not used in the last 5 years");
    }
}

function AgeAtFirstPeriod() {
    const [menarcheAge, setMenarcheAge] = React.useState((getRF("menarche")?getRF("menarche").toString():"NaN"));

    const handleChange = (event: SelectChangeEvent) => {
        setMenarcheAge(event.target.value);
        setRF("menarche", parseInt(event.target.value));
    };

    const possibleNames = [
        { id: "NaN", name: "Unknown" },
        { id: "10", name: "<11" },
        { id: "11", name: "11" },
        { id: "12", name: "12" },
        { id: "13", name: "13" },
        { id: "14", name: "14" },
        { id: "15", name: "15" },
        { id: "16", name: ">15" },
      ];

    return (
        <>
            <FormLabel id="menarche-label" sx={{mt:5, pr:1, fontWeight:700}} >How old were you when you had your first period?</FormLabel>
            <FormControl>

            <Select
                data-testid="select-menarche"
                aria-labelledby="menarche-label"
                size="small"
                value={menarcheAge}
                sx={{ minWidth: 120 }}
                onChange={handleChange}
            >
                {possibleNames.map((n, index) => (
                    <MenuItem
                        key={index}
                        value={n.id}
                        data-testid={`menarche-item-${n.id}`}
                    >
                        {n.name}
                    </MenuItem>
                ))}
            </Select>
            </FormControl>
        </>
    )
}

/**
 * Age of menopause
 * @returns component - age of periods stopped
 */
function AgeOfMenopause() {
    const [periodsStopped, setPeriodsStopped] = React.useState(getRF("periodsStopped").haveStopped);

    const periodsStoppedChange = ((event: React.ChangeEvent<HTMLInputElement>, value: string) => {
        const psStopped = value as "Y"|"N"|"U";
        setPeriodsStopped(psStopped);

        const ps = getRF("periodsStopped");
        ps.haveStopped = psStopped;
        if(ps !== "Y") delete ps.age;
        setRF("periodsStopped", ps);
    });

    const agePeriodsStoppedChange = ((event: React.ChangeEvent<HTMLInputElement>, value: string) => {
        const ps = getRF("periodsStopped");
        ps.age = parseInt(value);
        setRF("periodsStopped", ps);
    });
    const age = getFT().proband.age;

    return (
        <div data-testid="stoppedPeriods">
            <FormLabel id="rbtn-grp-periods" sx={{fontWeight:700}}>
                Have your periods now stopped completely because you are in the menopause?

                <MyTooltip
                    outline={false}
                    title={"That is, have you now gone at least 6 months without having a period "+
                           "and you are not pregnant or using any form of hormonal contraception.\n\n"+
                           "If you have had surgery to remove your ovaries before you went through the menopause, please select yes.\n\n"+
                           "If you have taken any medicine that stopped your periods completely before "+
                           "you went through the menopause, please select unknown."}
                    areaLabel={"Periods stopped information button"} />
            </FormLabel>
            <RadioGroup
                row
                key={periodsStopped}
                aria-labelledby="rbtn-grp-periods"
                defaultValue={periodsStopped}
                name="radio-periods"
                onChange={periodsStoppedChange}
                sx={{ml:2, mb:2}}
                >
                <FormControlLabel value="Y" control={<Radio size="small"/>} label="Yes"/>
                <FormControlLabel value="N" control={<Radio size="small"/>} label="No" />
                <FormControlLabel value="U" control={<Radio size="small" />} label="Unknown" />
            </RadioGroup>

            <Collapse in={periodsStopped === "Y"} timeout="auto" unmountOnExit>
                <FormLabel id="rbtn-grp-age-menopause" sx={{fontWeight:700}}>How old were you when your periods stopped completely?</FormLabel>
                <RadioGroup
                    aria-labelledby="rbtn-grp-age-menopause"
                    defaultValue={periodsStopped.age ? periodsStopped.age.toString() : null}
                    name="radio-menopause"
                    onChange={agePeriodsStoppedChange}
                    sx={{ml:2}}
                    key={periodsStopped.age}
                    >
                    <FormControlLabel value="39" disabled={false} control={<Radio size="small"/>} label="younger than 40 years old" />
                    <FormControlLabel value="42" disabled={(age !== undefined && age<40)} control={<Radio size="small"/>} label="between the ages of 40 and 44" />
                    <FormControlLabel value="47" disabled={(age !== undefined && age<45)} control={<Radio size="small" />} label="between the ages of 45 and 49" />
                    <FormControlLabel value="52" disabled={(age !== undefined && age<50)} control={<Radio size="small" />} label="between the ages of 50 and 54" />
                    <FormControlLabel value="55" disabled={(age !== undefined && age<55)} control={<Radio size="small" />} label="when older than 54 years old" />
                </RadioGroup>
            </Collapse>
        </div>
    )
}

/**
 * Oral contraception usage, OC_use
 * NA=unspecified, N=Never, F:Years=Former use, C:Years=Current use;
 * F and C are followed by integer=number of years taken e.g. F:4 means former use of 4 years
 * @returns component
 */
function OralContraception() {
    const ocUsage = getRF("ocUsage");
    const [usage, setUsage] = React.useState(ocUsage.usage);

    const ocUsageChange = ((event: React.ChangeEvent<HTMLInputElement>, value: string) => {
        const u = value as "Y"|"N"|"U";
        setUsage(u)
        ocUsage.usage = u;
        setRF("ocUsage", ocUsage);
    });

    const nyearsChange = ((event: React.ChangeEvent<HTMLInputElement>, value: string) => {
        ocUsage.nyears = value;
        setRF("ocUsage", ocUsage);
    });

    const currentChange = ((event: React.ChangeEvent<HTMLInputElement>, value: string) => {
        ocUsage.isCurrent = value === "Y";
        setRF("ocUsage", ocUsage);
    });

    return (
        <div data-testid="ocUsage">
            <FormLabel id="rbtn-grp-oc" sx={{fontWeight:700}}>
                Have you ever taken the oral contraceptive pill?

                <MyTooltip
                    outline={false}
                    title={"Have you ever (currently or in the past) used any form of oral contraceptive - "+
                           "this includes both the combined pill and the progesterone-only/mini pill. "+
                           "It does not include other forms of hormonal contraception such as the "+
                           "contraceptive implant, patch, injection or Mirena coil."}
                    areaLabel={"Oral contraception information button"} />
            </FormLabel>
            <RadioGroup
                row
                key={usage}
                aria-labelledby="rbtn-grp-oc"
                defaultValue={usage}
                name="radio-periods"
                onChange={ocUsageChange}
                sx={{ml:2, mb:2}}
                >
                <FormControlLabel value="Y" control={<Radio size="small"/>} label="Yes" />
                <FormControlLabel value="N" control={<Radio size="small"/>} label="No" />
                <FormControlLabel value="U" control={<Radio size="small" />} label="Unknown" />
            </RadioGroup>

            <Collapse in={usage === "Y"} timeout="auto" unmountOnExit>
                <FormLabel id="rbtn-grp-oc-yrs" sx={{fontWeight:700}}>How many years in total?</FormLabel>

                <MyTooltip
                    outline={false}
                    title={"If you are unsure how many years in total you have taken oral contraception, "+
                           "please choose the category you think is closest."}
                    areaLabel={"Oral contraception time information button"} />

                <RadioGroup
                    aria-labelledby="rbtn-grp-oc-yrs"
                    defaultValue={ocUsage.nyears}
                    name="radio-menopause"
                    onChange={nyearsChange}
                    sx={{ml:2, mb:2}}
                    >
                    <FormControlLabel value="<1" control={<Radio size="small"/>} label="less than 1 year" />
                    <FormControlLabel value="2" control={<Radio size="small"/>} label="1-4 years" />
                    <FormControlLabel value="7" control={<Radio size="small" />} label="5-9 years" />
                    <FormControlLabel value="12" control={<Radio size="small" />} label="10-14 years" />
                    <FormControlLabel value="15" control={<Radio size="small" />} label="15 or more years" />
                </RadioGroup>

                <FormLabel id="rbtn-grp-oc-current" sx={{fontWeight:700}}>Have you taken the pill in the last two years?</FormLabel>
                <RadioGroup
                    row
                    aria-labelledby="rbtn-grp-oc-current"
                    defaultValue={ocUsage.isCurrent ? "Y" : "N"}
                    name="radio-periods"
                    onChange={currentChange}
                    sx={{ml:2}}
                    >
                    <FormControlLabel value="Y" control={<Radio size="small"/>} label="Yes" />
                    <FormControlLabel value="N" control={<Radio size="small"/>} label="No" />
                </RadioGroup>
            </Collapse>
        </div>
    )
}


/**
 * Use of menopause hormone therapy
 * NA=unspecified
 * N=Never used
 * F=Former use
 * E=Current E-type use
 * C=Current other/unknown type (including combined type) use
 * @returns 
 */
function MHTUse() {
    const mhtUsage = getRF("mhtUsage");
    const [usage, setUsage] = React.useState(mhtUsage.usage);
    const [currentUsage, setCurrentUsage] = React.useState(mhtUsage.isCurrent);
    const [currentType, setCurrentType] = React.useState(mhtUsage.currentType);

    const mhtUsageChange = ((event: React.ChangeEvent<HTMLInputElement>, value: string) => {
        const u = value as "Y"|"N"|"U";
        setUsage(u)
        mhtUsage.usage = u;
        if(u !== "Y") delete mhtUsage.isCurrent;
        setRF("mhtUsage", mhtUsage);
    });

    const currentChange  = ((event: React.ChangeEvent<HTMLInputElement>, value: string) => {
        mhtUsage.isCurrent = value === "Y";
        setCurrentUsage(value === "Y");
        setRF("mhtUsage", mhtUsage);
    });

    const typeChange  = ((event: React.ChangeEvent<HTMLInputElement>, value: string) => {
        mhtUsage.currentType = value as "E"|"C";
        setRF("mhtUsage", mhtUsage);
    });

    return (
        <div data-testid="hrtUsage">
            <FormLabel id="rbtn-grp-mht" sx={{fontWeight:700}}>Have you ever used hormone replacement therapy (HRT) for the menopause? </FormLabel>
            <RadioGroup
                row
                aria-labelledby="rbtn-grp-mht"
                defaultValue={usage}
                name="radio-hrt"
                onChange={mhtUsageChange}
                sx={{ml:2, mb:2}}
                key={usage}
                >
                <FormControlLabel value="Y" control={<Radio size="small"/>} label="Yes" />
                <FormControlLabel value="N" control={<Radio size="small"/>} label="No" />
                <FormControlLabel value="U" control={<Radio size="small" />} label="Unknown" />
            </RadioGroup>

            <div data-testid="hrtcurrentUsage">
            <Collapse in={usage === "Y"} timeout="auto" unmountOnExit>
                <FormLabel id="rgrp-mht-current" sx={{fontWeight:700}}>Have you used HRT in the last 5 years?</FormLabel>
                <RadioGroup
                    row
                    aria-labelledby="rgrp-mht-current"
                    defaultValue={currentUsage ? "Y" : "N"}
                    name="radio-periods"
                    onChange={currentChange}
                    sx={{ml:2, mb:2}}
                    key={currentUsage? "Y" : "N"}
                    >
                    <FormControlLabel value="Y" control={<Radio size="small"/>} label="Yes" />
                    <FormControlLabel value="N" control={<Radio size="small"/>} label="No" />
                </RadioGroup>

                {currentUsage && 
                    <>
                    <FormLabel id="radio-grp-mht-type" sx={{fontWeight:700}}>What is the name of the HRT you have used?</FormLabel>
                    <Typography variant="subtitle1" sx={{pt:1}}>To help you remember the hormone preparation,
                    we have given you a list of the most common ones below. Select from this list:</Typography>

                    <Grid container direction="row">
                        <Grid item>
                            <Autocomplete
                                sx={{my:1, mr:1}}
                                options={HRT_TABLETS}
                                //defaultValue={hrtBrands[pidx]}
                                getOptionLabel={(option) => option[0]}
                                style={{ width: 200 }}
                                size="small"
                                renderOption={(props, option) => (
                                    <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                        {option[0]}
                                        <ColorCircle color={(option[1] === "C-type" ? "warning" : "error")}/>
                                    </Box>
                                )}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Choose an HRT"
                                        inputProps={{ ...params.inputProps }}
                                    />
                                )}
                                onChange={(event: React.SyntheticEvent<Element, Event>, value: string[] | null, reason: AutocompleteChangeReason) => {
                                    if(reason === "selectOption" && value !== null) {
                                        if(value[1] === "C-type") {
                                            setCurrentType("C");
                                            mhtUsage.currentType = "C";
                                        } else if (value[1] === "E-type") {
                                            setCurrentType("E");
                                            mhtUsage.currentType = "E";
                                        }
                                    };
                                }}
                            />
                        </Grid>

                        <Grid item>
                            <Typography variant="subtitle1" sx={{pl:1, pt:2}}>Or:</Typography>
                        </Grid>
                    </Grid>

                    <RadioGroup
                        aria-labelledby="radio-grp-mht-type"
                        defaultValue={currentType ? currentType : null}
                        name="radio-periods"
                        onChange={typeChange}
                        sx={{ml:2}}
                        key={currentType}
                        >
                        <FormControlLabel value="E" control={<Radio size="small"/>} label={<>Known Oestrogen Monotherapy HRT <ColorCircle color="error"/></>} />
                        <FormControlLabel value="C" control={<Radio size="small"/>} label={<>Known Combined HRT <ColorCircle color="warning"/></>} />
                        <FormControlLabel value="O" control={<Radio size="small"/>} label="Other Type of HRT" />
                        <FormControlLabel value="U" control={<Radio size="small" />} label="Unknown Type of HRT" />

                    </RadioGroup>
                    </>
                }
            </Collapse>
            </div>
        </div>
    )
}

interface ColorCircleProps {
    color: "primary" | "secondary" | "error" | "info" | "success" | "warning" | "disabled" | "inherit" | "action" | undefined
}

function ColorCircle(props:ColorCircleProps) {
    return (
        <CircleIcon fontSize="small" color={props.color} sx={{verticalAlign: "middle"}}/>
    )
}
