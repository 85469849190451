/**
/* © 2023 University of Cambridge. All rights reserved.  
**/
import { getFT, Person, setFamilyTree } from "./Person";
import React, { useState } from "react";
import { Box, Card, CardContent, Grid, Palette, TextField, Typography, useTheme } from "@mui/material";
import { toTitleCase } from "./Family";
import { Cancer } from "../utils/Cancers";
import { clearContents, makeid } from "../utils/Utilities";
import { red } from "@mui/material/colors";
import MyDate from "./MyDate";
import { SubSectionHeader } from "./SubSectionHeader";


const Intro = (props: {hasDiagnoses:boolean}) => {
    return (
        <>
            {props.hasDiagnoses &&
                <>
                    <Typography variant="body1" component="div" gutterBottom={true}>
                    In the previous sections, you have indicated that members of your family have been diagnosed with cancer. 
                    </Typography>
                    <Typography variant="body1" component="div" gutterBottom={true}>
                    In some cases, we may need to find out more information about the cancer they have/had so we can calculate your risk more accurately.  
                    </Typography>
                    <Typography variant="body1" component="div" gutterBottom={true}>
                    We will not contact any of your family members without your consent.
                    <ul>
                        <li>
                            If that person is still alive, we will send you a letter to give to them asking for more information or to be able to access their medical records.
                        </li> 
                        <li>
                            If that person has died, we will access any information available to us through their medical records and other cancer registries.
                        </li>
                    </ul>
                    To help us with this process, please provide as much information as you can:
                    </Typography>
                </>
            }
            {!props.hasDiagnoses && 
                <Typography variant="body1" component="div">
                    You have not indicated any family members as being diagnosed with cancer; please continue.
                </Typography>
            }
        </>
    )
}

export const CancerDiagnosesData = ((props: {}) => {
    const {proband, mother, father, maternal_grandmother, maternal_grandfather, paternal_grandmother, paternal_grandfather} = getFT();
    const allWithCancer = getGroupsWithCancer();
    const theme = useTheme();
    /* const allPartnersWithCancer = partners.filter(function (ps) {
        return getMyCancers(ps).length > 0;
    }); */

    const pdetails:(Element | any[]) = [];
    appendDetails(pdetails, proband, 'You', theme.palette);

    /* if(allPartnersWithCancer.length > 0)
        pdetails.push([(<Typography sx={{fontWeight:700}} key={makeid()}> Partner(s) </Typography>)]);
    for (let i = 0; i < allPartnersWithCancer.length; i++) {
        pdetails.push(<PersonDetails person={allPartnersWithCancer[i]} isProband={false} key={makeid()} />);
    } */

    for (let i = 0; i < allWithCancer.length; i++) {
        const ps = allWithCancer[i].persons;
        if(!ps) continue;

        const bg = ps[0].sex === 'F' ? theme.palette.female.main : theme.palette.male.main;
        const sx = {fontWeight:700, backgroundColor:bg, color:"white", p:1, ml:1, mt:3, borderRadius:2};

        const onlyWithCancer = ps.filter(function (p: Person) {
            return Cancer.getMyCancers(p).length > 0;
        });

        pdetails.push([(<Typography sx={sx} align="center" key={makeid()}> {toTitleCase(allWithCancer[i].name)} </Typography>)]);
        pdetails.push( onlyWithCancer.map((p) => { return <PersonDetails person={p} isProband={false} key={makeid()} /> }) );
    }

    appendDetails(pdetails, mother, 'Mother', theme.palette);
    appendDetails(pdetails, father, 'Father', theme.palette);
    appendDetails(pdetails, maternal_grandmother, "Grandmother on mother's side", theme.palette);
    appendDetails(pdetails, maternal_grandfather, "Grandfather on mother's side", theme.palette);
    appendDetails(pdetails, paternal_grandmother, "Grandmother on father's side", theme.palette);
    appendDetails(pdetails, paternal_grandfather, "Grandfather on father's side", theme.palette);

    return(
        <>
            <SubSectionHeader title={"Cancer Diagnoses"} />
            <Card sx={{ backgroundColor: theme.palette.primary.xtralight }}>
                <CardContent>
                <Intro hasDiagnoses={pdetails.length > 0} /> 
                {pdetails}
                </CardContent>
            </Card>
        </>
    )
});

/**
 * Get type of relatives (children, siblings, aunts, uncles) with one or more cancer diagnosis.
 * @returns 
 */
function getGroupsWithCancer() {
    const {kids, sibs, mothers_sibs, fathers_sibs} = getFT();
    const all = kids.concat(sibs, mothers_sibs, fathers_sibs);
    return all.filter(function (a) {
        const ps =  a.persons;
        if(ps)
            for(let i = 0; i < ps.length; i++) {
                if(Object.keys(ps[i].cancers).length !== 0) return true;
            }
        return false;
    });
}

/**
 * Add details to be rendered for the given person if they have a cancer diagnosis
 * @param p - Person
 * @param name - Relationship name (e.g. mother) of the person
 * @returns 
 */
function appendDetails(pdetails:(Element | Element[])[], p:Person, name:string, palette:Palette) {
    if(Cancer.getMyCancers(p).length > 0) {
        const bg = p.sex === 'F' ? palette.female.main : palette.male.main;
        const sx = {fontWeight:700, backgroundColor:bg, color:"white", p:1, ml:1, mt:3, borderRadius:2};
        const mydetails: (any)[] = [];
        mydetails.push([(<Typography sx={sx} align="center" key={makeid()}> {name} </Typography>)]);
        mydetails.push(<PersonDetails person={p} isProband={true} key={makeid()} />)
        pdetails.push(mydetails);
    }
}

interface PersonDetailsProps {
    person: Person;
    isProband: boolean;
    desc?: string;
}

function PersonDetails(p:PersonDetailsProps) {
    const ps = p.person;
    const mycancers = Cancer.getMyCancers(ps);
    const [dob, setDOB] = useState<Date|null>(ps.extraDetails?.dob ? ps.extraDetails.dob : null);
    const [dod, setDOD] = useState<Date|null>(ps.extraDetails?.dod ? ps.extraDetails.dod : null);

    const uid = makeid();
    const isAlive = ps.status === 0;
    const addWarning = (wrn:string) => {
        const theDiv = clearContents("warnings-"+uid);
        const content = document.createTextNode(wrn);
        theDiv?.appendChild(content);
    }

    const cs = mycancers.map((c, idx) => {
        const mycancer = ps.cancers[c.cancer];
        const cancerType = (c.cancer !== "Other" ? c.cancer+" Cancer" : mycancer.otherCancerName);
        const cancerAge = (mycancer.age > -1 ? mycancer.age : "unknown");

        return (
            <Grid container rowSpacing={1} columnSpacing={2} key={makeid()}>
                <Grid item sm={4} xs={12} sx={{mt:0.5}}>
                    <Box display="flex" justifyContent="flex-end" sx={{mr:1}}>
                        <Typography>{cancerType} (age {cancerAge})</Typography>
                    </Box>
                </Grid>
                <Grid item sm={4} xs={12}>
                    <TextField 
                        defaultValue={mycancer.homeAddress}
                        fullWidth={true}
                        label="Home address at diagnosis [if known]" 
                        variant="outlined" 
                        size="small" 
                        type="string"
                        onChange={function(event: React.ChangeEvent<HTMLInputElement>) {
                            mycancer.homeAddress = event.target.value.trim();
                            setFamilyTree();
                        }} 
                    />
                </Grid>

                <Grid item sm={4} xs={12}>
                    <TextField 
                        defaultValue={mycancer.treatmentCenter}
                        fullWidth={true}
                        label="Hospital where treated [if known]" 
                        variant="outlined" 
                        size="small" 
                        type="string"
                        onChange={function(event: React.ChangeEvent<HTMLInputElement>) {
                            mycancer.treatmentCenter = event.target.value.trim();
                            setFamilyTree();
                        }} 
                    />
                </Grid>
            </Grid>
        )
    })

    return (
        <Card sx={{my:1, ml:1, p:1, backgroundColor: "#fafafa"}}>
            <Grid container rowSpacing={1} columnSpacing={2}>
                <Grid item md={4} sm={6}  xs={12}>
                    <TextField
                        defaultValue={ps.extraDetails?.fullname}
                        fullWidth={true}
                        label={"Full name"}
                        variant="outlined"
                        size={"small"}
                        type="string"
                        onChange={function(e: React.ChangeEvent<HTMLInputElement>) {
                            const n = e?.target.value.trim();       // trim start/end blank spaces
                            if(ps.extraDetails)
                                ps.extraDetails.fullname = n;
                            else
                                ps.extraDetails = {'fullname': n};
                            setFamilyTree();
                        }} 
                    />
                </Grid>

                <Grid item md={4} sm={6} xs={12}>
                    <TextField
                        defaultValue={ps.extraDetails?.othername}
                        fullWidth={true}
                        label={'Any previous names'}
                        variant="outlined"
                        size={"small"}
                        type="string"
                        onChange={function(e: React.ChangeEvent<HTMLInputElement>) {
                            const n = e?.target.value.trim();       // trim start/end blank spaces
                            if(ps.extraDetails)
                                ps.extraDetails.othername = n;
                            else
                                ps.extraDetails = {'othername': n};
                            setFamilyTree();
                        }} 
                    />
                </Grid>

                <Grid item md={2} sm={3} xs={12}>
                    <MyDate
                        mydate={dob}
                        dateLabel={"Date of birth"}
                        views={["year", "day"]}
                        required={false}
                        dateChange={function (date: any): void {
                            addWarning("");
                            if(date === null) {
                                delete ps.extraDetails?.dob;
                                return;
                            }

                            if(ps.extraDetails)
                                ps.extraDetails.dob = date
                            else
                                ps.extraDetails = {'dob': date};
                            setDOB(date);

                            if(ps.yob && ( Math.abs(ps.yob - date.getFullYear()) > 0 )) {
                                addWarning("Date of birth does not match the year of birth ("+
                                           ps.yob+") previously indicated.");
                            }
                        }} />
                </Grid>

                <Grid item md={2} sm={3} xs={12}>
                    { !isAlive &&
                        <>
                        <MyDate 
                            mydate={dod}
                            dateLabel={"Date of death"}
                            views={["year", "day"]}
                            required={false}
                            dateChange={function (date: any): void {
                                addWarning("");
                                if(date === null){
                                    delete ps.extraDetails?.dod;
                                    return;
                                }

                                if(ps.extraDetails)
                                    ps.extraDetails.dod = date
                                else
                                    ps.extraDetails = {'dod': date};
                                setDOD(date);

                                if(dob && ps.age) {
                                    const yod = dob.getFullYear() + ps.age;
                                    const diff = Math.abs(date.getFullYear()-yod);
                                    if(diff > 1)
                                        addWarning("Age at death ("+ps.age+") and year of birth ("+
                                        dob.getFullYear()+") suggest a different year of death ("+yod+").");
                                }
                            } } />
                        </>
                    }
                </Grid>
            </Grid>

            <Grid item xs={12}>
                <Box display="flex" justifyContent="flex-end" sx={{mr:1}}>
                    <Typography sx={{ color: red[900], textTransform: "uppercase", fontWeight: 'bold' }} id={"warnings-"+uid}></Typography>
                </Box>
            </Grid>

            <Grid item sm={12} xs={12}>
                <Typography sx={{fontWeight:700, mt:1}}>Cancer Diagnosis:</Typography>
            </Grid>
            {cs}
        </Card>
    )
}


/**
 * Get comma sepearate list of details of family members with cancer diagnoses
 * @returns 
 */
export function getCancerCSV() {
    const {proband, mother, father, maternal_grandmother, maternal_grandfather, paternal_grandmother, paternal_grandfather} = getFT();
    const allWithCancer = getGroupsWithCancer();
    /* const allPartnersWithCancer = partners.filter(function (ps) {
        return getMyCancers(ps).length > 0;
    }); */

    const HDR = ["Relative", "Full name", "Maiden/Other name",
                "Age", "DOB", "Alive", "DOD",
                "Cancer", "Diagnosis Age", "Home address", "Treatment Center",
                "Cancer", "Diagnosis Age", "Home address", "Treatment Center",
                "Cancer", "Diagnosis Age", "Home address", "Treatment Center",
                "Cancer", "Diagnosis Age", "Home address", "Treatment Center"];

    let deets = HDR.join(",")+"\n";
    deets += getCSVLine(proband, "Self");
    deets += getCSVLine(mother, "Mother");
    deets += getCSVLine(father, "Father");
    deets += getCSVLine(maternal_grandmother, "Maternal Grandmother");
    deets += getCSVLine(maternal_grandfather, "Maternal Grandfather");
    deets += getCSVLine(paternal_grandmother, "Paternal Grandmother");
    deets += getCSVLine(paternal_grandfather, "Paternal Grandfather");
    /* for(let i = 0; i < allPartnersWithCancer.length; i++) {
        deets += getCSVLine(allPartnersWithCancer[i], "Partner");
    } */
    for(let i = 0; i < allWithCancer.length; i++) {
        const ps = allWithCancer[i].persons;
        if(!ps) continue;
        const n = allWithCancer[i].name;
        for(let j=0; j < ps?.length; j++) {
            deets += getCSVLine(ps[j], n);
        }
    }
    return deets;
}

function quoteStr(s: string|undefined) : string {
    return (s ? (s.indexOf(",") > -1 ? '"'+s+'"' : s) : "");
}

function getCSVLine(p:Person, relative:string) {
    const mycancers = Cancer.getMyCancers(p);
    if(mycancers.length === 0) return "";

    const dob = (p.extraDetails?.dob ? new Date(p.extraDetails?.dob).toLocaleDateString('en-GB') : "");
    const dod = (p.extraDetails?.dod ? new Date(p.extraDetails?.dod).toLocaleDateString('en-GB') : "");
    const details = [   
                        relative,
                        quoteStr(p.extraDetails?.fullname),
                        quoteStr(p.extraDetails?.othername),
                        p.age,
                        dob,
                        p.status === 0 ? "Y" : "N" ,
                        dod
                    ];
    for (let i = 0; i < mycancers.length; i++) {
        let c = mycancers[i].cancer;    // cancer type
        const dg = p.cancers[c];        // cancer diagnosis
        if(c === "Other") c = p.cancers.Other.otherCancerName ? p.cancers.Other.otherCancerName : "Unknown";
        details.push(c, dg.age ? dg.age.toString() : "NA", quoteStr(dg.homeAddress), quoteStr(dg.treatmentCenter));
    }
    return details.join(",")+"\n";
}
