/**
/* © 2023 University of Cambridge. All rights reserved.  
**/

import React, { useState } from "react";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import { Alert, Box, CardHeader, Collapse, Divider, FormControl, FormControlLabel,  FormLabel, 
  Grid, Radio, RadioGroup, TextField } from '@mui/material';
import AgeDateCombo from './AgeDateCombo';
//import { Moment } from 'moment';
//import moment from 'moment';

import { getFT, getRF, Person, setRF } from './Person';
import GeneticTestsPanel, { GeneticTest } from "./GeneticTests";
import HeightBMI from "./HeightBMI";
import CancersPanel from "./CancerPanel";
import { Ethnicity } from "./Ethnicity";
import { answerType } from "../utils/Utilities";
import MyTooltip from "./MyTooltip";

const keys = ['Y', 'N', 'U'];

export interface PersonCardProps {
  person: Person;
  desc: string;
  name?: string;
  isProband: boolean;
  halfSiblingOption?: boolean;
  notValid?: String;
}

export default function PersonCard(props: PersonCardProps) {
  const theme = useTheme()
  const cfemale = theme.palette.female;
  const cmale = theme.palette.male;
  const c = props.person.sex === 'F' ? cfemale.main : cmale.main;


  let title;
  const tlen = props.desc.length;
  if( tlen > 0) {
    if( props.desc.endsWith("s") && !props.desc.endsWith("'s") ) {
      title = props.desc.substring(0, tlen-1) + "'" + props.desc.substring(tlen-1) + " Details";
    }
    else if(props.desc.indexOf("Details") > -1) {
      title = props.desc;
    } else {
      title = props.desc + " Details";
    }
  } else {
    title = "";
  }

  //console.log("RENDER PERSONCARD", props.desc);
  return (
    <Card sx={{ backgroundColor: "#fafafa" }}>
      <CardHeader
      title={<Typography variant="h2" color="white">{title}</Typography>}
      sx={{backgroundColor: c, py:1}}
      action={
        <MyTooltip 
          title="Enter details below, adding any cancer diagnoses and/or genetic testing."
          areaLabel="Person information button"
          color="white"
        />
      }
      />

      <CardContent sx={{px:{md:1.5, xs: 0.5}, py:1.5}}>
        {props.notValid && props.notValid !== "" &&  <Alert severity="error" sx={{ mb:2 }}>{props.notValid}</Alert>}

        <Grid container spacing={0.25} align-content="flex-start">
          <PersonDetails person={props.person}
                         desc={props.desc}
                         name={props.name}
                         isProband={props.isProband}
                         halfSiblingOption={props.halfSiblingOption} />
{/* 
          {(props.desc === "Daughters" ||  props.desc === "Sons") &&
            <>
              <Grid item xs={12} sx={{backgroundColor: cpartner, borderRadius:1}}>
                <Typography variant="h2" sx={{p:1}} color="white">
                  {partners[0].sex === "F" ? "Details of the mother of this child" : "Details of the father of this child"}
                </Typography>
              </Grid>
              <Grid item xs={12} sx={{mt:1, ml:1}}>
              {(partners[0].age === 0 && partners[0].yob === 0) || addFather ?
                  (
                    <PersonDetails person={partners[partners.length-1]} desc={"father of this child"} />
                  )
                  :
                  (
                    <>
                      <Typography variant="subtitle1" sx={{pb:1}}>
                        Select this childs father:
                      </Typography>

                      <TextField
                        id="select-father"
                        label="Father"
                        size="small"
                        defaultValue={pidx}
                        sx={{pb:1}}
                        style={{ width: 200 }}
                        select
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                          props.person.fathid = partners[parseInt(event.target.value)].uid;
                        }}>
                        {fathers}
                      </TextField>

                      <Typography>Or 
                      <Button
                          title="Click to add a new father"
                          variant="text" size="small"
                          onClick={(event) => {
                            const father = new Person(partners[0].sex);
                            partners.push(father);
                            props.person.fathid = father.uid;
                            setAddFather(true);
                          }}
                        >Click here to add a new father</Button> </Typography>
                    </>
                  )
              }
              </Grid>
            </>
          } */}
        </Grid>
      </CardContent>
    </Card>
  );
}

PersonCard.defaultProps = {
  isProband: false
};

function getDefaultDOB(isProband:boolean, yob: number) {
  if(isProband && getRF("dob"))
    return getRF("dob");
  return (yob !== 0 ? new Date('1 January '+yob) : null)
}

function PersonDetails(props:PersonCardProps) {
  const [sex, setSex] = useState(props.person.sex);
  const [surname, setSurname] = useState<string|undefined>(props.person.name);
  const [ageLabel, setAgeLabel] = useState<"Age"|"Diagnosis age"|"Age at death">(props.person.status === 0? "Age" : "Age at death")
  const [age, setAge] = useState<number>(props.person.age);
  const [dob, setDOB] = useState<Date|null>(getDefaultDOB(props.isProband, props.person.yob));
  const [dod, setDOD] = useState<Date|null>(props.person.yod !== 0 ? new Date('1 January '+props.person.yod): null);
  const [ashk, setAshk] = useState<0|1>(props.person.ashkenazi);
  const [status, setStatus] = useState<0|1|-1>(props.person.status);
  const [maxAgeAlert, setMaxAgeAlert] = useState(false);

  const ashkChange = (event: React.ChangeEvent<HTMLInputElement>, value: string) => {
    value === "Y" ? props.person.ashkenazi = 1 : props.person.ashkenazi = 0;
    setAshk(value === "Y" ? 1 : 0);
  }
  
  /**
   * Sex updates
   * @param event
   * @param value - sex M|F 
   */
  const sexUpdated = (event: React.ChangeEvent<HTMLInputElement>, value: string) => {
    if(value === "M")
      props.person.sex = "M";
    else
      props.person.sex = "F";
    setSex(props.person.sex)
    if(props.isProband) {
      const {partners} = getFT();
      for(let i = 0; i < partners.length; i++) {
        partners[i].sex = (value === "M" ? "F" : "M");
      }
    }
  }
  
  const dobChange = (date: Date) => {
    if(date === null) {
      props.person.yob = 0;
      if(props.isProband) {
        setDOB(date);
        setRF("dob", undefined);
      }
      return;
    }
    if(props.person.status === 0) {   // if alive update age
      const newage = new Date().getFullYear() - date.getFullYear()//moment().diff(date, 'years',false);
      if(newage > 120) {
        console.error("AGE ERROR in dateChange(): " + newage);
        setMaxAgeAlert(true);
        return;
      }

      const diff = (newage-props.person.age);
      if((diff !== 0 && diff !== 1) || !props.person.yob) {
        //console.log(diff, newage);
        setAge(newage);
        props.person.age = newage;
      }
    }
    setDOB(date);
    props.person.yob = date.getFullYear();
    if(props.isProband) {
      setRF("dob", date);
    }
    setMaxAgeAlert(false);
  };

  const ageChange = (newage: string|null) => {
    if (newage) {
      const iage = parseInt(newage);
      if(iage > 120) {
        console.error("AGE ERROR IN ageChange(): " + iage)
      }
      const diff = new Date().getFullYear()-(props.person.yob+iage);
      if(props.person.status === 0 && diff !== 0 && diff !== 1) {   // if alive update age and date
        dobChange(new Date("01/01/"+(new Date().getFullYear()-iage)));
      } else {
        if(props.person.status === 1) {                        // if deceased update date of death
          const newdod = new Date('1 January '+(props.person.yob+iage));
          const diff = (dod !== null && dod !== undefined ? dod.getFullYear()-newdod.getFullYear() : 2);
          if(diff !== 0 && diff !== 1) {
            setDOD(newdod);
            props.person.yod = newdod.getFullYear();
          }
        }
        setAge(iage);
        props.person.age = iage;
      }
    } else {
      setAge(0);
      props.person.age = 0;
    }
  };

  /**
   * Status updates, 0 = alive, 1 = dead
   * @param event 
   * @param value - status
   */
  const statusUpdated = (event: React.ChangeEvent<HTMLInputElement>, value: string) => {
    if(value === "Y")
      props.person.status = 0;
    else if(value === "N")
      props.person.status = 1;
    else
      props.person.status = -1;
    setStatus(props.person.status);
    setAgeLabel(props.person.status === 1? "Age at death" : "Age")
  }

  const theme = useTheme();
  const name = (props.name ? props.name : props.desc.replace(/('s|s)\s*$/, "").replace(/^Your /, "").toLowerCase());

  let cancerQ = props.isProband ? "Have you" : "Has your "+name;
  cancerQ += " had a cancer diagnosis?";

  let geneticQ = props.isProband ? "Have you" : "Has your "+name;
  geneticQ += " had testing for a pathogenic variant (mutation) in any of the following genes - "+
              "BRCA1, BRCA2, PALB2, ATM, CHEK2, BARD1, RAD51C, RAD51D, BRIP1?";

  //console.log("HALF SIBS", props.halfSiblingOption);
  return (
    <>
      <Grid container sx={{ml:1}}>
        {props.isProband &&
          <Grid item xs={6} md={4} lg={3}>
            <FormControl required size="small">
            <TextField 
                defaultValue={surname}
                error={surname === undefined || surname === ""}
                helperText={surname === undefined || surname === ""? 'Required' : ' '}
                label="Surname" variant="outlined" size="small" type="string"
                onBlur={() => {
                  // trim start/end blank spaces
                  const e = document.getElementById("surname") as HTMLInputElement;
                  const n = e?.value.trim();
                  if(n) {e.value = n; props.person.name = n; setSurname(n)}
                }}
                onChange={function(event: React.ChangeEvent<HTMLInputElement>) {
                  const n = event.target.value.trim(); props.person.name = n; setSurname(n)
                }} />
            </FormControl>
          </Grid>
        }

        {!props.isProband &&
          (<Grid item xs={12} sx={{ml:1, pb:1.5, mt:-1}}>
            <FormLabel id="radio-btn-group-status">
              Is this person alive?

              <MyTooltip title={"Please give a rough estimate of \"Year of Birth\" "+
                            (status === 0 ? "or \"" : "and \"")+ageLabel+"\" if unknown."}
                      outline={false}
                      areaLabel={"Age and date"} />
            </FormLabel>
            <RadioGroup
                row
                aria-labelledby="radio-btn-group-status"
                value={status === 0 ? "Y" : (status === 1 ? "N" : "U")}
                name="radio-btn-grp-status"
                onChange={statusUpdated}
              >
                <FormControlLabel value="Y" control={<Radio size="small"/>} label="Yes" />
                <FormControlLabel value="N" control={<Radio size="small"/>} label="No" />
                <FormControlLabel value="U" control={<Radio size="small" />} label="Unknown" />
            </RadioGroup>
          </Grid>)
        }

        <Grid item xs={12} md={props.isProband ? 4 : 12} sx={{pl:0.25}}>
          {maxAgeAlert && <Alert severity="error">Enter a valid {props.isProband ? "date of birth" : "year of birth and age"}</Alert>}
            <AgeDateCombo
              age={age}
              mydate={dob}
              dateChange={dobChange} ageChange={ageChange}
              isAlive={(status !== 1)}
              views={props.isProband ? ["year", "day"] : ["year"]}
              ageLabel={ageLabel}
              includeAge={!props.isProband && status !== 1} />

          {!props.isProband && status === 1 &&
            <Box sx={{mt:1.5}}>
              <AgeDateCombo     // deceased - provide age and year of death option
                age={age}
                mydate={dod}
                dateChange={(newdod: Date) => {
                  if(!newdod || props.person.yob === 0)
                    return;
                  setDOD(newdod);
                  props.person.yod = newdod.getFullYear();
                  // new yod updated
                  const age = props.person.yod-props.person.yob;
                  const diff = age-props.person.age;
                  if(age>=0 && diff !== 0 && diff !== 1)
                    ageChange(age+"");
                }}
                ageChange={ageChange}
                isAlive={false}
                views={["year"]}
                ageLabel={"Age at death"}
                dateLabel={"Year of death"}
                includeAge={true} />
            </Box>
          }
        </Grid>
      </Grid>

      {props.isProband &&
        <Grid container sx={{pt: 0, ml: 1}}>
          <Grid item xs={12} sm={6} md={4} lg={3} >
            <FormLabel id="radio-btn-grp-sex">What is your sex?
              <MyTooltip title={"Sex assigned at birth."}
                      outline={false}
                      areaLabel={"Age and date"} />
            </FormLabel>

            <FormControl>
              <RadioGroup
                row
                key={"radio-"+sex}
                aria-labelledby="radio-btn-grp-sex"
                defaultValue={sex}
                name="radio-buttons-group"
                onChange={sexUpdated}
                sx={{p:0}}
              >
                <FormControlLabel value="F" control={<Radio size="small"/>} label="Female" sx={{mr:1}} />
                <FormControlLabel value="M" control={<Radio size="small"/>} label="Male" sx={{mr:0}} />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}> <Ethnicity /> </Grid>
        </Grid>
      }

      {props.isProband && props.person.sex === "F" &&
        <>
          <Grid item xs={12} sx={{mt:1}}><Divider sx={{backgroundColor:theme.palette.primary.light}}/></Grid>
          <Grid item xs={12} md={8} lg={6}>
              <HeightBMI />
          </Grid>
          <Grid item xs={12}><Divider sx={{backgroundColor:theme.palette.primary.light}}/></Grid>
        </>
      }

      <Grid item xs={12}>
        <CancersPanel question={cancerQ} person={props.person} />
      </Grid>

      <Grid item xs={12}>
        <GeneticTestsPanel
            question={geneticQ}
            person={props.person}
            change={function (gts: GeneticTest[]): void { props.person.geneTests = gts }} />
      </Grid>

      {props.isProband &&
        <Grid item xs={12} sx={{ml:1}}>
          <FormLabel id="radio-btn-group-ashk">
            Do you have Ashkenazi Jewish ancestry?

            <MyTooltip
                outline={false}
                title="Ashkenazi Jewish women have a higher-than-average risk of developing breast cancer. Knowing if you have this heritage will help us make a more accurate estimate of your breast cancer risk."
                areaLabel="Ashkenazi Jewish ancestry information button"
              />
          </FormLabel>
          <RadioGroup
              row
              aria-labelledby="radio-btn-group-ashk"
              defaultValue={ashk===1 ? "Y" : "N"}
              name="radio-btn-grp-ashk"
              onChange={ashkChange}
            >
              <FormControlLabel value="Y" control={<Radio size="small"/>} label="Yes" />
              <FormControlLabel value="N" control={<Radio size="small"/>} label="No" />
              <FormControlLabel value="U" control={<Radio size="small" />} label="Unknown" />
          </RadioGroup>
        </Grid>
      }

      {props.halfSiblingOption && 
        <Grid item xs={12} sx={{mt:1, p:1, backgroundColor: '#eceff1', borderRadius:2}}>
          <HalfSibling person={props.person} />
        </Grid>
      }
    </>
  )
}

PersonDetails.defaultProps = {
  isProband: false,
  hgt: -1,
  bmi: -1,
  changeHgtBMI: (h: number, w: number) => {}
};

interface HalfSiblingProps {
  person: Person
}

function HalfSibling(props:HalfSiblingProps) {
  const halfSibling = props.person.halfSibling;
  const [open, setOpen] = useState(halfSibling.isHalfSibling === "Y");

  //console.log(halfSibling, halfSibling.sharesMother, halfSibling.sharesMother);
  return (
    <Grid container spacing="0">
      <Grid item xs={12} sx={{mt:1, ml:1}}>
      <FormLabel id="rbtn-half">Are they a half-sibling?</FormLabel>
      <RadioGroup
          row
          aria-labelledby="rbtn-half"
          defaultValue={halfSibling.isHalfSibling}
          name="radio-btn-half-sibling"
          onChange={(event: React.ChangeEvent<HTMLInputElement>, v: string) => {
            console.log(v, keys, (keys.indexOf(v) > -1), halfSibling.isHalfSibling);
            if (keys.indexOf(v) > -1) {
              halfSibling.isHalfSibling = v as answerType;
              setOpen(halfSibling.isHalfSibling === "Y");
              
            }
          }}
          key={halfSibling.isHalfSibling}
        >
          <FormControlLabel value="Y" control={<Radio size="small"/>} label="Yes" />
          <FormControlLabel value="N" control={<Radio size="small"/>} label="No" />
          <FormControlLabel value="U" control={<Radio size="small" />} label="Unknown" />
      </RadioGroup>

      <Collapse in={open} timeout="auto" unmountOnExit>
        <FormLabel id="rbtn-half-share">Which parent do they share?</FormLabel>
        <RadioGroup
            row
            aria-labelledby="rbtn-half-share"
            defaultValue={halfSibling.sharesMother ? "ma" : "pa"}
            name="radio-btn-half-sibling-share"
            onChange={(event: React.ChangeEvent<HTMLInputElement>, v: string) => {
              halfSibling.sharesMother = (v === "ma");
            }}
            key={halfSibling.sharesMother ? "ma" : "pa"}
          >
            <FormControlLabel value="ma" control={<Radio size="small"/>} label="Mother" />
            <FormControlLabel value="pa" control={<Radio size="small"/>} label="Father" />
        </RadioGroup>
      </Collapse>
      </Grid>
    </Grid>
  )
} 
