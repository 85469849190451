/**
/* © 2023 University of Cambridge. All rights reserved.  
**/
import { Box, Button, Modal, TextField, Typography } from "@mui/material";
import React from "react";
import { getTokenParts, isValidToken } from "../utils/Utilities";

type TOKEN_TYPE = `^[0-9]{11}[A-Z]{4}$`;
export let TOKEN: TOKEN_TYPE;

export function TokenErrorModal() {
    const tok = getTokenParts();
    const [open, setOpen] = React.useState(!tok ? true : false);
    const handleClose = (event: object, reason?: string) => {
        const e = document.getElementById("mycanriskToken") as HTMLInputElement;
        const t = e?.value.trim(); // trim start/end blank spaces
        const isToken = (t && isValidToken(t));
        if(isToken){
            TOKEN = t as TOKEN_TYPE;
            setOpen(false);
        }
    }
  
    const style = {
      position: 'absolute' as 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '80%',
      bgcolor: 'background.paper',
      border: '2px solid #000',
      boxShadow: 24,
      p: 4,
    };
    
    return (
      <>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="token-modal-title"
          aria-describedby="token-modal-description"
        >
          <Box sx={style}>
            <Typography id="token-modal-title" variant="h2">
              It looks like the MyCanRisk token is missing or incorrect. Please enter a valid token to continue:
            </Typography>
  
            <TextField
                id="mycanriskToken"
                defaultValue={""}
                label="Enter token" type="string"
                size="small"
                sx={{my:2}}
            />
  
            <div><Button variant="contained" onClick={handleClose}>Continue</Button></div>
          </Box>
        </Modal>
      </>
    );
  }
