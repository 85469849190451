/**
/* © 2023 Cambridge University. All rights reserved.  
**/

import { TextareaAutosize, Button, Grid, Typography, Box, TextField, InputLabel } from "@mui/material";
import useWindowDimensions from "../utils/WindowDimensions";
import EastIcon from "@mui/icons-material/East";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { s_decrypt } from "../utils/Scrypt";
import { grey, red } from "@mui/material/colors";
import { clearContents, getToken } from "../utils/Utilities";
import { ChangeEvent, useState } from "react";

const Decrypt = () => {
  const [encTxt, setEncTxt] = useState<string>();
  const [fileName, setFileName] = useState<string>();
  const { height, } = useWindowDimensions();

  const addWarning = (wrn:string) => {
    const theDiv = clearContents("warnings");
    const content = document.createTextNode(wrn);
    theDiv?.appendChild(content);
    throw Error(wrn);
  }

  const onFileUpload = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      return;
    }
    const file = e.target.files[0];
    const { name } = file;
    setFileName(name);
    const reader = new FileReader();
    reader.onload = (evt) => {
      if (!evt?.target?.result) return;
      setEncTxt(evt.target.result as string);
    };
    reader.readAsBinaryString(file);
  };

  /**
   * Get value of input element
   * @param id - ID of the element
   * @returns value
   */
  const getValue = (id:string) => {
    return (document.getElementById(id) as HTMLInputElement).value;
  }

  /**
   * Save decrypted data
   */
  const onSave = () => {
    const content = getValue('denc');
    const sid = getValue('studyid')+"_"+getValue('surname')+"_"+getValue('yob')+".txt";
    const fname = (fileName ? fileName.replace('_enc.', '_DECRYPTED.') : sid)
    console.log(fname, sid);

    const file = new Blob([content], {type: "text/plain"});
    const a = document.createElement("a");
    const url = URL.createObjectURL(file);
    a.href = url;
    a.download = fname;
    document.body.appendChild(a);
    a.click();
    setTimeout(function() {
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    }, 0);
  }

  /**
   * Decrypt the encrypted string to plain text.
   */
  const decrypt = () => {
    const el = (document.getElementById('denc') as HTMLInputElement);
    let studyid = getValue('studyid');
    const surname = getValue('surname');
    const yob = getValue('yob');
    const enct = getValue('enc');
    el.value = "";

    if(surname.length === 0 || yob.length === 0 || studyid.length === 0 || enct.length === 0) {
      addWarning("Missing study ID, surname, year of birth and/or text to unencrypt");
    }

    const studyregex: RegExp = /^\d{6}$/; // e.g. match 010001
    if(studyregex.test(studyid)) {
      studyid = "0"+studyid.substring(0,2)+"-"+studyid.substring(2)+"-X"; // covert to 001-0001-X
      console.log(studyid);
    }

    const pswd = getToken(studyid, surname, yob);
    const decdata = s_decrypt(enct, pswd);
    if(decdata) decdata.then((dec) => {
      clearContents("warnings");
      if(el && dec != null) el.value = fill_canrisk_data(dec);
      if(dec === null) addWarning("Failed to unencrypt - check study ID, year of birth, surname and encrypted text");
    });
  }


  /**
  * Fill in missing blank genetic test/pathology data
  */
  function fill_canrisk_data(cdata:string) {
    if(cdata.indexOf("##CanRisk") === -1) return cdata;
    let cdataT = "";
    const regexp = /([0-9])/;
    let version = 2;
    const NCOLUMNS = [27];	// number of columns CanRisk v2
    const lines = cdata.trim().split('\n');

    for(let i = 0;i < lines.length;i++){
      let ln = lines[i].trim();
      if(ln.indexOf("##CanRisk") === 0) {
        const match = ln.match(regexp);
        version = parseInt(match ? match[1] : "0");
        if (version !== 2) throw Error('Unsupported CanRisk version: '+version);
      } else if(ln.indexOf("##") !== 0) {
        const delim = (ln.indexOf('\t') < 0 ? /\s+/ : /\t/);
        const attr = ln.split(delim).map(s => s.trim());
        if(attr.length > 1) {
          if(attr.length !== NCOLUMNS[version-2]) {
            if(attr.length === NCOLUMNS[version-2]-1) {
              ln += "\t0:0:0:0:0"; // pathology
            } else if(attr.length === NCOLUMNS[version-2]-9-1) {
              ln += "\t0:0\t0:0\t0:0\t0:0\t0:0\t0:0\t0:0\t0:0\t0:0"; // gene tests
              ln += "\t0:0:0:0:0"; // pathology
            }
          }
        }
      }
      cdataT += ln+"\n";
    }
    return cdataT;
  }

  return (
    <>
      <Grid container spacing={1} sx={{mt:10}} justifyContent='left' >
        <Grid item sm={2} xs={12}>
          <Box display="flex" justifyContent="flex-end" sx={{mr:1, mt:2}}>
            <InputLabel htmlFor="studyid">Study ID</InputLabel>
          </Box>
        </Grid>
        <Grid item sm={3} xs={12}><TextField fullWidth placeholder="Study ID (001-0001-X or 010001)" id="studyid" size="small" color="secondary" sx={{mt:1, ml:1}} /></Grid>
        <Grid item sm={7} xs={12}></Grid>
          
        <Grid item sm={2} xs={12}>
          <Box display="flex" justifyContent="flex-end" sx={{mr:1, mt:2}}>
            <InputLabel htmlFor="yob">Year of Birth</InputLabel>
          </Box>
        </Grid>
        <Grid item sm={3} xs={12}><TextField fullWidth placeholder="Year of birth" id="yob" size="small" color="secondary" sx={{mt:1, ml:1}}  /></Grid>
        <Grid item sm={7} xs={12}></Grid>

        <Grid item sm={2} xs={12}>
          <Box display="flex" justifyContent="flex-end" sx={{mr:1, mt:2}}>
            <InputLabel htmlFor="surname">Surname</InputLabel>
          </Box>
        </Grid>
        <Grid item sm={3} xs={12}><TextField fullWidth placeholder="Surname" id="surname" size="small" color="secondary" sx={{mt:1, ml:1}} /></Grid>
        <Grid item sm={7} xs={12}></Grid>
      </Grid>
    

      <Grid container spacing={1} sx={{mt:1}} justifyContent='center' >
        <Grid item xs={7}>

          <Button
            variant="contained"
            title="Load encrypted file"
            component="label"
            size="small">Load encrypted file
              <input
                type="file"
                hidden
                onChange={onFileUpload}
              /> 
          </Button> 
          <Typography variant="caption" component="span" sx={{ ml:1, pt:1, color: grey[700] }}>{fileName}</Typography>
        </Grid>

        <Grid item xs={5}>
          <Box display="flex" justifyContent="flex-end">
            <Button 
                title="Copy"
                sx={{ minWidth: 16, mr: 1 }}
                size="large"
                onClick={() => {
                  const txt = getValue('denc');
                  if ('clipboard' in navigator) {
                    navigator.clipboard.writeText(txt);
                  } else {
                    document.execCommand('copy', true, txt);
                  }
                }}
              >
                <ContentCopyIcon color="error"/>
              </Button>

              <Button onClick={onSave} variant="contained" color="primary">
                Save
              </Button>
            </Box>
        </Grid>

        <Grid item xs={5}>
          <TextareaAutosize id="enc" defaultValue={encTxt} placeholder="Encrypted text" style={{width:'100%', height:height/3}} />
        </Grid>
        
        <Grid item xs={2}>
          <Box display="flex" alignItems="center" justifyContent="center">
            <Button 
              variant="contained"
              title="Unencrypt"
              sx={{ minWidth: 16, mr: 1 }}
              size="medium"
              color="primary"
              onClick={decrypt}
            >
              <EastIcon fontSize="large"/>
            </Button>
          </Box>
        </Grid>

        <Grid item xs={5}>
          <TextareaAutosize id="denc" placeholder="Unencrypted text" style={{width:'100%', height:height/3}} />
        </Grid>

        <Grid item xs={12}>
          <Typography sx={{ color: red[900], textTransform: "uppercase", fontWeight: 'bold' }} id="warnings"></Typography>
        </Grid>
      </Grid>
    </>
  );
};
export default Decrypt;
