/**
/* © 2023 Cambridge University. All rights reserved.  
**/

import  { Box, Typography, Table, TableBody, TableCell, TableRow, } from "@mui/material";
import * as Bowser from "bowser";

const Test = () => {
  const msg:{[key:string]: string|undefined} = {};
  try {
    const navigator = window.navigator;
    const browser = Bowser.getParser(navigator.userAgent);
    msg.name = browser.getBrowserName();
    msg.version = browser.getBrowserVersion();
    msg.os = browser.getOSName()+' '+(browser.getOSVersion() ? browser.getOSVersion() : '');
    msg.online = navigator.onLine.toString();
    msg.platform = browser.getPlatform().type;
  }
  catch(err) {
    msg.name = navigator.appName;
    msg.version = navigator.appVersion;
    msg.os =navigator.platform;
    msg.online = navigator.onLine.toString();
    msg.platform = navigator.platform;
  }

  return (
    <Box sx={{pt:10, pb:2}} textAlign="center">
      <Typography variant="h1">Your Browser</Typography>
      <div style={{display: 'flex',  justifyContent:'center', alignItems:'center'}}>
        <Table sx={{width:"auto", mb:2}}>
          <TableBody>

              <TableRow>
                <TableCell align="right">Browser and OS</TableCell>
                <TableCell align="left">{navigator.userAgent}</TableCell>
              </TableRow>

              <TableRow>
                <TableCell align="right">Name</TableCell>
                <TableCell align="left">{msg.name}</TableCell>
              </TableRow>

              <TableRow>
                <TableCell align="right">Version</TableCell>
                <TableCell align="left">{msg.version}</TableCell>
              </TableRow>

              <TableRow>
                <TableCell align="right">Platform</TableCell>
                <TableCell align="left">{msg.platform}</TableCell>
              </TableRow>

              <TableRow>
                <TableCell align="right">Operating System</TableCell>
                <TableCell align="left">{msg.os}</TableCell>
              </TableRow>

              <TableRow>
                <TableCell align="right">Online</TableCell>
                <TableCell align="left">{msg.online}</TableCell>
              </TableRow>

          </TableBody>
        </Table>
      </div>
    </Box>
  );
};
export default Test;
