/**
/* © 2023 University of Cambridge. All rights reserved.  
**/

import { Card, CardContent, Grid, Typography, useTheme } from "@mui/material";
import React from "react";

export default function Landing(props: {page:1|2}) {
  const theme = useTheme();
  const bg = theme.palette.primary.xtralight;

  return (
      <Grid container spacing={2} sx={{pt:2}}>
        {props.page === 1 &&
          <>
            <Grid item sm={12} md={6}>
              <Card sx={{ backgroundColor: bg }}>
                <CardContent>
                  <Typography variant="h2" gutterBottom={true}>What is MyCanRisk?</Typography>
                  <Typography variant="body1" gutterBottom={true}>
                  MyCanRisk is an online tool that you can complete using your own device (e.g. computer, tablet, smart phone). 
                  </Typography>
                  <Typography variant="body1"gutterBottom={true}>
                  This tool collects information about you and your <strong>biological family</strong> members (those that are related to you by blood) and shares it with
                  your healthcare team. <strong>Please note that this tool requires information based on people's sex assigned at birth.</strong>
                  </Typography>
                  <Typography variant="body1">
                  Completing this will help your healthcare team to predict your future risk of developing breast cancer.           
                  </Typography>
                  </CardContent>
              </Card>
            </Grid>
            <Grid item sm={12} md={6}>
              <Card sx={{ backgroundColor: bg, height: "100%" }}>
                <CardContent>
                  <Typography variant="h2" gutterBottom={true}>Who is MyCanRisk for?</Typography>
                  <Typography variant="body1" gutterBottom={true}>
                  MyCanRisk is for women who are having their risk of breast cancer assessed by a healthcare professional. 
                  </Typography>
                  <Typography variant="body1">
                  At the moment, MyCanRisk can only be used by women who have consented to take part in one of our CanRisk studies.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </>
        }

        {props.page === 2 &&
          <>
            <Grid item sm={12} md={6}>
              <Card sx={{ backgroundColor: bg, height: "100%" }}>
                <CardContent>
                  <Typography variant="h2" gutterBottom={true}>How do I complete MyCanRisk?</Typography>
                  <Typography variant="body1" gutterBottom={true}>
                  MyCanRisk is split into two sections - <i>information about you</i> and <i>information about your biological family
                  members</i> (those that are related to you by blood). The sections can be completed in any order.
                  </Typography>
                  <Typography variant="body1" gutterBottom={true}>
                  All information collected is relevant to calculate the most accurate future risk of cancer, so it is important that you fill in as 
                  much information as you can. <strong>If you don't have the exact information, you should give an estimate.</strong>
                  </Typography>
                  <Typography variant="body1">
                  We anticipate it will take you around 30 minutes to complete the tool. You do not have to do it all in one go,
                  as the information you give is automatically saved in the internet browser on that device so as long as you do
                  not clear your browser you should be able to return to it at any point without losing your progress.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
{/*             <Grid item sm={12} md={6}>
              <Card sx={{ backgroundColor: bg, height: "100%" }}>
                <CardContent>
                  <Typography variant="h2" gutterBottom={true}>How will my data be used?</Typography>
                  <Typography variant="body1" component="div">
                  The information that you give through this tool will be used:

                  <ol>
                    <li>To inform your care.</li>
                    <li>In the research study you have consented to take part in.</li>
                  </ol>

                  Your information will not be used for any other purposes. If you have any questions
                  about how your data will be used, <strong>please contact the [person x] (email)</strong>.
                  </Typography>
                  </CardContent>
              </Card>
            </Grid> */}
          </>
        }
      </Grid>
      )
}
