/**
/* © 2023 University of Cambridge. All rights reserved.  
**/

import { Button, Card, CardContent, FormControlLabel, FormLabel, Grid,
    Radio, RadioGroup, Typography, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import { getRF, setRF } from "./Person";

// https://gpdirect.co.uk/alcohol-units-calculator/
/* const drinks: Drink[] = [
    new Drink("Bottle of lager/beer/cider", 330, 5, 1.6),
    new Drink("Can of lager/beer/cider", 500, 5.5, 2.8),
    new Drink("Pint of lower-strength lager/beer/cider", 568, 3.6, 2.04),
    new Drink("Pint of higher-strength lager/beer/cider", 568, 5.2, 3),
    new Drink("Small glass of red/white/rosé wine", 125, 12, 1.5),
    new Drink("Standard glass of red/white/rosé wine", 175, 12, 2.1),
    new Drink("Large glass of red/white/rosé wine", 250, 12, 3.0),
    new Drink("Small measure of fortified wine", 50, 17.5, 0.9),
    new Drink("Large measure of fortified wine", 75, 17.5, 1.3),
    new Drink("Single shot of spirits", 25, 40, 1.0),
    new Drink("Standard shot of spirits", 35, 40, 1.4),
    new Drink("Double shot of spirits", 50, 40, 2.0),
    new Drink("Alcopop", 275, 4.5, 1.2)
]; */


interface CircleProps{
    vol: number
}

function CircleIcon(props:CircleProps) {
    const theme = useTheme();
    const smScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const d = smScreen ? 40 : 60;
    return (
        <svg viewBox="40 40 80 80" height={d} width={d}>
            <circle cx="80" cy="80" r="38" fill="#eb0032" strokeWidth="3.6"></circle>
            <text x="56" y="82" fill="white" fontFamily="Roboto, Helvetica" fontSize="1.1em" fontWeight="bold">
                {props.vol} ml
            </text>
        </svg>
    )    
  }

function UnitOfAlcohol() {
    const [showUnits, setShowUnits] = React.useState(false)
    return(
        <>
            <Button
                onClick={() => {
                    setShowUnits(!showUnits)
                }}
                size="small">
                What does 1 unit of alcohol look like?
            </Button>
            {showUnits &&
                <Grid container spacing="1" sx={{mt:1, ml:1, borderRadius: 1, backgroundColor : "white", pt:1}}>
                    <Grid item sm={1} xs={4} sx={{pr:2, pb:1, display: "flex", justifyContent: "flex-end"}}>
                        <img src="img/Cider.svg" alt="" style={{width:"40px"}}/>
                    </Grid>
                    <Grid item sm={1} xs={8}>
                        <CircleIcon vol={218}/>
                        <Typography variant="caption" component="p">Cider, Standard 4.5% ABV</Typography>
                    </Grid>
                    <Grid item sm={1} xs={4} sx={{pr:2, pb:1, display: "flex", justifyContent: "flex-end"}}>
                        <img src="img/Wine.svg" alt="" style={{width:"40px"}}/>
                    </Grid>
                    <Grid item sm={1} xs={8}>
                        <CircleIcon vol={76}/>
                        <Typography variant="caption" component="p">Wine,&nbsp;&nbsp;Standard 13% ABV</Typography>
                    </Grid>
                    <Grid item sm={1} xs={4} sx={{pr:2, pb:1, display: "flex", justifyContent: "flex-end"}}>
                        <img src="img/Spirit.svg" alt="" style={{width:"40px"}}/>
                        </Grid>
                    <Grid item sm={1} xs={8}>
                        <CircleIcon vol={25}/>
                        <Typography variant="caption" component="p">Whisky, Standard 40% ABV</Typography>
                    </Grid>
                    <Grid item sm={1} xs={4} sx={{pr:2, pb:1, display: "flex", justifyContent: "flex-end"}}>
                        <img src="img/Beer.svg" alt="" style={{width:"40px"}}/>
                        </Grid>
                    <Grid item sm={1} xs={8}>
                        <CircleIcon vol={250}/>
                        <Typography variant="caption" component="p">Beer,&nbsp;&nbsp;Standard 4% ABV</Typography>
                    </Grid>
                    <Grid item sm={1} xs={4} sx={{pr:2, pb:1, display: "flex", justifyContent: "flex-end"}}>
                        <img src="img/Alcopop.svg" alt="" style={{width:"40px"}}/>
                    </Grid>
                    <Grid item sm={1} xs={8} sx={{width:"220px"}}>
                    <CircleIcon vol={250}/>
                        <Typography variant="caption" component="p">Alcopop, Standard 4% ABV</Typography>
                    </Grid>
                </Grid>
            }
        </>
    )
}

export type Frequency = "N"|"M"|"4M"|"3W"|"4W";

export const alcoholFrequency: { [name: string]: string } = {
    "N" : "Never",
    "M" : "Less than monthly",
    "4M": "2-4 times a month",
    "3W": "2-3 times a week",
    "4W": "4 or more times a week"
}

export const alcoholAmount: { [name: string]: number } = {
    "1-2" : 1.5,
    "3-4" : 3.5,
    "5-6" : 5.5,
    "7-9" : 8,
    "10+": 10
}


export const UNITS_GRAMS_ALCOHOLUK = 8.0; // UK Grams per Unit

export function getAlcoholGramsPerDay() {
    const alco = getRF("alcohol");
    if(alco.frequency !== "N" && alco.amount) {
      let f = 0;
      switch(alco.frequency){
        case "M":     // Less than monthly
          f = 1/60.88;
          break;
        case "4M":    // 2-4 times a month; 365.25/12 days per month
          f = 3/30.44;
          break;
        case "3W":    // 2-3 times a week
          f = 2.5/7;
          break;
        case "4W":    // 4 or more times a week
          f = 5.5/7;
          break;
      }
      // How are the units and grams of alcohol calculated? https://canrisk.atlassian.net/l/c/DrDnE0oP
      return alco.amount * f * UNITS_GRAMS_ALCOHOLUK;
    }
    return (alco.frequency === "N" ? 0 : null);
}

/**
* Alcohol Panel
*/
export default function AlcoholPanel(props: {}) {
    let alcohol = getRF("alcohol");
    const [drinks, setDrinks] = React.useState<Frequency|undefined>(alcohol.frequency);

    // alcohol frequency
    const af = Object.keys(alcoholFrequency).map((k, idx) => {
        return (
            <FormControlLabel key={k} value={k} control={<Radio size="small"/>} label={alcoholFrequency[k]} />
        )
    });

    // alcohol amount
    const aa = Object.keys(alcoholAmount).map((k, idx) => {
        return (
            <FormControlLabel key={k} value={alcoholAmount[k]} control={<Radio size="small"/>} label={k} />
        )
    });

    const bg = useTheme().palette.primary.xtralight;
    return (
        <Card sx={{ backgroundColor: bg, borderRadius:2 }}>
            <CardContent>
                <Grid container>
                    <Grid item xs={12} sx={{mt:1, ml:1}}>
                    <FormLabel id="rbtn-grp-alcohol" sx={{fontWeight:700}}>How often do you have a drink containing alcohol?</FormLabel>
                    <RadioGroup
                        key={"frequency"+drinks}
                        aria-labelledby="rbtn-grp-alcohol"
                        defaultValue={drinks ? drinks : null}
                        name="alcohol"
                        onChange={(event: React.ChangeEvent<HTMLInputElement>, value: any) => {
                            setDrinks(value as Frequency);
                            alcohol.frequency = value;
                            if(value === "N") delete alcohol.amount;
                            setRF("alcohol", alcohol);
                          }}
                        >
                        {af}
                    </RadioGroup>
                    </Grid>

                    {drinks && drinks !== "N" && 
                    <>
                        <Grid item xs={12} sx={{mt:1, ml:1}}>
                        <FormLabel id="rbtn-grp-drinks" sx={{fontWeight:700}}>How many units of alcohol do you drink on a typical day when you are drinking?</FormLabel>
                        <RadioGroup
                            key={"amount"+alcohol?.amount}
                            aria-labelledby="rbtn-grp-drinks"
                            name="drinks"
                            defaultValue={alcohol.amount ? alcohol.amount : null}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>, value: any) => {
                                alcohol = {"frequency":drinks, "amount":value};
                                setRF("alcohol", alcohol);
                                //console.log("ALCOHOL", alcohol)
                              }}
                            >
                            {aa}
                        </RadioGroup>
                        </Grid>

                        <UnitOfAlcohol/>
                    </>
                    }
                </Grid>
            </CardContent>
        </Card>
    )
}