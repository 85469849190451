/**
/* © 2023 Cambridge University. All rights reserved.  
**/

import React from 'react';
import { useState } from "react";
import { Theme, ThemeOptions, ThemeProvider } from "@mui/system";
import { createTheme } from "@mui/material";


declare module '@mui/material/styles' {
  interface Palette {
    female: Palette['primary'];
  }
  interface Palette {
    male: Palette['primary'];
  }

  interface PaletteColor {
    xtralight?: string;
    xtradark?: string;
  }
  interface SimplePaletteColorOptions {
    xtralight?: string;
  }
}


// NHS colours https://www.england.nhs.uk/nhsidentity/identity-guidelines/colours/
const nhs_palette1:ThemeOptions["palette"] = {
    primary: { // blue
      dark: "#003087",
      main: "#005EB8",
      light: "#41B6E6",
      xtralight: "#41B6E6"+20   // Hexadecimal Colors With Transparency
    },
    secondary: { // grey
      dark:  "#313A49",
      main:  "#425563",
      light: "#E8EDEE",
      xtralight: "#FEFEFE"
    },
    female: { // purple
      dark:  "#6e1948",
      main:  "#7C2855",
      light: "#8b3662"      
    },
    male: { // green
      xtradark: "#006747",
      dark:  "#006747",
      main:  "#009639",
      light: "#78BE20"      
    }
}

const nhs_palette2:ThemeOptions["palette"] = {
  primary: { // blue
    dark: "#003087",
    main: "#005EB8",
    light: "#41B6E6",
    xtralight: "#41B6E6"+20   // Hexadecimal Colors With Transparency
  },
  secondary: { // grey
    dark:  "#425563",
    main:  "#768692",
    light: "#E8EDEE"
  },
  female: { // blue
    dark:  "#003087",
    main:  "#005EB8",
    light: "#005EB8"      
  },
  male: { // blue
    dark:  "#003087",
    main:  "#005EB8",
    light: "#005EB8"      
  }
}

const alt_palette:ThemeOptions["palette"] = {
  primary: { // pink
    dark: "#8c0032",
    main: "#c2185b",
    light: "#fa5788",
    xtralight: "#fa5788"+20   // Hexadecimal Colors With Transparency
  },
  secondary: { // bluegrey
    dark:  "#29434e",
    main:  "#546e7a",
    light: "#819ca9"
  },
  female: { 
    dark:  "#162e57",
    main:  "#455784",
    light: "#7383b4"      
  },
  male: { // gold
    dark:  "#93781a",
    main:  "#c6a74a",
    light: "#fbd879"  
  }
}

const nhsTheme1 = createTheme({
  typography: {
    fontFamily: [
      "Roboto", "Helvetica"
    ].join(','),
    h1: {
      fontSize: '1.5rem',
      fontWeight: 500,
      lineHeight: 1.6
    },
    h2: {
      fontSize: '1.25rem',
      fontWeight: 500,
      lineHeight: 1.5
    },
  },
  components: {
    MuiAlert: {
      styleOverrides: {
        standardError: {
          fontSize: '1.1em',
          fontWeight: 'bold'
        }
      }
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          color: "red",
        },
      }
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: "0.8em",
          fontWeight: "normal",
          color: "white",
          backgroundColor: "#1e88e5" // blue
        }
      }
    },
    MuiTypography: {
      styleOverrides: {
        gutterBottom: {
          marginBottom: 16,
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: '0.9rem'
        }
      }
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: "#212121"
        }
      }
    }
  },
  palette: {
    ...nhs_palette1,
  }
});

const nhsTheme2 = createTheme({
  typography: {
    fontFamily: [
      "Roboto", "Helvetica"
    ].join(','),
  },
  components: {
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          color: "red",
        },
      }
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: "0.8em",
          fontWeight: "normal",
          color: "white",
          backgroundColor: "#1e88e5" // blue
        }
      }
    },
    MuiTypography: {
      styleOverrides: {
        gutterBottom: {
          marginBottom: 16,
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: "#212121"
        }
      }
    }
  },
  palette: {
    ...nhs_palette2,
  }
});

const altTheme  = createTheme({
    typography: {
      fontFamily: [
        "Roboto", "Helvetica"
      ].join(','),
    },
    components: {
      MuiFormHelperText: {
        styleOverrides: {
          root: {
            color: "red",
          },
        }
      },
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            fontSize: "0.8em",
            fontWeight: "normal",
            color: "white",
            backgroundColor: "#1e88e5" // blue
          }
        },
      },
      MuiTypography: {
        styleOverrides: {
          gutterBottom: {
            marginBottom: 16,
          },
        },
      },
      MuiFormLabel: {
        styleOverrides: {
          root: {
            color: "#212121"
          }
        }
      }
    },
    palette: {
      ...alt_palette,
    }
  });

export function getThemeByName(theme: string): Theme {
    return themeMap[theme];
}

const themeMap: { [key: string]: Theme } = {
    nhsTheme1,
    nhsTheme2,
    altTheme
};

type CanRiskThemeProviderProps = {
  children: React.ReactNode; // 👈️ type children
};

const CanRiskThemeProvider = (props:CanRiskThemeProviderProps) => {
    // State to hold the selected theme name
    const [themeName, _setThemeName] = useState('nhsTheme1');

    // Retrieve the theme object by theme name
    const theme = getThemeByName(themeName);

    return (
        <ThemeContext.Provider value={_setThemeName}>
          <ThemeProvider theme={theme}>{props.children}</ThemeProvider>
        </ThemeContext.Provider>
    );
}

export default CanRiskThemeProvider;
export const ThemeContext = React.createContext((themeName: string): void => {});

