/**
/* © 2023 University of Cambridge. All rights reserved.  
**/

import { Alert, AlertTitle, Button, Card, CardContent, Typography, useTheme } from "@mui/material";
import React from "react";
import { getFT } from "./Person";
import { getCanRisk } from "../utils/CanRisk";
import { s_decrypt, s_encrypt } from "../utils/Scrypt";
import useOnlineStatus from "../utils/OnlineStatus";
import { getTokenParts, urlParam } from "../utils/Utilities";
import { getCancerCSV } from "./CancerDiagnosesData";
import { TOKEN } from "./Token";
import { incompleteAlcohol, incompleteDetails } from "./RFSection1";

const URL = "https://canrisk.org";
const ALLOWED_EMAILS = [/@medschl.cam.ac.uk/, /^cuh.canrisk.*@nhs.net$/];
const DEFAULT_EMAIL = "tjc29@medschl.cam.ac.uk";

/**
 * Check if email is in the ALLOWED_EMAILS list
 * @param e email to test
 * @returns true if email is in the ALLOWED_EMAILS list
 */
const isValidEmail = (e:string|undefined) => {
  return (e ? ALLOWED_EMAILS.some(rx => rx.test(e)) : false);
}

/**
 * Get the email address from 'reply' url parameter
 * @returns
 */
const get_reply_email = () => {
  let reply = urlParam("reply");
  if(reply === undefined) {
      const reply1 = urlParam("reply1");
      const reply2 = urlParam("reply2");
      return (isValidEmail(reply1) ? reply1 : DEFAULT_EMAIL) + (isValidEmail(reply2) ? "?cc=" + reply2 + "&" : "");
  }
  return (isValidEmail(reply) ? reply : DEFAULT_EMAIL);
}

function submitData(onSend:(ok:boolean, e?:any) => void) {
  const {proband} = getFT();
  const surname = (proband.name !== undefined? proband.name: "");

  // ERROR IF AGE OR SURNAME MISSING
  const canrisk = getCanRisk(false);
  const cancer_details = getCancerCSV();

  const token = getTokenParts(TOKEN);
  let k:string, id:string;
  if(token) {
    k = token.token;
    id = token.studyID;
  } else {
    k = surname+(proband.yob).toString();
    id = k;
  }
  console.log(k, id);

  const epromise = s_encrypt(canrisk, k);
  const cpromise = s_encrypt(cancer_details, k);

  Promise.all([epromise, cpromise]).then((d) => {
    const edata = d[0];
    const cdata = d[1];

    // REMOVE THE FOLLOWING
    const decedata = s_decrypt(edata, k);
    if(decedata) decedata.then((dec) => {console.log(edata, edata.length, "DECRYPTED", dec);});
    const decdata = s_decrypt(cdata, k);
    if(decdata) decdata.then((dec) => {console.log(cdata, cdata.length, "DECRYPTED", dec);});

    // POST request using fetch with set headers
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        canrisk: URL+"/canrisk_tool/?canrisk="+edata,
        cancer_details:cdata,
        email_to: get_reply_email(),
        patient: id
      })
    };

    console.log((URL+"/canrisk_tool/?canrisk="+edata).length, URL+"/canrisk_tool/?canrisk="+edata);

    fetch('/email', requestOptions)
      .then(async response => {
          const isJson = response.headers.get('content-type')?.includes('application/json');
          const data = isJson && await response.json();

          // check for error response
          if (!response.ok) {
              // get error message from body or default to response status
              const error = (data && data.message) || response.status;
              return Promise.reject(error);
          } else {
            onSend(true);
          }
      })
      .catch(error => {
          console.error('There was an error!', error);
          onSend(false, error);
      });
  });
      
}

/**
 * Submit pedigree data to the server
 */
 export const SendPanel = () => {
  const [isClicked, setIsClicked] = React.useState(false);
  const [status, setStatus] = React.useState<any>(null);

  const invalidAlc = incompleteAlcohol();
  const invalids = incompleteDetails();
  if(invalidAlc !== "") invalids.push(invalidAlc)
  const isValid = (invalids.length === 0);

  // alcohol frequency
  const invalidEls = invalids.map((k, idx) => {
      return <li key={k}>{k}</li>
  });

  const bg = useTheme().palette.primary.xtralight;
  function onSend(ok: boolean, error?: any) {
    setIsClicked(ok);
    if(!ok) {
      error = "There was an error connecting to the internet. Please try again! " + (error ? "["+error+"]" : "");
    }
    setStatus(ok?ok:error);
  }

  const isOnline = useOnlineStatus();
  return (
      <Card sx={{ backgroundColor: bg }}>
        <CardContent>

        { isValid && (isOnline ?
          <>
            <Typography variant="body1" sx={{p:1}}>
            Click SUBMIT to send the information about you and your family to the CanRisk research team.
            </Typography>
            <Typography variant="body1" sx={{p:1}}>
            The information you give through this tool will be used in the research study you have consented to take part in. Your information will not be used for any other purposes.
            </Typography>

            <Button variant="contained" color="success"
              disabled={isClicked}
              onClick={function() { submitData(onSend)} }
              sx={{ml:1, mt:2}} autoFocus>
              SUBMIT
            </Button>
          </>
        :
          <Typography variant="h2" sx={{p:1}}>
            Please connect to the internet to send the information about you and your family to your clinic.
            When connected, this page will automatically show a button to submit your information.
          </Typography>)
        }

        {isValid && status !== null &&
          (status === true ? 
            <Alert severity="success" sx={{mt:2}}>
              <AlertTitle>Success</AlertTitle>
              <Typography>Your information has been successfully submitted. Thank you!</Typography>

              <Typography sx={{mt:2}}>
                If you would like to clear the data stored by this application in your browser,
                please click the clear button:</Typography>

                <Button
                  onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                    localStorage.clear();
                    sessionStorage.clear();
                    const button:HTMLButtonElement = event.currentTarget;
                    button.disabled = true;
                    button.setAttribute('disabled', 'true')
                    button.innerHTML = "Cleared";
                  }}
                  size="small"
                  variant="contained"
                  color="error" >
                  Clear
                </Button>
            </Alert>
            :
            <Alert severity="warning" sx={{mt:2}}>
              <AlertTitle>Error</AlertTitle>
              <Typography>{status}</Typography>
            </Alert>        
          )        
        }

        {!isValid &&
          <Alert severity="error" sx={{mt:2}}>
            <AlertTitle>Missing Your Details</AlertTitle>
            <Typography>
              Some essential details are missing:
              <ul>{invalidEls}</ul>
              Please return to the 'ABOUT YOU' section and complete your details.
            </Typography>
          </Alert>
        }
      </CardContent>
    </Card>)
 }
       