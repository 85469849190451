/**
/* © 2023 University of Cambridge All rights reserved.  
**/

import { Alert, Button, Card, CardContent, Collapse, FormControlLabel, FormLabel, Grid, Radio, RadioGroup, Typography } from "@mui/material";
import { Box, useTheme } from "@mui/system";
import React, { useState } from "react";

import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import PersonCard from "./PersonCard";
import AlcoholPanel from "./AlcoholPanel";
import { getFT, getRF, setFamilyTree, updateRels } from "./Person";
import Family from "./Family";
import WomensHealth from "./WomensHealth";
import { scrollToTop } from "../utils/Utilities";
import { MyCanRiskBreadcrumbs } from "./Breadcrumbs";
import { SubSectionHeader } from "./SubSectionHeader";

export const CRUMBSFEMALE = ["ABOUT YOU", "YOUR DETAILS", "CHILDREN", "ALCOHOL", "REPRODUCTIVE HEALTH"];
export const CRUMBSMALE   = ["ABOUT YOU", "YOUR DETAILS", "CHILDREN"];
type crumbIdxType = 0|1|2|3|4;

const rfintro = (sex:'M'|'F', bg:string) => {
    return (
      <Card sx={{ backgroundColor: bg }}>
        <CardContent>
            <Typography variant="h2" gutterBottom={true}>Information About You</Typography>
            { sex === 'F' &&
            <Typography variant="body1" gutterBottom={true}>
            The <i>information about you</i> section will ask about your height and weight, how much alcohol you drink, and some relevant information from your medical history around women’s health.
            </Typography>
            }

            <Typography variant="body1" gutterBottom={true}>
            It will also ask about whether you have been diagnosed with cancer, and if you have had any genetic tests (and the results).
            </Typography>
          </CardContent>
      </Card>
    )
}

/**
 * Create a comma separate strong from list
 * @param arr array of strings
 * @returns 
 */
function list2str(arr: string[]) {
    if (arr.length === 0) return "";
    if (arr.length === 1) return arr[0];
    const firsts = arr.slice(0, arr.length - 1);
    const last = arr[arr.length - 1];
    return firsts.join(', ') + ' and ' + last;
  }
  
interface RFSection1Props {
    nextSection: () => void;
    previousSection: () => void;
    idx: number;
}

export default function RFSection1(props: RFSection1Props) {
    const {proband} = getFT();
    const thisCrumbs = (proband.sex === "F" ? CRUMBSFEMALE : CRUMBSMALE);
    const [currCrumb, setCurrCrumb] = useState<crumbIdxType>((props.idx ? props.idx as crumbIdxType: 0));
    const [notValid, setNotValid] = useState<string>("")
    const theme = useTheme();
    const bg = theme.palette.primary.xtralight;

    const isValid = () => {
        let v = "";
        if(thisCrumbs[currCrumb] === "YOUR DETAILS") {
            v = list2str(incompleteDetails());
            setNotValid(v);
        } else if(thisCrumbs[currCrumb] === "ALCOHOL") {
            v = incompleteAlcohol();
            setNotValid(v);
        }
        else setNotValid("");
        return v === "";
    }

    const next = () => {
        if(isValid() && currCrumb >= 0) {
            scrollToTop();
            setCurrCrumb(currCrumb+1 as crumbIdxType);
            setFamilyTree();
        } else if(!isValid()){
            scrollToTop();
        }
    }

    const back = () => {
        if(isValid() && currCrumb >= 1) {
            scrollToTop();
            setCurrCrumb(currCrumb-1 as crumbIdxType);
            setFamilyTree();
        }
    }

    return (<>
        <Box sx={{pt:1, pb:1}} textAlign="left">           
            <MyCanRiskBreadcrumbs
                CRUMBS={thisCrumbs}
                idx={currCrumb}
                onClick={function (idx: number): void {
                    if(!isValid()) return;
                    const cidx = idx as crumbIdxType;
                    setCurrCrumb(cidx);
                }}
            />
        </Box>

        <SubSectionHeader title={thisCrumbs[currCrumb]} />
        {(() => {
            switch (currCrumb) {
            case 0:
                return (rfintro(proband.sex, theme.palette.primary.xtralight))
            case 1:
                return (
                    <Box>
                        {notValid !== "" && 
                            <Alert severity="error" sx={{ my:1 }}>Ensure your {notValid} {notValid.indexOf(" and ") === -1 ? "is" : "are"} provided!</Alert>
                        }
                        <PersonCard person={proband} desc="Your" isProband={true} />
                    </Box>
                )
            case 2:
                return (
                    <ChildrenPanel background={bg} />
                )
            case 3:
                return (
                    <>
                        {notValid !== "" && 
                            <Alert severity="error" sx={{ my:1 }}>Ensure your {notValid} is provided!</Alert>
                        }
                        <AlcoholPanel />
                    </>)
            case 4:
                return <WomensHealth />
            default:
                return null
            }
        })()}

        <Grid container
              direction="row"
              alignItems="center"
              marginTop="24px"
              columnSpacing={5}>
            <Grid item>
                <Button variant="contained" color="primary"
                        onClick={currCrumb > 0 ? back : props.previousSection}
                        sx={{pr:0.75}} size="small">
                    Back<ChevronLeftIcon />
                </Button>
            </Grid>
            
            <Grid item>
                <Button variant="contained" color="primary"
                        onClick={e => {
                            e.currentTarget.blur();     // lose focus so that focus is set to top of next/previous section
                            currCrumb < thisCrumbs.length-1 ? next() : props.nextSection();
                        }}
                        sx={{ml:'auto', pr:0.75}} size="small">
                    Continue<ChevronRightIcon />
                </Button>
            </Grid>
        </Grid>
        </>
        );
}

export function incompleteDetails(): string[] {
    const {proband} = getFT();
    const invalid: string[] = [];
    let d = getRF("dob");
    if(!d || (d instanceof Date && isNaN(d.getFullYear()))) invalid.push("date of birth");
    if(proband.name === undefined || proband.name === "") invalid.push("surname");

    const ethnic = getRF("ethnic");
    if(!ethnic || ethnic.group === "Unknown") invalid.push("ethnicity");
    else if(!ethnic.background) invalid.push("ethnic background")

    if(isNaN(getRF("hgtMetric"))) invalid.push("height");
    if(isNaN(getRF("wgtMetric"))) invalid.push("weight");
    return invalid;
}

export function incompleteAlcohol(): string {
    const a = getRF("alcohol");
    if(!a.frequency) return "alcohol usage";
    else if(a.frequency !== "N" && !a.amount) return "alcohol amount";
    return ""
}


/**
 * Children panel
 * @returns component - details of biological children
 */
function ChildrenPanel(props: { background: string }) {
    const {kids} = getFT();
    const nkids = (kids[0].persons ? kids[0].persons?.length : 0)+(kids[1].persons ? kids[1].persons?.length : 0);
    const [hasChildren, setHasChildren] = React.useState(nkids > 0 ? "Y" : "N");

    const childChange = ((event: React.ChangeEvent<HTMLInputElement>, value: string) => {
        setHasChildren(value as "Y"|"N");
        if(value === "N") {
            updateRels(kids[0], 0, kids[0].sex);
            updateRels(kids[1], 0, kids[0].sex)
        }
    });

    return (
        <Card sx={{background:props.background, borderRadius:2, height: '100%' }}>
            <CardContent>
                <FormLabel id="rbtn-grp-periods" sx={{fontWeight:700}}>Do you have any biological children?</FormLabel>
                <RadioGroup
                    row
                    key={hasChildren}
                    aria-labelledby="rbtn-has-children"
                    defaultValue={hasChildren}
                    name="radio-periods"
                    onChange={childChange}
                    sx={{ml:2}}
                    >
                    <FormControlLabel value="Y" control={<Radio size="small"/>} label="Yes"/>
                    <FormControlLabel value="N" control={<Radio size="small"/>} label="No" />
                </RadioGroup>

                <Collapse in={hasChildren === "Y"} timeout="auto" unmountOnExit>
                    <Typography sx={{fontWeight:700, py:1}}>Select the number of biological children below and click on the icons to enter their details:</Typography>
                    <Family relatives={kids} />
                </Collapse>
            </CardContent>
        </Card>
    )
}
