/**
/* © 2023 Cambridge University. All rights reserved.  
**/

import React, { useState, useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import { AppBar, Avatar, Box, Breadcrumbs, Button, Grid, Link, Toolbar, Typography, useMediaQuery, useTheme } from '@mui/material';

import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import PrintIcon from '@mui/icons-material/Print';

import { getFT } from "../components/Person";
import RFSection1, {CRUMBSFEMALE as RFcrumbsF, CRUMBSMALE as RFcrumbsM} from "../components/RFSection1";
import FHSection2, {CRUMBS as FHcrumbs} from "../components/FHSection2";
import Landing from "../components/Landing";
import {Summary} from "../components/Summary";
import { OnlineStatusProvider } from '../utils/OnlineStatus';
import { SendPanel } from '../components/SendPanel';
import { CancerDiagnosesData } from '../components/CancerDiagnosesData';
import { TokenErrorModal } from '../components/Token';
import { scrollToTop } from '../utils/Utilities';

const SECTIONS = ["ABOUT", "START", "ABOUT YOU", "ABOUT YOUR FAMILY", "SUMMARY", "CANCER DIAGNOSES", "SEND"];
type sectionIdxType = 0|1|2|3|4|5|6;

// hook to get the height of the AppBar
const useAppBarHeight = () => {
  const [appBarHeight, setAppBarHeight] = React.useState(0);

  React.useEffect(() => {
    const getAppBarHeight = ([{ target: appBar }] : any[]) => {
      appBar && setAppBarHeight(appBar.clientHeight);
    };

    const resizeObserver = new ResizeObserver(getAppBarHeight);
    const e = document.querySelector("header.MuiAppBar-root");
    if(e)
      resizeObserver.observe(e);
  }, []);
  return appBarHeight;
};


export default function SectionTool() {
  const [sectionCrumb, setSectionCrumb] = useState<sectionIdxType>(0);
  const [idx, setIDX] = useState<number>(-1);   // sub-section index
  const [printing, setPrinting] = useState(false);

  //const lineHgt = parseInt(window.getComputedStyle(window.document.body).lineHeight.replace('px', ''));
  const appBarHeight = useAppBarHeight();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleOnBeforeGetContent = React.useCallback(() => {
      // console.log("`onBeforeGetContent` called"); // tslint:disable-line no-console
      setPrinting(true);
      return new Promise<void>((resolve:any) => {
        setTimeout(() => {
          resolve();
          setPrinting(false);
        }, 2000);
      });
  }, []);

  const componentRef = useRef<any>();
  const handlePrint = useReactToPrint({
      content: () => componentRef.current,
      onBeforeGetContent: handleOnBeforeGetContent,
  });

  const next = () => {
    if(sectionCrumb >= 0 && sectionCrumb < 6) {
      setSectionCrumb(sectionCrumb+1 as sectionIdxType);
      setIDX(-1)
      scrollToTop();
    }
  }

  const back = () => {
    if(sectionCrumb > 0) {
      setSectionCrumb(sectionCrumb-1 as sectionIdxType);
      const section = SECTIONS[sectionCrumb-1];
      if(section === "ABOUT YOU") setIDX((getFT().proband.sex === "F" ? RFcrumbsF.length : RFcrumbsM.length)  - 1);
      else if(section === "ABOUT YOUR FAMILY") setIDX(FHcrumbs.length-1);
      scrollToTop();
    }
  }

  return (
      <>
        <p> 
          {/*
          empty paragraph here to allow user to use 3rd party tools to alter
          text spacing. Accesibility checked using
          https://holistica11y.com/text-spacing-bookmarklet-for-accessibility-testing/
          */} 
        </p>
        <TokenErrorModal />

        <AppBar position="fixed" sx={{flexGrow: 1}} style={{backgroundColor:theme.palette.primary.dark}} >
          <Toolbar sx={{mx:(isMobile ? 1 : 3)}}>
            <Breadcrumbs
                separator={<ArrowForwardIosIcon fontSize={(isMobile ? "small" : "medium")} sx={{color:"white"}}/>}
                aria-label="section breadcrumbs"
                sx={{flexGrow: 1}}
              >
              {SECTIONS.map((scrumb, idx) => {
                  const sx = idx===sectionCrumb ? {fontWeight: "bold", textDecorationLine: "underline"} : {fontWeight: "normal"};
                  return (
                      <Link key={idx+"-main"} underline="hover" color="white"
                        component="button"
                        variant="subtitle1"
                        sx={sx}
                        onClick={() => {
                          const cidx = idx as sectionIdxType;
                          scrollToTop();
                          setSectionCrumb(cidx);
                          setIDX(-1);
                        }}
                      >{(scrumb)}</Link>
                  )
                  })
              }
            </Breadcrumbs>

            <Typography
              variant={isMobile ? "h2":"h1"} 
              sx={{ px:0.2, mx:0.2, color:'white', fontSize:'1.25rem' }}>
                MyCanRisk
            </Typography>
            <Avatar alt="logo" src={"./logo192.png"}
              sx={{ bgcolor: 'white', width:(isMobile ? "20px" : "25px"), height:(isMobile ? "20px" : "25px"), p:0.4  }} />

          </Toolbar>
        </AppBar>

        <Box sx={{pt:appBarHeight+"px"}} textAlign="left"> </Box>

        {(() => {
            switch (sectionCrumb) {
            case 0:
                return <Landing page={1} />
            case 1:
                  return <Landing page={2} />
            case 2:
                return <RFSection1 nextSection={next}
                                   previousSection={back}
                                   idx={idx>0?idx:0} />
            case 3:
                return <FHSection2 nextSection={next}
                                   previousSection={back}
                                   idx={idx>0?idx:0} />
            case 4:
                return <Summary
                          ref={componentRef}
                          printing={printing}
                          gotoSection={function (section: string, key: string): void {
                            // SET SECTION AND SUBSECTION IDX
                            setSectionCrumb(SECTIONS.indexOf(section) as sectionIdxType);
                            if(section === "ABOUT YOU") setIDX(RFcrumbsF.indexOf(key));
                            else if(section === "ABOUT YOUR FAMILY") setIDX(FHcrumbs.indexOf(key));
                          }} />
            case 5:
              return <CancerDiagnosesData />
            case 6:
                return (
                    <Box sx={{pt:2}}>
                      <OnlineStatusProvider> <SendPanel /> </OnlineStatusProvider>
                    </Box>
                  )
            default:
                return null
            }
            })()
        }

        <Grid container
            direction="row"
            alignItems="center"
            columnSpacing={5}
        >
        {sectionCrumb >= SECTIONS.length-3 &&
          <Grid item sx={{mt:3}}>
            <Button variant="contained" size="small" color="primary" onClick={back} sx={{pr:0.5}}>
                BACK<ChevronLeftIcon />
            </Button>
          </Grid>
        } 

        {(sectionCrumb <= 1 || SECTIONS[sectionCrumb] === "SUMMARY" || SECTIONS[sectionCrumb] === "CANCER DIAGNOSES") &&
          <Grid item sx={{mt:3}}>
            <Button aria-label={sectionCrumb === 1 ? "Start MyCanRisk" : "Continue"}
                    variant="contained" size="small" color="primary" onClick={next} sx={{ml:'auto', pr:0.5}}>
                {sectionCrumb === 1 ? "Start MyCanRisk" : "Continue"}<ChevronRightIcon />
            </Button>
          </Grid>
        }

        {SECTIONS[sectionCrumb] === "SUMMARY" &&
          <Grid item sx={{mt:3}}>
            <Button variant="contained" color="primary" size="small" onClick={handlePrint}><PrintIcon sx={{pr:1}}/>Print Summary</Button>
          </Grid>
        }
        </Grid>
      </>
    );
}
