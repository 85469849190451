/**
/* © 2023 Cambridge University. All rights reserved.  
**/

import { getAlcoholGramsPerDay } from "../components/AlcoholPanel";
import { getFT, getRF, RelativeI, Person, get_relatives } from "../components/Person";
import { getMHTUsage, getOCUsage } from "../components/WomensHealth";

// cancers, genetic & pathology tests
const CANCERS = ['BC1', 'BC2', 'OC', 'PRO', 'PAN'];
const CANCERS_MAP: { [index: string]: string; }  = {
  'BC1': 'Breast', 'BC2': 'Opposite Breast', 'OC': 'Ovarian', 'PRO': 'Prostate', 'PAN': 'Pancreatic'
}
const GENETIC_TESTS = ['brca1', 'brca2', 'palb2', 'atm', 'chek2', 'bard1', 'rad51d', 'rad51c', 'brip1'];
const PATHOLOGY_TESTS = ['er', 'pr', 'her2', 'ck14', 'ck56'];
const HDR = "\n##FamID\tName\tTarget\tIndivID\tFathID\tMothID\tSex\tMZtwin\tDead\tAge\tYob\t" +
            CANCERS.join("\t") + "\tAshkn\t" +
            GENETIC_TESTS.map(function(x){ return x.toUpperCase(); }).join('\t') + "\t" +
            PATHOLOGY_TESTS.map(function(x){ return x.toUpperCase(); }).join(':');

// test number of header columns
if(HDR.split("\t").length !== 27) {
  throw new Error("Expecting 27 columns")
}

/**
 * Get BMI from height and weight in metric units
 * @param hgt - height in cms
 * @param wgt - weight in kgs
 * @returns 
 */
const get_bmi = (hgt: number, wgt: number) => {
  if(!wgt || !hgt) return NaN;
  return wgt / (hgt*hgt/10000);
};

// Check if this is a relative that is a half-sibling. If they
// are then create the dummy parent and store original id.
const checkHalfSibling = (p:Person, full:boolean) => {
  let l = "";
  if(p.halfSibling.isHalfSibling === "Y") {
    if(p.halfSibling.sharesMother) {
      const father = new Person("M");
      l = getCanRiskLine("", father, full);
      if(!p.fathid_original) p.fathid_original = p.fathid;
      p.fathid = father.uid;
      if(p.mothid_original) p.mothid = p.mothid_original;
    } else {
      const mother = new Person("F");
      l = getCanRiskLine("", mother, full);
      if(!p.mothid_original) p.mothid_original = p.mothid;
      p.mothid = mother.uid;
      if(p.fathid_original) p.fathid = p.fathid_original;
    }
  }
  return l;
}

const getCanRiskLine = (i: number|string, p:Person, full:boolean, isproband=false, famid="X") => {
  let l = '\n'+famid+'\t';              // max 13 chars
  l += (typeof i === 'string' ? i : '')+'\t';    // display_name (ANONIMISE) max 8 chars
  l += (isproband ? '1' : 0)+'\t';
  l += p.uid+'\t';							        // max 7 chars
  l += (p.fathid ? p.fathid : 0)+'\t';  // max 7 chars
  l += (p.mothid ? p.mothid : 0)+'\t';  // max 7 chars
  l += p.sex+'\t';
  l += p.mztwin+'\t';                   // MZtwin
  l += (p.status === 1 ? 1 : 0)+'\t';   // current status: 0 = alive, 1 = dead, -1 = unknown assume alive
  l += (p.age ? p.age : 0)+'\t';        // Age at last follow up or 0 = unspecified
  l += (p.yob ? p.yob : 0)+'\t';        // YOB or 0 = unspecified

  for (let key in CANCERS_MAP){
    const c = CANCERS_MAP[key];
    l += p.cancers[c] ? p.cancers[c].age+'\t' : '0\t';
  }

  l += p.ashkenazi+'\t';                // Ashkenazi status, 0 = not Ashkenazi, 1 = Ashkenazi

  let gtest = "";
  let hasGTest = false;
  p.geneTests.forEach( (gt) => {
    gtest += gt.type+":"+gt.result+'\t';
    if(gt.result !== "0") hasGTest = true;
  });
  if(full || hasGTest) l+=gtest;

  if(full)
    PATHOLOGY_TESTS.forEach( (pt, idx) => {
      l += (idx<PATHOLOGY_TESTS.length-1 ? '0:' : '0');
    });
  return l;
}

function isInfinite(n:number) {
  return n === n/0;
}

/**
 * Get CanRisk pedigree data file
 * @param full - include unspecified/untested genetic tests and pathology
 * @param version - file format version
 * @returns 
 */
export const getCanRisk = (full=true, version="2.1") => {
  const {proband, mother, father, maternal_grandmother, maternal_grandfather, paternal_grandmother, paternal_grandfather, partners} = getFT();
  const hgt = getRF("hgtMetric");
  const bmi = get_bmi(hgt, getRF("wgtMetric"));

  let cf = "##CanRisk " + version;
  if(!isNaN(hgt) && hgt > 0) {
    cf += "\n##height=" + hgt;
  }
  if(!isNaN(bmi) && !isInfinite(bmi)) {
    cf += "\n##BMI=" + bmi;
  }

  if(!isNaN(getRF("menarche"))) {
    cf += "\n##Menarche=" + getRF("menarche");
  }
  const meno = getRF("periodsStopped");
  if(meno.haveStopped === "Y") {
    cf += "\n##Menopause=" + meno.age;
  }
  if(getRF("ocUsage")) {
    cf += "\n##OC_use=" + getOCUsage(true);
  }
  if(getRF("mhtUsage")) {
    cf += "\n##MHT_use=" + getMHTUsage(true);
  }

  const relatives = get_relatives();
  const [nchild, age_at_first_live_birth] = getChildrenRiskFactors(proband, relatives);
  cf += "\n##Parity=" + nchild;

  if(!isNaN(age_at_first_live_birth)) {
    cf += "\n##First_live_birth=" + age_at_first_live_birth;
  }

  const alcoGrams = getAlcoholGramsPerDay();
  if(alcoGrams !== null) {
    cf += "\n##Alcohol=" + alcoGrams;
  }

  const ethnic = getRF("ethnic");
  if(ethnic) {
    cf += "\n##Ethnicity=" + ethnic.group+";";
    if(ethnic.background && ethnic.background.indexOf("please describe") === -1) {
      cf += ethnic.background+";";
    }
    if(ethnic.backgroundDesc) {
      cf += ethnic.backgroundDesc;
    }
  }

  // Don't include header to reduce size of encrypted data
  //cf += HDR; 
  cf += getCanRiskLine("you", proband, full, true);
  cf += getCanRiskLine("", mother, full, false);
  cf += getCanRiskLine("", father, full, false);
  cf += getCanRiskLine("", maternal_grandmother, full, false);
  cf += getCanRiskLine("", maternal_grandfather, full, false);
  cf += getCanRiskLine("", paternal_grandmother, full, false);
  cf += getCanRiskLine("", paternal_grandfather, full, false,);

  let haschildren = false;
  for (let i = 0; i < relatives.length; i++) {
    let ps = relatives[i].persons;
    if(ps !== undefined) {
      if(relatives[i].mother.uid === proband.uid || relatives[i].father.uid === proband.uid) {
        haschildren = true;
      }

      for (let j = 0; j < ps.length; j++) {
        cf+=checkHalfSibling(ps[j], full);
        cf+=getCanRiskLine(i, ps[j], full, false);
      }
    }
  }

  if(haschildren) {
    for (let i = 0; i <partners.length; i++) {
      cf += getCanRiskLine("", partners[i], full, false);
    }
  }
  return cf;
}

/**
 * @param relatives 
 * @returns proband's number of children and age of first birth
 */
function getChildrenRiskFactors(proband:Person, relatives: RelativeI[]) {
  const MAX_YEAR = new Date().getFullYear()+1;
  let nchild = 0;
  let youngest_yob = MAX_YEAR;
  for (let i = 0; i < relatives.length; i++) {
    const ps = relatives[i].persons;
    if(ps) {
      for(let j = 0; j < ps.length; j++) {
        if(ps[j].mothid === proband.uid) {
          nchild++;
          if(ps[j].yob > 0 && proband.yob > 0 && ps[j].yob < youngest_yob)
            youngest_yob = ps[j].yob;
        }
      }
    }
  }
  return [nchild, (youngest_yob < MAX_YEAR ? youngest_yob-proband.yob : NaN)];
}
