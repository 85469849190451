/**
/* © 2023 University of Cambridge. All rights reserved.  
**/
import { Box, Button, Card, CardContent, Grid, Typography } from "@mui/material";
import { useTheme } from "@mui/system";
import { useState } from 'react';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import PersonCard from "./PersonCard";
import Family from "./Family";
import { Person, getFT, setFamilyTree } from "./Person";
import TwinPanel from "./TwinPanel";
import { isValidAgesMsg, scrollToTop } from "../utils/Utilities";
import { FamilyTree } from "./FamilyTree";
import MyTooltip from "./MyTooltip";
import { MyCanRiskBreadcrumbs } from "./Breadcrumbs";
import { SubSectionHeader } from "./SubSectionHeader";

export const CRUMBS = ["ABOUT YOUR FAMILY", "SIBLINGS", "PARENTS", "AUNTS & UNCLES",
                       "GRANDPARENTS - MOTHER'S SIDE", "GRANDPARENTS - FATHER'S SIDE", "FAMILY TREE"]
type crumbIdxType = 0|1|2|3|4|5|6;


const fhintro = (bg:string) => {
    return (
      <Card sx={{ backgroundColor: bg }}>
        <CardContent>
            <Typography variant="h2" gutterBottom={true}>Information About Your Family Members</Typography>
            <Typography variant="body1" gutterBottom={true}>
            The <i>information about your family members</i> section will ask you about your brothers and sisters,
            parents, aunts and uncles and grandparents. Please only include information about your biological family
            (i.e. those who are related to you by blood).
            </Typography>

            <Typography variant="body1" gutterBottom={true}>
            For each of your biological family members, you will be asked if they are alive or deceased, their age or
            year of birth, if they have had any cancer diagnoses or any genetic tests (and the results) that you know
            about. <strong>If you don't know the exact information about each person, please give an estimate or select 'unknown'.</strong>
            </Typography>

            <Typography variant="body1" component="div" gutterBottom={true}>
            There are several ways of completing this section:
            <ol>
                <li>
                    Some people prefer to collect all the information that is needed before they start - this may involve talking with family members.
                </li> 
                <li>
                    Others prefer to fill in the information that they can and then come back to it at a later date.
                </li>
            </ol>
            Either of these is fine, as <strong>the information you give is automatically saved in the internet browser on that
            device so as long as you do not clear your browser you should be able to return to it at any point without
            losing your progress</strong>.
            </Typography>

            <Typography variant="body1">
            The amount of time it will take you to complete this section can vary depending on the size of your family.
            </Typography>
        </CardContent>
      </Card>
    )
}

interface FHSection2Props {
    nextSection: () => void;
    previousSection: () => void;
    idx?: number;
}

export default function FHSection2(props: FHSection2Props) {
    const [currCrumb, setCurrCrumb] = useState<crumbIdxType>((props.idx ? props.idx as crumbIdxType: 0));
    const [notValidMGM, setNotValidMGM] = useState<string>("")  // maternal grandmother valid age/yob string
    const [notValidMGF, setNotValidMGF] = useState<string>("")  // maternal grandfather valid age/yob string
    const [notValidPGM, setNotValidPGM] = useState<string>("")  // paternal grandmother valid age/yob string
    const [notValidPGF, setNotValidPGF] = useState<string>("")  // paternal grandfather valid age/yob string
    const theme = useTheme();

    /** 
     * Ensure age/yob provided when diagnosed with cancer(s) and
     * that age of diagnosis is greater or equal to their age.
     */
    function isValid(ps1:Person, setNotValid:React.Dispatch<React.SetStateAction<string>>):boolean {
        const msg = isValidAgesMsg(ps1);
        setNotValid(msg);
        return msg === "";
    }

    /**
     * Check the ages/yob are present when diagnosed with cancer(s) and
     * valid for grandparents.
     * @returns true if the grandparents panels are valid
     */
    function isOK() {
        if(CRUMBS[currCrumb] === "GRANDPARENTS - FATHER'S SIDE") {
            return isValid(paternal_grandmother, setNotValidPGM) && isValid(paternal_grandfather, setNotValidPGF);
        } else if (CRUMBS[currCrumb] === "GRANDPARENTS - MOTHER'S SIDE") {
            return isValid(maternal_grandmother, setNotValidMGM) && isValid(maternal_grandfather, setNotValidMGF);
        }
        return true;
    }

    const next = () => {
        if(!isOK()) {
            scrollToTop();
        } else if(currCrumb >= 0 && currCrumb < 10) {
            scrollToTop();
            setCurrCrumb(currCrumb+1 as crumbIdxType);
            setFamilyTree();
        }
    }

    const back = () => {
        if(!isOK()) {
            scrollToTop();
        } else if(currCrumb < 11) {
            scrollToTop();
            setCurrCrumb(currCrumb-1 as crumbIdxType);
            setFamilyTree();
        }
    }

    const {mother, father, maternal_grandmother, maternal_grandfather, paternal_grandmother, paternal_grandfather} = getFT();
    let scolour;
    let tcolour;
    if(CRUMBS[currCrumb] === "GRANDPARENTS - FATHER'S SIDE") {
        scolour = "#003087";
        tcolour = "#fafafa";
    }

    return (<>
        <Box sx={{pt:1, pb:1}} textAlign="left">
            <MyCanRiskBreadcrumbs
                CRUMBS={CRUMBS}
                idx={currCrumb}
                onClick={function (idx: number): void {
                    const cidx = idx as crumbIdxType;
                    setCurrCrumb(cidx);
                    setFamilyTree();
                }}
            />
        </Box>
        <SubSectionHeader colour={scolour} txtColour={tcolour} title={CRUMBS[currCrumb]} />

    {(() => {
        switch (currCrumb) {
        case 0:
            return (fhintro(theme.palette.primary.xtralight))
        case 1:
            return (
                <Card sx={{background:theme.palette.primary.xtralight, borderRadius:2, height: '100%' }}>
                    <CardContent>
                        <Typography sx={{fontWeight:700, pb:2}}>Select the number of brothers and sisters below and click on the icons to enter their details:</Typography>
                        <Family relatives={getFT().sibs} />
                    </CardContent>
                </Card>
            )
        case 2:
            return (
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                    <PersonCard key={mother.uid} person={mother} desc="Your Mother's"/>
                    </Grid>
                    <Grid item xs={12} md={6}>
                    <PersonCard key={father.uid} person={father} desc="Your Father's"/>
                    </Grid>
                </Grid>
            )
        case 3:
            return (
                <Card sx={{background:theme.palette.primary.xtralight, borderRadius:2, height: '100%' }}>
                    <CardContent>
                        <Box display="flex" justifyContent="space-between" sx={{pb:2}}>
                            <Typography sx={{fontWeight:700}}>
                                Select the number of uncles and aunts below and click on the icons to enter their
                                details:</Typography>
            
                            <MyTooltip
                                title={"Reminder: you can take a break and return to completing the questions. "+
                                    "Your answers are being saved to this browser. "+
                                    "Please remember to complete as much information as possible."}
                                outline={false}
                                areaLabel={"remind to take a break"}/>
                        </Box>
                        <Box>
                            <Family relatives={getFT().mothers_sibs} />
                            <Family relatives={getFT().fathers_sibs} />
                        </Box>
                    </CardContent>
                </Card>
            )
        case 4:
            return (
            <Box>

                <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <PersonCard key={maternal_grandmother.uid} 
                                person={maternal_grandmother}
                                name="grandmother (mother's side)"
                                notValid={notValidMGM}
                                desc="Your Grandmother's Details (Mother's Side)"/>
                </Grid>
                <Grid item xs={12} md={6}>
                    <PersonCard key={maternal_grandfather.uid}
                                person={maternal_grandfather}
                                name="grandfather (mother's side)"
                                notValid={notValidMGF}
                                desc="Your Grandfather's Details (Mother's Side)"/>
                </Grid>
                </Grid>
            </Box>
        )
        case 5:
            return (
            <Box>
                <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <PersonCard key={paternal_grandmother.uid}
                                person={paternal_grandmother}
                                name="grandmother (father's side)"
                                notValid={notValidPGM}
                                desc="Your Grandmother's Details (Father's Side)"/>
                </Grid>
                <Grid item xs={12} md={6}>
                    <PersonCard key={paternal_grandfather.uid}
                                person={paternal_grandfather}
                                name="grandfather (father's side)"
                                notValid={notValidPGF}
                                desc="Your Grandfather's Details (Father's Side)"/>
                </Grid>
                </Grid>
            </Box>
        )
        case 6:
            return (
                <MyFamilyTree colour={theme.palette.secondary.xtralight} />
            )
        default:
            return null
        }
    })()}

        <Grid container
              direction="row"
              alignItems="center"
              marginTop="24px"
              columnSpacing={5}>
            <Grid item>
                <Button variant="contained" color="primary"
                        onClick={currCrumb > 0 ? back : props.previousSection}
                        sx={{ml:'auto', pr:0.5}} size="small">
                    BACK<ChevronLeftIcon />
                </Button>
            </Grid>

            <Grid item>
                <Button variant="contained" color="primary"
                        onClick={e => {
                            e.currentTarget.blur();     // lose focus so that focus is set to top of next/previous section
                            currCrumb < CRUMBS.length-1 ? next() : props.nextSection();
                        }}
                        sx={{ml:'auto', pr:0.5}} size="small">
                    Continue<ChevronRightIcon />
                </Button>
            </Grid>
        </Grid>
    </>
    );
}


function MyFamilyTree(props: {colour:any}) {
    const [updateTwin, setUpdateTwin] = useState(false);

    function updateTrue() { setUpdateTwin(true); }
    function updateFalse() { setUpdateTwin(false); }

    return (
        <>
            <TwinPanel onTwinUpdate={updateTrue} />
            <Typography variant="body1" sx={{pb:2}}>
                A Family Tree is a drawing of someone's family that shows many generations (children, parents,
                grandparents), and how people are related to each other.
            </Typography>
            <Typography variant="body1">
                Below is your family tree that is created from the information that you have given us
                here. Please check to see if this looks correct. If not you can go back to the relevant
                family member and amend their details.
            </Typography>

            <FamilyTree colour={props.colour} updateTwin={updateTwin} afterUpdate={updateFalse} />
            <Box component="div" id="msgDialog"></Box>
        </>
    );
}
