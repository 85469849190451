/**
/* © 2023 Cambridge University. All rights reserved.  
**/

import { Person, getFT, get_relatives } from "../components/Person";


interface colourType {
  [key: string]: string;
}

export const CANRISK_CANCER_COLOURS:colourType = {
  'Breast':           '#F68F35',  // orange
  'Opposite Breast':  'pink',     // pink
  'Ovarian':          '#306430',  // green
  'Pancreatic':       '#4289BA',  // blue
  'Prostate':         '#D5494A',  // red
}

export const OTHER_CANCERS_COLOURS:colourType = {
  "Colon":      "#00ff00",  // lime
  "Uterus":     "#ffa07a",  // light salmon
  "Melanoma":   "#f0e68c",  // khaki
  "Kidney":     "#d8bfd8",  // thistle
  "Gastric":    "#da70d6",  // orchid
  "Lung":       "#00ff7f",  // spring green
  "Brain":      "#ffd700",  // gold
  "Oesophagus": "#a52a2a",  // brown
  "Thyroid":    "#2f4f4f",  // dark slate grey
  "Liver":      "#d2b48c",  // tan
  "Cervix":     "#800000",  // maroon
  "Myeloma":    "#000080",  // navy
  "Leukemia":   "#dda0dd",  // plum
  "Other":      "#ffff54"   // laser lemon
}

export const CanRiskCancer = Object.keys(CANRISK_CANCER_COLOURS);
export type CanRiskCancerType = typeof CanRiskCancer[number];

export const OtherCancer = Object.keys(OTHER_CANCERS_COLOURS);
type OtherCancerType = typeof OtherCancer[number];

/**
 * Cancer diagnosis
 */
export class Cancer {
  cancer: CanRiskCancerType|OtherCancerType;
  age: number|undefined;

  constructor(cancer: CanRiskCancerType|OtherCancerType, age?: number) {
    this.cancer = cancer;
    if(age) this.age = age;
  }

  static removeCancer(carr:string[], c:string) {
    const idx = carr.indexOf(c);
    if (idx > -1) { // only splice array when item is found
      carr.splice(idx, 1); // 2nd parameter means remove one item only
    }
  }

  /**
   * Get the cancer names that apply based on if female or male
   * @param sex - male/female
   * @returns string[]
   */
  static getCancerNamesBySex = (sex:'F'|'M') => {
    const c = [...CanRiskCancer, ...OtherCancer];
    if(sex === 'F') {
      Cancer.removeCancer(c, 'Prostate');
    } else if(sex === 'M') {
      Cancer.removeCancer(c, 'Opposite Breast');
      Cancer.removeCancer(c, 'Ovarian');
      Cancer.removeCancer(c, 'Uterus');
    }
    return c;
  }

  /**
   * Get the Cancer's that apply based on if female or male 
   * @param sex - male/female
   * @returns Cancer[]
   */
  static getCancersBySex = (sex:'F'|'M') => {
    const cNames = Cancer.getCancerNamesBySex(sex);
    const c = [];
    for(let i=0; i<cNames.length; i++) {
      c.push(new Cancer(cNames[i]));
    }
    return c;
  };

  /**
   * Get cancer diagnoses for a person.
   * @param p: Person
   * @returns cancer diagnoses
   */
  static getMyCancers = ((p:Person) => {
    return Cancer.getCancersBySex(p.sex).filter(function(c){return p.cancers[c.cancer]});
  });

  static getFamilyMembersWithCancers = (() => {
    const ps:Person[] = [];
    const cs:string[] = [];

    function addIfCancer(p:Person) {
      const mycs = Cancer.getMyCancers(p);
      if(mycs.length > 0) {
        ps.push(p);
        for(let i = 0; i < mycs.length; i++) {
          if(cs.indexOf(mycs[i].cancer) === -1) {
            cs.push(mycs[i].cancer);
          }
        }
      }
    }
    const {proband, mother, father, maternal_grandmother, maternal_grandfather, paternal_grandmother, paternal_grandfather, partners} = getFT();

    addIfCancer(proband);
    addIfCancer(mother);
    addIfCancer(father);
    addIfCancer(maternal_grandmother);
    addIfCancer(maternal_grandfather);
    addIfCancer(paternal_grandmother);
    addIfCancer(paternal_grandfather);

    const relatives = get_relatives();
    let haschildren = false;
    for (let i = 0; i < relatives.length; i++) {
      let ps = relatives[i].persons;
      if(ps !== undefined) {
        if(relatives[i].mother.uid === proband.uid || relatives[i].father.uid === proband.uid) haschildren = true;

        for (let j = 0; j < ps.length; j++) {
          addIfCancer(ps[j]);
        }
      }
    }

    if(haschildren) {
      for (let i = 0; i <partners.length; i++) {
        addIfCancer(partners[i]);
      }
    }

    return {'membersWithCancer': ps, 'cancerTypes': cs};
  })
}
