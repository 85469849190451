/**
/* © 2023 Cambridge University. All rights reserved.  
**/

import React from 'react';
import ReactDOM from 'react-dom/client'

import NavBar from './components/NavBar';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { Container } from '@mui/material';
import Footer from './components/Footer';
import CanRiskThemeProvider from './utils/Theme';
import "./index.css";
import 'typeface-roboto';


const el = document.getElementById('root')
if (el === null) throw new Error('Root container missing in index.html')

const root = ReactDOM.createRoot(el)
root.render(
  <React.StrictMode>
    <Container maxWidth="lg" sx={{marginBottom: "24px"	/* footer height */}}>
      <CanRiskThemeProvider>
        <NavBar />
      </CanRiskThemeProvider>
    </Container>
    <Container maxWidth="lg"><Footer /></Container>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA

const config = {
  onUpdate: (registration: ServiceWorkerRegistration) => {
    console.log("UPDATE - APP");
    alert('A new version of MyCanRisk is available and will be loaded automatically.');

    const registrationWaiting = registration.waiting;
    if (registrationWaiting) {
      registrationWaiting.postMessage({ type: 'SKIP_WAITING' });
      registrationWaiting.addEventListener('statechange', e => {
        if ((e.target as ServiceWorker).state === 'activated') {
          window.location.reload();
        }
      });
    }
  },
  onSuccess: (registration: ServiceWorkerRegistration) => {
    console.log("SUCCESS - APP");
    window.location.reload()
  }
}


serviceWorkerRegistration.register(config);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

