/**
/* © 2023 University of Cambridge. All rights reserved.  
**/
import React, { useState } from "react";
import FormControl from '@mui/material/FormControl';
import { Button, Grid, TextField, useMediaQuery, useTheme } from '@mui/material';
import { getRF, setRF } from "./Person";
import { makeid } from "../utils/Utilities";

const KGS_PER_POUND = 0.453592;
const POUNDS_PER_STONE = 14;
const INCHES_IN_FOOT = 12;
const CMS_PER_INCH = 2.54;
const INCHES_IN_CMS = 0.393701;
const MAX_STONES = 30;
const MAX_KGS = ((MAX_STONES*POUNDS_PER_STONE)+(POUNDS_PER_STONE-1))*KGS_PER_POUND;
const MAX_FT = 8;
const MAX_CMS = ((MAX_FT*INCHES_IN_FOOT)+(INCHES_IN_FOOT-1))*CMS_PER_INCH;


/** Imperial to metric weight conversion */
const get_wgt_kg = (pid: string) => {
  const st = parseInt((document.getElementById("st"+pid) as HTMLInputElement).value);
  const lb = parseInt((document.getElementById("lb"+pid) as HTMLInputElement).value);
  let kg = NaN;
	if (isNaN(st) || isNaN(lb)) {
		console.log("Error: weight NaN");
	} else {
		const lbtot = (14 * st) + lb;
		kg = KGS_PER_POUND * lbtot;
	}
  return kg;
}

/** Metric to imperial height conversion */
export const get_wgt_st_lbs = (wgt_kgs: number|undefined) => {
  if(wgt_kgs === null || !wgt_kgs) return [undefined, undefined];
  const lbtot = wgt_kgs/KGS_PER_POUND;
  const st = Math.floor(lbtot/POUNDS_PER_STONE);
  const lbs = Math.round(lbtot - (st*POUNDS_PER_STONE));
  return (lbs < POUNDS_PER_STONE ? [st, lbs] : [st+1, 0]);
}

/** Imperial to metric height conversion */
const get_hgt_cms = (pid: string) => {
  const ft = parseInt((document.getElementById("ft"+pid) as HTMLInputElement).value);
  const inch = parseInt((document.getElementById("in"+pid) as HTMLInputElement).value);
  if(isNaN(ft) || isNaN(inch)) return undefined;
  return CMS_PER_INCH * ((INCHES_IN_FOOT * ft) + inch);
}

/** Metric to imperial height conversion */
export const get_hgt_ft_ins = (hgt_cms:number) => {
  const intot = hgt_cms*INCHES_IN_CMS;
  const ft = Math.floor(intot/INCHES_IN_FOOT);
  const inches = Math.round(intot - (ft*INCHES_IN_FOOT));
  return (inches < INCHES_IN_FOOT ? [ft, inches] : [ft+1, 0]);
}

/**
 * Height, weight and BMI component
 * @constructor
 * @param {HeightBMIProps} HeightBMIProps - properties
 */
export default function HeightBMI() {
  const [wgtUnits, setWgtUnits] = useState<"metric"|"imperial">(getRF("wgtUnits"));
  const [hgtUnits, setHgtUnits] = useState<"metric"|"imperial">(getRF("hgtUnits"));
  const pid = makeid();

  const unitChange = (value:"metric"|"imperial", isHgt:boolean) => {
    if(isHgt) {
      setHgtUnits(value);
      setRF("hgtUnits", value);
    } else {
      setWgtUnits(value);
      setRF("wgtUnits", value);
    }
  }

  const getWgt = () => {
    if(wgtUnits === "metric") return parseInt((document.getElementById("wgt"+pid) as HTMLInputElement).value);
    return get_wgt_kg(pid)
  }

  const getHgt = () => {
    if(hgtUnits === "metric") return parseInt((document.getElementById("hgt"+pid) as HTMLInputElement).value)
    return get_hgt_cms(pid)
  }

  const change = () => {
    setRF("hgtMetric", getHgt());
    setRF("wgtMetric", getWgt());
  }

  const hgt = getRF("hgtMetric");
  const wgt = getRF("wgtMetric");
  return (
    <>
      <Grid container sx={{mt:1}}>
        <Grid item xs={12} md={6}>
        {hgtUnits === "metric" &&
          <TextField id={"hgt"+pid} label="Height (cm)" size="small" type="number" inputProps={{min:50, max:MAX_CMS}}
                sx={{mt:1, pl:1, width: '20ch'}} variant="outlined"
                helperText={isNaN(hgt) || hgt === null ? 'Required' : ' '}
                defaultValue={(hgt !== undefined && !isNaN(hgt)) ? hgt : ""}
                onMouseLeave={(e) => {
                  const hgtTarget = (document.getElementById("hgt"+pid) as HTMLInputElement);
                  if(parseInt(hgtTarget.value) < 50)  hgtTarget.value = "";
                  change();
                }}
                onChange={(e) => {
                  if(parseInt(e.target.value) > MAX_CMS)  e.target.value = "";
                  change();
                }} />
        }
        {hgtUnits === "imperial" &&
          <FormControl component="fieldset">
            <Grid container columnSpacing={1} rowSpacing={0}>
              <Grid item sm={6}>
                <TextField id={"ft"+pid} label="Height (ft)" size="small" type="number" inputProps={{min:1, max:MAX_FT}}
                sx={{mt:1, pl:1, width: '14ch'}} variant="outlined"
                helperText={isNaN(hgt) ? 'Required' : ' '}
                defaultValue={(hgt !== undefined && !isNaN(hgt)) ? get_hgt_ft_ins(hgt)[0] : undefined}
                onMouseLeave={(e) => {
                  const hgtTarget = (document.getElementById("ft"+pid) as HTMLInputElement);
                  if(parseInt(hgtTarget.value) < 1)  hgtTarget.value = "";
                  change();
                }}
                onChange={(e) => {
                  if(parseInt(e.target.value) > MAX_FT)  e.target.value = "";
                  change();
                }} />
              </Grid>
              <Grid item sm={6}>
                <TextField id={"in"+pid} label="Height (in)" size="small" type="number" inputProps={{min:0, max:INCHES_IN_FOOT-1}}
                sx={{mt:1, pl:1, width: '14ch'}} variant="outlined"
                defaultValue={(hgt !== undefined && !isNaN(hgt)) ? get_hgt_ft_ins(hgt)[1] : undefined}
                onChange={(e) => {
                  if(parseInt(e.target.value) > INCHES_IN_FOOT-1)  e.target.value = "";
                  change();
                }} />
              </Grid>
            </Grid>
          </FormControl>
        }
        </Grid>
        <Grid item xs={12} md={6}>
          <SwitchUnits desc={"height"} defaultValue={hgtUnits} unitChange={unitChange}/>
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={12} md={6}>
        {wgtUnits === "metric" &&
          <TextField id={"wgt"+pid} label="Weight (kg)" size="small" type="number" inputProps={{min:25, max:MAX_KGS}}
                sx={{mt:1, pl:1, width: '20ch'}} variant="outlined"
                helperText={isNaN(wgt) || wgt === null? 'Required' : ' '}
                defaultValue={(wgt !== undefined && !isNaN(wgt)) ? wgt : ""}
                onChange={(e) => {
                  if(parseInt(e.target.value) > MAX_KGS) e.target.value = "";
                  change();
                }} />
        }
        {wgtUnits === "imperial" &&
          <FormControl component="fieldset">
            <Grid container columnSpacing={1} rowSpacing={0}>
              <Grid item sm={6}>
                <TextField id={"st"+pid} label="Weight (st)" size="small" type="number" inputProps={{min:4, max:MAX_STONES}}
                sx={{mt:1, pl:1, width: '14ch'}} variant="outlined"
                helperText={isNaN(wgt) || wgt === null ? 'Required' : ' '}
                defaultValue={get_wgt_st_lbs(wgt)[0]}
                onChange={(e) => {
                  if(parseInt(e.target.value) > MAX_STONES) e.target.value = "";
                  change();
                }} />
              </Grid>
              <Grid item sm={6}>
                <TextField id={"lb"+pid} label="Weight (lb)" size="small" type="number" inputProps={{min:0, max:POUNDS_PER_STONE-1}}
                sx={{mt:1, pl:1, width: '14ch'}} variant="outlined"
                helperText={isNaN(wgt) ? 'Required' : ' '}
                defaultValue={get_wgt_st_lbs(wgt)[1]}
                onChange={(e) => {
                  if(parseInt(e.target.value) > (POUNDS_PER_STONE-1)) e.target.value = "";
                  change();
                }} />
              </Grid>
            </Grid>
          </FormControl>
        }
        </Grid>
        <Grid item xs={12} md={6}>
          <SwitchUnits desc={"weight"} defaultValue={wgtUnits} unitChange={unitChange}/>
        </Grid>
      </Grid>
    </>
  );
}

interface SwitchUnitsProps {
  defaultValue:string;
  unitChange: (value:"metric"|"imperial", isHgt:boolean) => void;
  desc: string;
}

/**
 * Switch between imperial and metric units
 * @param props 
 * @returns 
 */
function SwitchUnits(props: SwitchUnitsProps) {
  const isSmall = useMediaQuery(useTheme().breakpoints.down('sm'));
  const theme = useTheme();
  return (
    <Button
      sx={{mt:(isSmall?0:2), mb:(isSmall?1:2), color:theme.palette.primary.dark}}
      onClick={(ev: React.MouseEvent<HTMLButtonElement>) => {
          const btn: HTMLButtonElement = ev.currentTarget;
          const txt = btn.textContent;
          if(txt) {
            const oldUnit = (txt.includes("metric") ? "metric" : "imperial");
            const newUnit = (txt.includes("metric") ? "imperial" : "metric");
            btn.textContent = "Switch to "+newUnit;
            props.unitChange(oldUnit, props.desc === "height");
          }
        }
      }
      size="small">
        Switch to {props.defaultValue === "metric" ? "imperial" : "metric"}
    </Button>
  )
}
