/**
/* © 2023 University of Cambridge. All rights reserved.  
**/

import React, { useState } from "react";
import { Autocomplete, Grid, TextField, useMediaQuery, useTheme } from "@mui/material";
import { RelativeI, Person, updateRels } from "./Person";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { ButtonProps as MuiButtonProps } from "@mui/material/Button";
import PersonCard from "./PersonCard";
import { isValidAgesMsg } from "../utils/Utilities";


/**
 * Family component - to define number of relatives
 */
 export default function Family(props: { relatives: RelativeI[] }) {
  const [relatives, setRelatives] = React.useState(props.relatives);

  const handleClick = (i: number, v: string, sex: "M"|"F") => {
    const nnew = parseInt(v ? v : "0");
    const rels = relatives.slice();
    updateRels(rels[i], nnew, sex);
    setRelatives(rels);
  }

  const renderComboBox = (i: number, relatives: RelativeI[]) => {
    if(relatives[i] === undefined) return;
    const rels = relatives[i].persons;
    let persons;
    if (rels !== undefined) {
      persons = rels.map((rel) => {
        return <PersonButton person={rel} key={rel.uid} type={props.relatives[i]} mr={1}></PersonButton>
      })
    }

    return (
      <Grid container direction="row" alignItems="center" justifyContent="flex">
        <Grid item>
          <ComboBox
            key={i}
            name={relatives[i].name}
            num={relatives[i].number}
            onChange={(
              ev: React.ChangeEvent<HTMLSelectElement>,
              newValue: string
            ): void => handleClick(i, newValue, relatives[i].sex)}
          />
        </Grid>
        <Grid item>
          {persons}
        </Grid>
      </Grid>
    );
  }

  return (
    <>
      { renderComboBox(0, relatives) }
      { renderComboBox(1, relatives) }
    </>
  )
}


interface ComboBoxProps {
  name: string;
  num: number;
  onChange: any
}

// array of number of relatives 0 - 8
const number = Array.from({ length: 9 }, (v, i) => i.toString());

/**
 * Combobox dropdown selection of number of relatives
 */
function ComboBox(props: ComboBoxProps) {
    const label = "Number of "+props.name;
    const txtwidth = (label.length>19?'21ch':'16ch');
    const width = (label.length>19?215:170);
    return (
      <Autocomplete
        sx={{my:1, mr:1}}
        options={number}
        getOptionLabel={(option) => option}
        style={{ width: width }}
        renderInput={(params) => (
          <TextField {...params} label={label} variant="outlined" size="small" sx={{width: txtwidth}} />
        )}
        onChange={props.onChange}
        value={props.num.toString()}
      />
    );
}

interface PersonButtonProps {
  key: string;
  name?: string;
  type: RelativeI;
  person: Person;
  color?: MuiButtonProps["color"];
  minWidth?: number;
  mr?: number
}

PersonButton.defaultProps = {
  minWidth: 0,
  mr: 0
};

export function toTitleCase(str: string) {
  return str.replace(
    /\w\S*/g,
    function(txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    }
  );
}

/**
 * Assign Person age/yob
 */
function PersonButton(props: PersonButtonProps) {
  const [open, setOpen] = useState(false);
  const [notValid, setNotValid] = useState<string>("")
  const theme = useTheme();
  const fullScreen = !useMediaQuery(theme.breakpoints.down('sm'));

  const handleClickOpen = () => { setOpen(true); };
  const handleClose = () => {
    let msg = isValidAgesMsg(props.person); // age/yob provided when diagnosed with cancer(s) and ages consistent
    setNotValid(msg);
    if(msg === "") setOpen(false);
  };

  const bg = props.person.sex === 'F' ? theme.palette.female.main : theme.palette.male.main;
  const bghover = props.person.sex === 'F' ? theme.palette.female.dark : theme.palette.male.dark;

  return (
    <>
      <Button variant="contained" size="small" 
        sx={{mr: props.mr, mb: 1, px:{xs:1, sm:2}, mx:1, flexDirection:'column', minWidth:props.minWidth+'%',
            backgroundColor:bg, '&:hover': { backgroundColor: bghover}
          }}
        onClick={handleClickOpen} >
        {props.name ? props.name : ""}
        {props.name ? <br/> : ""}
        Age: {props.person.age ? props.person.age : "?"}  {props.person.yob ? "("+props.person.yob+")": ""}
      </Button>

      <Dialog
        fullScreen={!fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title" sx={{ mx:1, p:1 }}></DialogTitle>
        <DialogContent sx={{pb:1}}>
          <Grid container spacing={2} align-content="flex-start" sx={{pt:2}}>
            <PersonCard
              person={props.person}
              desc={toTitleCase(props.type.name)}
              notValid={notValid}
              halfSiblingOption={props.type.halfSiblingOption}/>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="contained" fullWidth={true} color="primary" autoFocus>
            DONE
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
