/**
/* © 2023 University of Cambridge. All rights reserved.  
**/

import { Fade, IconButton, Tooltip, useTheme } from "@mui/material";
import InfoIconOutline from '@mui/icons-material/InfoOutlined';
import InfoIcon from '@mui/icons-material/Info';
import { ReactNode } from "react";

export interface TooltipProps {
    title: string|NonNullable<ReactNode>;
    areaLabel: string;
    outline: boolean;
    color?: string
}

MyTooltip.defaultProps = {
    outline: true,
    color: "#006647"
};
  
/**
 * Tooltip component
 * @param {TooltipProps} TooltipProps - tooltip properties
 */
export default function MyTooltip(props: TooltipProps) {
    const c = useTheme().palette.primary.main;
    return (
        <Tooltip
            title={
                <div style={{ whiteSpace: 'pre-line' }}>{props.title}</div>
            }
            enterTouchDelay={50}
            leaveTouchDelay={5000}
            placement={"top"}
            TransitionComponent={Fade} TransitionProps={{ timeout: 600 }} arrow>
            <IconButton area-label={props.areaLabel} size="small" sx={{px:1}}>
                {props.outline ?
                    <InfoIconOutline sx={{color: props.color}}/>
                :
                    <InfoIcon sx={{color: c}}/>
                }
            </IconButton>
        </Tooltip>)
}