/**
/* © 2023 University of Cambridge. All rights reserved.  
**/

import React from 'react';

import { Grid, Typography } from '@mui/material';
import MyDate, { DateProps } from './MyDate';
import Age, { AgeProps } from "./Age";
import { CalendarPickerView } from '@mui/x-date-pickers';
import MyTooltip from './MyTooltip';

interface AgeDateProps extends DateProps, AgeProps {
    isAlive: boolean;
    views?: readonly CalendarPickerView[];
    ageLabel?: "Age"|"Diagnosis age"|"Age at death";
    dateLabel?: "Date of birth"|"Date of death"|"Year of death"|""|undefined;
    includeAge?: boolean;
}

/**
 * Age and Date components.
 */
const AgeDateCombo = (props: AgeDateProps) => {
  // if alive set minimum date possible to be 120 years ago
  const minDate = new Date("01/01/"+(props.isAlive ? new Date().getFullYear()- 120 : 1850));
  const d = props.isAlive ? 2 : 3;
  return (
    <Grid container>
      <Grid item sm={4} xs={5}>
        <MyDate dateChange={props.dateChange} views={props.views} mydate={props.mydate} minDate={minDate} dateLabel={props.dateLabel}/>
      </Grid>
      {props.includeAge && (
      <>
        <Grid item xs={1}>
          <Typography sx={{px:1}}>or</Typography>
        </Grid>
        <Grid item sm={d} xs={3}>
          <Age ageChange={props.ageChange} age={props.age} label={props.ageLabel ? props.ageLabel : "Age"} />
        </Grid>

        <Grid item xs={1}>
          <MyTooltip title={"Age and year of birth are automatically updated but can be manually adjusted to provide the exact value."}
                    outline={false}
                    areaLabel={"Age and date synchronisation"} />
        </Grid>
      </>
      )}
    </Grid>
  );
};
export default AgeDateCombo;
